.event {
  &-block {
    display: block;
    position: relative;
    width: 260px;
    @media (min-width: 870px) {
      margin-left: 10px;
    }
    @media (min-width: 1360px) {
      margin-left: 20px;
    }
  }
  &-page__link {
    color: #a0a0a0;
    font-family: $museo700;
    font-size: 11px;
    text-transform: uppercase;
    transition: 0.2s ease-in-out;
    &:hover {
      color: #7bb249;
      text-decoration: none;
    }
    i {
      margin-left: 5px;
    }
  }
  &-list {
    padding-left: 0;
    list-style-type: none;
  }
  &-item {
    padding-top: 20px;
    padding-bottom: 5px;
    &:first-of-type {
      border-bottom: 1px solid #acacac;
    }
  }
  &-link {
    display: flex;
    &:hover {
      text-decoration: none;
    }
    .wrap {
      display: flex;
      flex-direction: column;
      padding-left: 10px;
    }
  }
  &-title {
    color: #000;
    font-family: $museo300;
    font-size: 11px;
    line-height: normal;
    font-weight: 400;
    margin-bottom: 0;
    text-transform: uppercase;
    transition: 0.2s ease-in-out;
    &:hover {
      color: #7bb249;
      text-decoration: none;
    }
  }
  &-date {
    color: #000;
    font-family: $museo900;
    font-size: 22px;
    text-transform: uppercase;
  }
  &-type {
    color: #000;
    font-family: $museo900;
    font-size: 11px;
    margin-top: 0;
  }
}