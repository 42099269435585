#navigation-mobile {
  display: none;
  background-color: #222;
  position: relative;
  z-index: 200;
  @media (max-width: 1024px) {
    position: fixed;
    top: 50px;
    left: 0;
    right: 0;
    overflow-y: auto;
    height: calc(100% - 50px);
  }
  .container {
    padding-bottom: 20px;
  }
  .rd-dropdown-plus {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    background-color: #222;
    .fa {
      display: block;
      text-align: center;
      font-size: 13px;
      width: 50px;
      height: 44px;
      line-height: 51px;
      color: #555;
    }
  }
  a {
    text-decoration: none;
  }
  ul {
    margin: 0;
    padding: 20px 0;
    list-style: none;
    li {
      position: relative;
      border: 1px solid #333;
      border-bottom: none;
      &:last-child {
        border-bottom: 1px solid #333;
      }
      a {
        display: block;
        font-size: 13px;
        padding: 15px;
        color: #999;
        img{
          vertical-align: -1px;
        }
      }
      &.has-children {
        > a {
          border-right: 1px solid #333;
          margin-right: 50px;
          img{
            vertical-align: -2px;
          }
        }
        > .rd-dropdown-plus {
          display: block;
        }
      }
      ul {
        position: relative;
        display: none;
        padding: 0;
        margin-left: 0;
        &:before {
          content: "";
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 5px solid #333;
          position: absolute;
          top: 5px;
          left: 30px;
        }
        li {
          border-left: none;
          border-right: none;
          &:first-child {
            border-top-width: 5px;
            border-top-color: #333;
          }
        }
      }
    }
  }
  .active {
    > a {
      color: #fff;
    }
  }
}

#rd-mobile-icon {
  display: none;
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -18px;
  padding: 10px;
  cursor: pointer;
  line-height: 0;
  @media (max-width: $toggle-menu) {
    display: block;
  }
  span {
    display: inline-block;
    width: 20px;
  }
  i {
    display: block;
    width: 100%;
    height: 3px;
    margin: 2px auto;
    background-color: #555;
    transition: all 0.1s;
  }
  strong {
    display: inline-block;
    position: relative;
    top: -4px;
    margin-right: 5px;
    font-size: 13px;
    line-height: 1em;
    text-transform: uppercase;
  }
}

#mobile-search {
  position: relative;
  margin-bottom: 20px;
  input {
    font-size: 13px;
    height: 40px;
    padding: 22px 30px 22px 12px;
    color: #999;
    border: 1px solid #333;
    background-color: #151515;
    outline-color: #fff;
  }
  button {
    position: absolute;
    font-size: 11px;
    top: 14px;
    right: 5px;
    padding: 5px;
    margin: 0;
    border: none;
    outline: none;
    background: transparent;
  }
}