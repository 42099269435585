.my-container {
  max-width: calc(1630px + 10%);
  width: 100%;
  position: relative;
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;

  @media (min-width: 1201px) {
    padding-left: 5%;
    padding-right: 5%;
  }
  @media(min-width: 2000px){
    max-width: 2185px;
    padding: 0;
  }
  &::before {
    content: " ";
    display: table;
  }
  &::after {
    clear: both;
    content: " ";
    display: table;
  }
}
#masthead {
  display: block;
  position: relative;
  width: 100%;
  background-color: #fff;
  transition: background 0.2s;
  @media (max-width: 1024px) {
    position: fixed;
    z-index: 199;
  }
  .rd-inner {
    float: left;
    width: 100%;
    position: relative;
  }
  .megaSubmenu{
    position: absolute;
    width: 100%;
    z-index: 999;
    top: 98px;
    background: #fff;
    text-align: center;
    transition: background 0.2s;
    box-shadow: -1px 2px 5px 1px rgba(0, 0, 0, 0.39);
    border-top: 2px solid #7bb249;
    &-insert{
      padding: 25px;
      width: 25%;
      //min-height: 519px;
      vertical-align: top;
      float: left;
      &-img{
        padding-bottom: 15px;
        opacity: .5;
        transition: opacity 1s ease;
      }
      &-sub{
        text-align: left;
        p{
          color: #000000;
          font-family: "Museo Sans Cyrl 700",sans-serif;
          font-size: calc(14px + .7vw);
          font-weight: 400;
          line-height: 30px;
          a{
            text-decoration: none;
            color: #000;
          }
        }
        ul{
          list-style: none;
          padding: 0;
          li{
            a{
              text-decoration: none;
              color: #676666;
              font-family: "Museo Sans Cyrl 300",sans-serif;
              font-size: calc(8px + .7vw);
              font-weight: 400;
              line-height: 40px;
              transition: all 1s ease-in-out;
              &:hover{
                color: #000000;
                font-family: "Museo Sans Cyrl 700",sans-serif;
                &::after{
                  content: url('/img/megaMenu-arrow.png');
                  width: 30px;
                  height: 30px;
                  margin-left: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
  &.is-compact .megaSubmenu{
    top: 48px;
    background-color: rgba(255,255,255,.95);
  }
  #site-logo {
    float: left;
    max-width: 85%;
    img {
      max-height: 100%;
      padding-top: 5px;
      padding-bottom: 5px;
    }
    .rd-logo2x {
      display: none;
    }
  }
  .rd-sticky-item {
    height: 100px;
    line-height: 100px;
    transition: .5s;

    @media (max-width: 1024px) {
      height: 40px;
      line-height: 40px;
    }
  }
  &.is-sticky {
    position: fixed !important;
    z-index: 999;
    top: 0;
  }
  &.is-compact {
    background-color: rgba(255, 255, 255, 0.95);

    .rd-sticky-item {
      height: 50px;
      line-height: 50px
    }
  }
}

#site-nav {
  float: right;
  @media (max-width: $toggle-menu) {
    display: none!important;
  }
  a {
    display: inline-block;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    li {
      position: relative;
      > .rd-link:hover {
        color: $main-green;
      }
      ul {
        display: none;
        position: absolute;
        z-index: 300;
        top: 100%;
        width: 250px;
        background-color: #fff;
        -webkit-box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.07);
        box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.07);
        li {
          &:first-child {
            > .rd-link {
              border-top: none;
            }
          }
          .rd-link {
            display: block;
            position: relative;
            padding: 12px;
            color: #333;
            background-color: #fff;
            border-top: 1px solid #eeeeee;

            font-family: "Museo Sans Cyrl 300", sans-serif;
            font-size: 12px;
            line-height: 1em;
            font-style: normal;
            font-weight: 400;
            transition: all 0.3s;
            &:hover {
              color: $main-green;
              background-color: #f8f8f8;
              text-decoration: none;
            }
          }
        }
      }
    }
  }
  .active > .rd-link {
    color: $main-green;
  }
  .rd-link {
    font-family: "Museo Sans Cyrl 300", sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
  }
  > ul {
    > li {
      float: left;
      .rd-link {
        display: block;
        position: relative;
        padding-left: 10px;
        padding-right: 10px;
        color: #333;
        &:hover {
          color: $main-green;
          text-decoration: none;
        }
        img{
          vertical-align: -2px;
        }
      }
      > ul {
        display: none;
        position: absolute;
        z-index: 300;
        top: 100%;
        width: 120px;
        background-color: #fff;
        -webkit-box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.07);
        box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.07);
        border-top: 2px solid $main-green;
        margin-top: -2px;
      }
    }
  }
  .has-children {
    > a:after {
      content: '\f107';
      font-family: 'FontAwesome';
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 1;
      margin-left: 5px;
      opacity: 0.5;
    }
  }
}

.link-depth-0:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 0;
  height: 2px;
  background-color: $main-green;
  opacity: 0;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.link-depth-0:hover::before {
  opacity: 1;
}

#site-header-shadow {
  position: absolute;
  bottom: -5px;
  height: 5px;
  width: 100%;
  overflow: hidden;
  z-index: 9;
  user-select: none;
  &:after {
    content: "";
    position: absolute;
    top: -5px;
    left: 0;
    width: 100%;
    height: 100%;
    -moz-border-radius: 100%/5px;
    -webkit-border-radius: 100% 5px;
    border-radius: 100%/5px;
    -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  }
}
.overflowH{
  overflow: hidden;
}
.btnTop {
  position: fixed;
  bottom: 25px;
  left: 25px;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 5px;
  transition: all 0s!important;
  a {
    color: white;
    padding: 5px;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
    display: inline-block;
    vertical-align: middle;
    span {
      display: inline-block;
      width: 100%;
      text-align: center;
    }
  }
}
