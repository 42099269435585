/************** Variables ******************/

//Breakpoint of toggle mobile menu
$toggle-menu: 1024px;


// Mediaqueries-strategy
$lap-top-l: 1440px;
$tabletsWide: 1024px;
$tablets: 768px;
$mobile: 425px;


//Colors
$main-green: #7bb249;

//colors-strategy
$seashell: #F1F1F1;
$silver: #B9B9B9;
$black: #000;
$white: #fff;
$mercury: #E2E2E2;
$red: #FF0000;
$sushi: #7BB249;
