#footer {
  position: relative;
  padding-top: 48px;
  font-size: 16px;
  line-height: 1.65em;
  color: #999;
  background-color: #222;
  z-index: 1;
  .widget-title h4 {
    color: #fff;
    font-family: $museo700;
    font-size: 21px;
    margin-bottom: 25px;
  }
  a {
    color: #ccc;
    transition: all 0.2s;
    &:hover {
      color: #7bb249;
      text-decoration: none;
    }
  }
  label {
    display: block;
    width: 100%;
    margin-bottom: 10px;
  }
  input {
    display: block;
    width: 100%;
    margin-top: 5px;
  }
  .button {
    width: 100%;
    padding: 0;
    height: 40px;
    color: #fff;
    font-size: 16px;
    line-height: 40px;
    margin-top: 30px;
    font-weight: 600;
    border: 1px solid #7bb249;
    @media (max-width: 767px) {
      overflow: hidden;
    }
    &:before {
      border-width: 1px !important;
    }
  }
  ul {
    list-style-type: none;
    padding-left: 0;
  }
  .menu_item {
    > a {
      font-family: $museo700;
      font-size: 16px;
      color: #fff;
      &:hover {
        color: #7bb249;
        text-decoration: none;
      }
      &.fSitemapLink{
        color: #7e7c7c;
        font-family: "Museo Sans Cyrl 100",sans-serif;
        &:hover{
          color: #7bb249;
        }
      }
    }
    ul {
      margin-bottom: 25px;
    }
    li {
      a {
        color: #7e7c7c;
        font-family: $museo100;
        &:hover {
          color: #7bb249;
          text-decoration: none;
        }
      }
    }
  }
  form {
    max-width: 330px;
    font-size: 13px;
    @media(max-width: 767px){
      min-width:100%;
    }
    input {
      padding-left: 10px;
      padding-right: 10px;
      height: 35px;
      outline: none;
    }
  }
  .contacts-list {
    li {
      padding-left: 30px;
      position: relative;
      margin-bottom: 30px;
    }
    i {
      position: absolute;
      left: 0;
      top: 3px;
    }
  }
}

.telegram, .viber, .whatsapp {
  vertical-align: sub;
  opacity: 0.9;
  &:hover {
    opacity: 1;
    background-color: transparent !important;
  }
  &::after {
    border-radius: 3px;
  }
}

.telegram {
  position: relative;
  top: 3px;
  margin-left: 5px;
  &:after {
    content: "";
    display: inline-block;
    width: 25px;
    height: 25px;
    background: url("/img/svg/telegram.svg") center no-repeat;
    background-size: contain;
  }
}

.viber {
  position: relative;
  top: 3px;
  margin-left: 5px;
  &:after {
    content: "";
    display: inline-block;
    width: 25px;
    height: 25px;
    background: url("/img/svg/viber.svg") center no-repeat;
    background-size: contain;
  }
}

.whatsapp {
  position: relative;
  top: 3px;
  margin-left: 5px;
  &:after {
    content: "";
    display: inline-block;
    width: 25px;
    height: 25px;
    background: url("/img/svg/WhatsApp.svg") center no-repeat;
    background-size: contain;
  }
}

.footer-container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
  max-width: 1540px;
}

#copyright {
  position: relative;
  font-size: 12px;
  line-height: 1.5em;
  padding: 10px 0;
  color: #555;
  background-color: #111;
}

.route_widget {
  width: 100%;
  margin-bottom: 48px;
  word-wrap: break-word;
}

.module-social {
  vertical-align: top;
  display: inline-block;
  position: relative;
  font-size: 11px;
  line-height: 35px;
  margin: 0;
  padding: 0;
  border: none;
  z-index: 1;
  a {
    display: inline-block;
    font-size: 20px;
    text-align: center;
    vertical-align: top;
    line-height: 36px;
    width: 36px;
    margin: 0;
    &:hover {
      color: #fff !important;
      background-color: #7bb249;
      -webkit-box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.1);
      box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.1);
    }
  }
}

.widget {
  width: 100%;
  margin-bottom: 48px;
  word-wrap: break-word;
}