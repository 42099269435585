.custom-form {
  background: #f1f1f1;
  border-radius: 0;
  color: #000;
  max-width: 663px;
  width: 100%;
  padding: 5vmin 4.5vmin 8vmin;
  margin: auto;
  &.coments {
    display: none;
    max-width:100%;
    float: left;
    @media(max-width:991px){
      max-width:570px;
      float: none;
    }
    .header{
      margin-bottom: 20px;
    }
    .cs-space{
      text-align: center;
    }
    .form-submit{
      max-width:570px;
    }
  }
  .head-text {
    text-align: center;
  }
  .cs-column-text{
    margin-bottom: 30px;
  }
  textarea {
    display: block;
    min-height: 112px;
    border: 1px solid #000;
    width: 100%;
    resize: none;
    background: transparent;
    padding: 5px;
  }
}

.wpcf7-form-control-wrap {
  width: 100%;
}

.input {
  position: relative;
  z-index: 1;
  display: inline-block;
  margin: 1em 0;
  width: 100%;
  //width: calc(100% - 1em);
}

.input__field {
  position: relative;
  display: block;
  float: right;
  padding: 0.8em;
  width: 60%;
  border: none;
  border-radius: 0;
  font-family: 'Museo Sans Cyrl 500',sans-serif;
  z-index: 100;
  font-size: 18px;
  &:focus {
    box-shadow: none !important;
    outline: none;
  }
}

.input__label-content--madoka {
  font-family: "Museo Sans Cyrl 100", sans-serif;
  font-size: 18px;
}

.input__field--madoka:focus + .input__label--madoka .graphic--madoka, .input--filled .graphic--madoka {
  stroke-dashoffset: 0;
}

.input__field--madoka:focus + .input__label--madoka .input__label-content--madoka, .input--filled .input__label-content--madoka {
  transform: translate3d(0,25px,0);
  font-size: 1.5em;
  padding: 1.6em 0;
}

.input__field--madoka {
  width: 100%;
  background: transparent;
  color: #000;
}

.wpcf7-form-control {
  margin-top: 5px;
  max-width: 100%;
}

.wpcf7 label {
  font-weight: normal;
}

.input__label {
  display: inline-block;
  float: right;
  padding: 0 1em;
  width: 40%;
  color: rgb(85, 85, 85);
  font-weight: bold;
  font-size: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
}

.input__label--madoka {
  position: absolute;
  width: 100%;
  height: 100%;
  color: #000;
  text-align: left;
  cursor: text;
}

svg:not(:root) {
  overflow: hidden;
}

.graphic {
  position: absolute;
  top: 0;
  left: 0;
  fill: none;
}

.graphic--madoka {
  transform: scale3d(1, -1, 1);
  transition: stroke-dashoffset 0.3s;
  pointer-events: none;
  stroke: #000;
  stroke-width: 2px;
  stroke-dasharray: 962;
  stroke-dashoffset: 558;
}

.input__label-content {
  position: relative;
  display: block;
  padding: 1.2em 0;
  width: 100%;
}

.input__label-content--madoka {
  transform-origin: 0% 50%;
  transition: 0.3s;
}

.cs-space {
  clear: both;
  margin-top: 100px;
  border: none !important;
}

.attachment-full {
  max-width: 955px;
  width: 100%;
}

.wpb_row { 
  & > .row {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: row;
    @media (max-width: 1365px) {
      flex-direction: column;
      align-items: center;
    }
    .background-form {
      background-size: 90%;
      padding-bottom: 46.25%;
      background: url('/img/marketing/layer-5.jpg') no-repeat 160px 120px;
      @media (max-width: 991px) {
        width: 100%;
        padding-bottom: 71.25%;
        background-size: cover;
      }
    }
  }
}

.coments {
  @media(min-width:992px) {
    .input__label--madoka {
      &:before, &:after {
        content: "";
        display: block;
        height: 100%;
        width: 2px;
        background: #f1f1f1;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 999;
      }
      &:after {
        left: auto;
        right: 0;
      }
    }
  }
}




