body {
  font-size: 13px;
  line-height: 1.65em;
  font-style: normal;
  font-weight: 400;
}

.container {
  .container {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}

.main-content {
  padding-bottom: 70px;
}

.comment-header {
  text-align: center;
  .header {
    display: block;
    font-family: "Museo Sans Cyrl 700", sans-serif;
    font-size: 35px;
    line-height: 1;
    text-transform: uppercase;
    margin-bottom: 30px;
    @media (max-width: 460px) {
      font-size: 26px;
    }
  }
  .subheader {
    font-family: "Museo Sans Cyrl 500", sans-serif;
    color: #000;
    font-size: 15px;
    font-weight: 400;
    line-height: 45px;
    margin-bottom: 20px;
    span {
      color: #f5012f;
    }
    @media (max-width: 765px) {
      line-height: 1.2;
    }
  }
}

.cs-section {
  .wpb_row {
    margin-bottom: 0;
  }
  .wpb_content_element {
    margin-bottom: 35px;
  }
  .wpb_wrapper {
    position: relative;
    margin: 0 auto;
    width: 100%;
    .wpb_video_wrapper {
      padding-top: 56.25%;
      position: relative;
      width: 100%;
      iframe {
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        margin: 0;
        top: 0;
        left: 0;
        box-sizing: border-box;
      }
    }
  }
}

.page-content {
  .entry {
    &-img {
      img {
        width: 100%;
      }
    }
    &-header {
      padding: 20px;
    }
    &-title {
      font-family: "Museo Sans Cyrl 500", sans-serif;
      font-weight: 500;
      font-size: 18px;
      margin-bottom: 10px;
      a {
        text-decoration: none;
        color: #333;
        &:hover {
          color: #7bb249;
        }
      }
    }
    &-meta {
      line-height: 20px;
      margin-bottom: 35px;
      span {
        display: inline-block;
        color: #999;
        font-family: "Museo Sans Cyrl 500", sans-serif;
        font-weight: 400;
        vertical-align: middle;
        padding-right: 5%;
        font-size: 18px;
        &:last-child {
          padding: 0;
        }
        &::before {
          content: '';
          background-repeat: no-repeat;
          background-size: contain;
          display: inline-block;
          width: 20px;
          height: 20px;
          margin-right: 15px;
          vertical-align: top;
          background-position: center center;
          background-repeat: no-repeat;
        }
        a {
          color: inherit;
          text-decoration: none;
          cursor: pointer;
          vertical-align: top;
          &:hover {
            color: #7bb249;
          }
        }
      }
    }
    &-content {
      h2, h3, h4 {
        margin-bottom: 20px;
        font-weight: normal;
        &.light {
          font-family: 'Museo Sans Cyrl 100', sans-serif;
        }
        &.normal {
          font-family: 'Museo Sans Cyrl 400', sans-serif;
        }
        &.bold {
          font-family: 'Museo Sans Cyrl 700', sans-serif;
        }
      }
      p {
        font-size: 18px;
        line-height: 26px;
        color: #000000;
        margin-bottom: 20px;
        font-family: 'Museo Sans Cyrl 500', sans-serif;
      }
      iframe {
        width: 100%;
        border: none;
        outline: none;
        margin-bottom: 20px;

      }
      .imageBlock {
        position: relative;
        margin-bottom: 20px;
        .textBG {
          content: '';
          position: absolute;
          background: black;
          opacity: .5;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
        }
        .text {
          width: 80%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 35px;
          line-height: 38px;
          font-weight: bold;
          color: white;
          text-align: center;
        }
      }
      ul, ol {
        padding-left: 40px;
        font-size: 18px;
        line-height: 26px;
        color: #000;
        margin-bottom: 20px;
        font-family: 'Museo Sans Cyrl 400', sans-serif;
      }
      .list-square {
        list-style: square;
      }
      .list-disc {
        list-style: disc;
      }
      .list-circle {
        list-style: circle;
      }
      .list-line {
        padding-left: 0;
        list-style: none;
        li {
          &:before {
            content: '-';
            margin-right: 5px;
          }
        }

      }
      .list-decimal {
        list-style: decimal;
      }
      .list-roman {
        list-style: upper-roman;
      }
      .list-none {
        padding-left: 0;
        list-style: none;
      }
      .banner {
        display: inline-block;
        width: 100%;
        margin-bottom: 20px;
      }
      video {
        width: 100%;
        margin-bottom: 20px;
      }
    }
    &-date::before {
      background-image: url('/img/branding/entry-date.png');
    }
    &-cat-links::before {
      background-image: url('/img/branding/entry-cat-links.png');
    }
    &-comments-link::before {
      background-image: url('/img/branding/entry-comments-link.png');
    }
    &-love::before {
      background-image: url('/img/branding/entry-love.png');
    }
    &-views-count::before {
      background-image: url('/img/branding/entry-views-count.png');
    }
  }
}

//.post-navigation {
//  border-top: 1px solid #e8e8e8;
//  border-bottom: 1px solid #e8e8e8;
//  margin-top: 24px;
//  padding: 10px 0;
//  font-size: 11px;
//  font-weight: normal;
//  text-transform: uppercase;
//  line-height: 1.65em;
//  .nav-previous {
//    float: left;
//  }
//  .nav-next {
//    float: right;
//    text-align: right;
//  }
//  a {
//    display: block;
//    color: #333;
//    text-decoration: none;
//    &:hover{
//      color: #7bb249;
//    }
//  }
//}

//.clear:before, .clear:after {
//  content: " ";
//  display: table;
//}
//
//.clear:after {
//  clear: both;
//}

//.comments-area {
//  margin-top: 48px;
//}
//
//.comment-form label {
//  display: block;
//  margin-bottom: 5px
//}
//
//.comment-form .required {
//  color: #d63e33 !important
//}
//
//.comment-form textarea, .comment-form input[type="text"], .comment-form input[type="email"], .comment-form input[type="url"] {
//  width: 50%
//}
//
//.comment-form textarea {
//  width: 70%
//}
.form-submit {
  .cs-btn {
    display: inline-block;
    vertical-align: middle;
    margin: 3px 3px 3px 0;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
    font-weight: 700;
    text-transform: uppercase;
    background: none;
    border: 2px solid transparent;
    user-select: none;
    transition: all 0.2s ease-in-out;
    outline: none;
    &-flat {
      color: #fff !important;
      &-accent {
        background-color: #7bb249;
        &:hover {
          background-color: #97c370;
        }
      }
    }
    &-xs {
      font-size: 12px;
      padding: 6px 16px;
      line-height: 1.2244897963;
    }
  }

}

.custom-form {
  background: #f1f1f1;
  border-radius: 0;
  color: #000;
  max-width: 570px;
  width: 100%;
  padding: 75px 35px 50px 35px;
  @media (max-width: 1023px) {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

.wpcf7-form-control-wrap {
  width: 100%;
}

//.input {
//  position: relative;
//  z-index: 1;
//  display: inline-block;
//  margin: 1em 0;
//  //width: calc(100% - 1em);
//}
//
//.input__field {
//  position: relative;
//  display: block;
//  float: right;
//  padding: 0.8em;
//  width: 60%;
//  border: none;
//  border-radius: 0;
//  font-weight: bold;
//  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
//  -webkit-appearance: none;
//  z-index: 100;
//  font-size: 18px;
//  &:focus {
//    box-shadow: none !important;
//    outline: none;
//  }
//}

.head-text {
  text-align: center;
  span {
    font-family: "Museo Sans Cyrl 700", sans-serif;
    font-size: calc(.8vmin + 25px);
    text-transform: uppercase;
  }
  small {
    font-family: "Museo Sans Cyrl 200", sans-serif;
    font-size: calc(.8vmin + 14px);
  }
}

//.input__label-content--madoka {
//  font-family: "Museo Sans Cyrl 100", sans-serif;
//  font-size: 18px;
//}
//
//.input__field--madoka:focus + .input__label--madoka .graphic--madoka, .input--filled .graphic--madoka {
//  stroke-dashoffset: 0;
//}
//
//.input__field--madoka:focus + .input__label--madoka .input__label-content--madoka, .input--filled .input__label-content--madoka {
//  -webkit-transform: scale3d(0.81, 0.81, 1) translate3d(0, 2em, 0);
//  transform: scale3d(0.81, 0.81, 1) translate3d(0, 2em, 0);
//}
//
//.input__field--madoka {
//  width: 100%;
//  background: transparent;
//  color: #000;
//}

.wpcf7-form-control {
  margin-top: 5px;
  max-width: 100%;
}

.wpcf7 label {
  font-weight: normal;
}

//.input__label {
//  display: inline-block;
//  float: right;
//  padding: 0 1em;
//  width: 40%;
//  color: rgb(85, 85, 85);
//  font-weight: bold;
//  font-size: 100%;
//  -webkit-font-smoothing: antialiased;
//  -moz-osx-font-smoothing: grayscale;
//  -webkit-touch-callout: none;
//  -webkit-user-select: none;
//  -khtml-user-select: none;
//  -moz-user-select: none;
//  -ms-user-select: none;
//  user-select: none;
//}
//
//.input__label--madoka {
//  position: absolute;
//  width: 100%;
//  height: 100%;
//  color: #000;
//  text-align: left;
//  cursor: text;
//}
//
//svg:not(:root) {
//  overflow: hidden;
//}
//
//.graphic {
//  position: absolute;
//  top: 0;
//  left: 0;
//  fill: none;
//}
//
//.graphic--madoka {
//  -webkit-transform: scale3d(1, -1, 1);
//  transform: scale3d(1, -1, 1);
//  -webkit-transition: stroke-dashoffset 0.3s;
//  transition: stroke-dashoffset 0.3s;
//  pointer-events: none;
//  stroke: #000;
//  stroke-width: 2px;
//  stroke-dasharray: 962;
//  stroke-dashoffset: 558;
//}
//
//.input__label-content {
//  position: relative;
//  display: block;
//  padding: 1.6em 0;
//  width: 100%;
//}
//
//.input__label-content--madoka {
//  -webkit-transform-origin: 0% 50%;
//  transform-origin: 0% 50%;
//  -webkit-transition: -webkit-transform 0.3s;
//  transition: transform 0.3s;
//}

.cs-space {
  clear: both;
  margin-top: 50px;
  border: none !important;
}

.attachment-full {
  max-width: 955px;
  width: 100%;
}

.wpb_row {
  & > .row {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    @media (max-width: 1365px) {
      flex-direction: column;
    }
  }
}

.route_widget {
  width: 100%;
  margin-bottom: 48px;
  word-wrap: break-word;
  .widget-title {
    h4 {
      font-size: 16px;
      color: #7bb249;
      margin-bottom: 35px;
      @media (max-width: 992px) {
        margin-top: 35px;
      }
    }
  }
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    li {
      margin-bottom: 15px;
      padding-bottom: 15px;
      border-bottom: 1px solid #e8e8e8;
      a {
        color: #333;
        text-decoration: none;
        img {
          width: 100%;
          margin-bottom: 10px;
        }
        &:hover {
          color: #7bb249;
        }
      }
    }
  }
}

.cs_widget_custom_posts .cs-with-image li {
  position: relative;
  padding-left: 70px;
  min-height: 74px;
  margin-bottom: 10px;
  padding-bottom: 14px;
  a {
    display: block;
    width: 100%;
    text-decoration: none;
    &:hover {
      color: #7bb249;
    }
    img {
      -moz-border-radius: 2px;
      -webkit-border-radius: 2px;
      border-radius: 2px;
      position: absolute;
      left: 0;
      top: 2px;
      width: 60px;
    }
  }
}

.entry-footer {
  margin-top: 24px;
}

iframe {
  max-width: 100%;
  @media (max-width: 767px) {
    max-height: 300px;
  }
}

.blog-form {
  background: url(/img/blog-form-bg.png) no-repeat;
  background-size: contain;
  background-position: 10% center;
  padding-top: 30px;
  padding-bottom: 30px;
  @media(max-width: 991px) {
    background: none;
  }
}

.coments {
  textarea {
    margin-bottom: 15px;
  }
  .wpcf7 span.error {
    bottom: -5px;
    top: auto;
  }
  .likeWork {
    display: table;
    margin: 15px auto;
  }
}

.vote {
  padding: 0;
  display: flex;
  margin-top: 60px;
  margin-bottom: 60px;
  align-items: center;
  justify-content: space-around;
  @media(max-width: 767px) {
    flex-direction: column;
  }
  &-text {
    font-size: 27px;
    padding-right: 5px;
    @media(max-width: 767px) {
      padding-right: 0;
      text-align: center;
    }
  }
  .btnWrap {
    a {
      padding-left: 40px;
      padding-right: 40px;
      @media(max-width: 1199px) {
        padding-left: 100px;
        padding-right: 100px;
      }
      @media(max-width: 767px) {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
    @media(max-width: 767px) {
      margin-top: 30px;
    }
  }
}

.shortInfo {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  & > span {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #999 !important;
    img {
      width: auto !important;
      max-height: 13px;
      margin-right: 5px;
      margin-bottom: 0 !important;
      max-width: 18px;
    }
  }
}
