.modal-open {
  //overflow: hidden;
  //position: fixed;
  //width: 100%;
  .wrap{
    //margin-right: 15px;
  }
}

.modal {
  display: none;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  outline: 0
}

.modal.fade .modal-dialog {
  transform: translate(0, -25%);
  transition: transform 0.3s ease-out
}

.modal.in .modal-dialog {
  transform: translate(0, 0)
}

.modal-open .modal {
  overflow-x: auto;
  overflow-y: auto;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px
}

.modal-content {
  position: relative;
  background-color: #fff;
  border: 1px solid #999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  background-clip: padding-box;
  outline: 0
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #000;
  z-index: 1049
}

.modal-backdrop.fade {
  opacity: 0
}

.modal-backdrop.in {
  opacity: 0.5
}

.modal-header {
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
  min-height: 16.428571429px
}

.modal-header .fa {
  position: absolute;
  right: 15px;
  padding: 5px;
  cursor: pointer
}

.modal-title {
  margin: 0;
  line-height: 1.428571429
}

.modal-body {
  position: relative;
  padding: 15px
}

.modal-center .modal-dialog-center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%
}

.modal-center .modal-body {
  overflow-y: auto
}

.modal-center .modal-footer {
  margin-top: 0
}

@media (min-width: 768px) {
  .modal-dialog {
    width: 600px;
    margin: 50px auto
  }
  .modal-content {
    -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5)
  }
  .modal-sm {
    width: 300px
  }
}

@media (min-width: 992px) {
  .modal-lg {
    width: 900px
  }
}

@media (max-width: 768px) {
  .modal-center .modal-dialog-center {
    width: 100%
  }
}


.cs-top-module {
  vertical-align: top;
  display: inline-block;
  position: relative;
  padding: 0 6px;
  margin-right: -1px;
  border-left: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
  font-size: 11px;
  line-height: 35px
}

.cs-top-module:first-child {
  border-left: none;
  padding-left: 0
}

.cs-top-module:last-child {
  border-right: none;
  padding-right: 0
}

.cs-top-module > a,.cs-top-module .cs-open-modal {
  color: #333;
  display: inline-block
}

.cs-top-module > a:hover,.cs-top-module .cs-open-modal:hover {
  color: #428bca;
  text-decoration: none
}

.cs-top-module .cs-in {
  margin-right: 5px
}

.cs-top-module .cs-down {
  margin-left: 2px
}
.cs-module-social {
  margin: 0;
  padding: 0;
  border: none;
  z-index: 1
}
.cs-module-social a {
  display: inline-block;
  font-size: 12px;
  text-align: center;
  vertical-align: top;
  line-height: 36px;
  width: 36px
}

.cs-module-social a:hover {
  color: #fff !important;
  background-color: #7bb249;
  -webkit-box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.1)
}

.cs-module-social .cs-in {
  margin: 0
}

.thanks {
  position: relative
}

.thanks img {
  max-width: 100%;
  @media (max-width: 767px) {
    max-width: 50vw;
    display: table;
    margin: 0 auto;
  }
}

.thanks .right span {
  text-transform: uppercase;
  font-size: 48px;
  line-height: 1;
  margin-top: 240px;
  display: inline-block;
  color: #333;
  margin-bottom: 10px;
  font-weight: 700;
}

.thanks .right h3 {
  text-transform: uppercase;
  font-size: 30px;
  line-height: 1
}

.thanks button.close {
  position: absolute;
  top: 15px;
  right: 15px;
  height: 22px;
  width: 22px;
  border: none;
  z-index: 1000;
  background: transparent url('/img/close.png') center center no-repeat
}

@media ( max-width: 992px ) {
  .thanks .left {
    padding-top: 50px
  }

  .thanks .right {
    padding-bottom: 20px
  }

  .thanks .right span {
    text-align: center;
    margin-top: 20px;
    font-size: 30px;
    width: 100%;
    @media (max-width: 767px) {
      font-size:26px;
    }
  }

  .thanks .right h3 {
    text-align: center;
    font-size: 25px;
    @media (max-width: 767px) {
      font-size:22px;
    }
  }

  .thanks .right .cs-module-social a {
    font-size: 25px
  }

  .thanks button.close {
    width: 30px;
    height: 30px
  }
}

@media ( max-width: 540px ) {
  .cs-column-text h1 {
    font-size: 38px !important
  }
}

.wpcf7 span.error {
  position: absolute;
  font-size: 16px;
  color: red;
  top: 15px;
  left: 0;
  right: 0;
  text-align: center;
  @media (max-width: 570px) {
    font-size: 12px;
  }
}

.modal .wpcf7 span.error {
  top: 2px;
}

.widget label{
  position: relative;
}
.widget span.error{
  position: absolute;
  top: 33px;
  color: red;
  width: 100%;
  text-align: center;
}
//.fancybox-slide--iframe .fancybox-content {
//  width: 70% !important;
//  height: 76% !important;
//}