@charset "UTF-8";
html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body {
  margin: 0; }

#complex {
  display: block;
  max-width: 1920px;
  margin: 0 auto; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block; }

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
  width: 100%; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden],
template {
  display: none; }

a {
  background-color: transparent; }

a:active,
a:hover, a:focus, button:focus {
  outline: none !important; }

abbr[title] {
  border-bottom: 1px dotted; }

b,
strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

h1 {
  font-size: 2em;
  margin: 0.67em 0; }

mark {
  background: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 1em 40px; }

hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0; }

pre {
  overflow: auto; }

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0; }

button {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled],
html input[disabled] {
  cursor: default; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input {
  line-height: normal; }

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

legend {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto; }

optgroup {
  font-weight: bold; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

html {
  font-size: 62.5%;
  -webkit-tap-highlight-color: transparent; }

body {
  color: #000;
  background-color: #fff; }

input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

a {
  color: #428bca;
  text-decoration: none; }

a:hover {
  text-decoration: underline; }

b,
strong {
  font-weight: bold; }

figure {
  margin: 0; }

img {
  border: 0;
  -ms-interpolation-mode: bicubic;
  max-width: 100%;
  height: auto;
  vertical-align: middle; }

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto; }

hr {
  border: 0;
  border-top: 1px solid #eeeeee;
  margin-top: 20px;
  margin-bottom: 20px; }

::selection {
  color: #fff;
  background-color: #428bca; }

::-moz-selection {
  color: #fff;
  background-color: #428bca; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  font-weight: bold;
  line-height: 1.1;
  color: #333; }

h1 small,
h1 .small, h2 small,
h2 .small, h3 small,
h3 .small, h4 small,
h4 .small, h5 small,
h5 .small, h6 small,
h6 .small {
  font-weight: normal;
  line-height: 1; }

h1,
h2,
h3 {
  margin-bottom: 10px; }

h1 small,
h1 .small,
h2 small,
h2 .small,
h3 small,
h3 .small {
  font-size: 65%; }

h4,
h5,
h6 {
  margin-bottom: 10px; }

h4 small,
h4 .small,
h5 small,
h5 .small,
h6 small,
h6 .small {
  font-size: 75%; }

h1 {
  font-size: 36px; }

h2 {
  font-size: 30px; }

h3 {
  font-size: 24px; }

h4 {
  font-size: 18px; }

h5 {
  font-size: 14px; }

h6 {
  font-size: 12px; }

p {
  margin: 0 0 20px; }

p:last-child {
  margin-bottom: 0; }

small,
.small {
  font-size: 85%; }

cite {
  font-style: normal; }

mark,
.mark {
  background-color: #fcf8e3;
  padding: .2em; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

.text-justify {
  text-align: justify; }

.text-muted {
  color: #777777; }

ul,
ol {
  margin-top: 0;
  margin-bottom: 10px; }

ul ul,
ul ol,
ol ul,
ol ol {
  margin-bottom: 0; }

.list-unstyled, .list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline {
  margin-left: -5px; }

.list-inline > li {
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px; }

dl {
  margin-top: 0;
  margin-bottom: 20px; }

dt,
dd {
  line-height: 1.428571429; }

dt {
  font-weight: 700; }

dd {
  margin-left: 0; }

sup {
  color: red; }

/************** Variables ******************/
@font-face {
  font-display: swap;
  font-family: "Museo Sans Cyrl 100";
  src: local("Museo Sans Cyrl 100"), url("/fonts/museosanscyrl-100.woff") format("woff"), url("/fonts/museosanscyrl-100.woff2") format("woff2");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-display: swap;
  font-family: "Museo Sans Cyrl 300";
  src: local("Museo Sans Cyrl 300"), url("/fonts/museosanscyrl-300.woff") format("woff"), url("/fonts/museosanscyrl-300.woff2") format("woff2");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-display: swap;
  font-family: "Museo Sans Cyrl 500";
  src: local("Museo Sans Cyrl 500"), url("/fonts/museosanscyrl-500.woff") format("woff"), url("/fonts/museosanscyrl-500.woff2") format("woff2");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-display: swap;
  font-family: "Museo Sans Cyrl 700";
  src: local("Museo Sans Cyrl 700"), url("/fonts/museosanscyrl-700.woff") format("woff"), url("/fonts/museosanscyrl-700.woff2") format("woff2");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-display: swap;
  font-family: "Museo Sans Cyrl 900";
  src: local("Museo Sans Cyrl 900"), url("/fonts/museosanscyrl-900.woff") format("woff"), url("/fonts/museosanscyrl-900.woff2") format("woff2");
  font-style: normal;
  font-weight: normal; }

/* FONT PATH
 * -------------------------- */
@font-face {
  font-family: 'FontAwesome';
  font-display: swap;
  src: url("../fonts/fontawesome-webfont.eot?v=4.7.0");
  src: url("../fonts/fontawesome-webfont.eot?#iefix&v=4.7.0") format("embedded-opentype"), url("../fonts/fontawesome-webfont.woff2?v=4.7.0") format("woff2"), url("../fonts/fontawesome-webfont.woff?v=4.7.0") format("woff"), url("../fonts/fontawesome-webfont.ttf?v=4.7.0") format("truetype"), url("../fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular") format("svg");
  font-weight: normal;
  font-style: normal; }

.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

/* makes the font 33% larger relative to the icon container */
.fa-lg {
  font-size: 1.33333em;
  line-height: 0.75em;
  vertical-align: -15%; }

.fa-2x {
  font-size: 2em; }

.fa-3x {
  font-size: 3em; }

.fa-4x {
  font-size: 4em; }

.fa-5x {
  font-size: 5em; }

.fa-fw {
  width: 1.28571em;
  text-align: center; }

.fa-ul {
  padding-left: 0;
  margin-left: 2.14286em;
  list-style-type: none; }
  .fa-ul > li {
    position: relative; }

.fa-li {
  position: absolute;
  left: -2.14286em;
  width: 2.14286em;
  top: 0.14286em;
  text-align: center; }
  .fa-li.fa-lg {
    left: -1.85714em; }

.fa-border {
  padding: .2em .25em .15em;
  border: solid 0.08em #eee;
  border-radius: .1em; }

.fa-pull-left {
  float: left; }

.fa-pull-right {
  float: right; }

.fa.fa-pull-left {
  margin-right: .3em; }

.fa.fa-pull-right {
  margin-left: .3em; }

/* Deprecated as of 4.4.0 */
.pull-right {
  float: right; }

.pull-left {
  float: left; }

.fa.pull-left {
  margin-right: .3em; }

.fa.pull-right {
  margin-left: .3em; }

.fa-spin {
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear; }

.fa-pulse {
  -webkit-animation: fa-spin 1s infinite steps(8);
  animation: fa-spin 1s infinite steps(8); }

@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

.fa-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg); }

.fa-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg); }

.fa-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg); }

.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  -webkit-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1); }

.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  -webkit-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  transform: scale(1, -1); }

:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270,
:root .fa-flip-horizontal,
:root .fa-flip-vertical {
  filter: none; }

.fa-stack {
  position: relative;
  display: inline-block;
  width: 2em;
  height: 2em;
  line-height: 2em;
  vertical-align: middle; }

.fa-stack-1x, .fa-stack-2x {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center; }

.fa-stack-1x {
  line-height: inherit; }

.fa-stack-2x {
  font-size: 2em; }

.fa-inverse {
  color: #fff; }

/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
   readers do not read off random characters that represent icons */
.fa-glass:before {
  content: ""; }

.fa-music:before {
  content: ""; }

.fa-search:before {
  content: ""; }

.fa-envelope-o:before {
  content: ""; }

.fa-heart:before {
  content: ""; }

.fa-star:before {
  content: ""; }

.fa-star-o:before {
  content: ""; }

.fa-user:before {
  content: ""; }

.fa-film:before {
  content: ""; }

.fa-th-large:before {
  content: ""; }

.fa-th:before {
  content: ""; }

.fa-th-list:before {
  content: ""; }

.fa-check:before {
  content: ""; }

.fa-remove:before,
.fa-close:before,
.fa-times:before {
  content: ""; }

.fa-search-plus:before {
  content: ""; }

.fa-search-minus:before {
  content: ""; }

.fa-power-off:before {
  content: ""; }

.fa-signal:before {
  content: ""; }

.fa-gear:before,
.fa-cog:before {
  content: ""; }

.fa-trash-o:before {
  content: ""; }

.fa-home:before {
  content: ""; }

.fa-file-o:before {
  content: ""; }

.fa-clock-o:before {
  content: ""; }

.fa-road:before {
  content: ""; }

.fa-download:before {
  content: ""; }

.fa-arrow-circle-o-down:before {
  content: ""; }

.fa-arrow-circle-o-up:before {
  content: ""; }

.fa-inbox:before {
  content: ""; }

.fa-play-circle-o:before {
  content: ""; }

.fa-rotate-right:before,
.fa-repeat:before {
  content: ""; }

.fa-refresh:before {
  content: ""; }

.fa-list-alt:before {
  content: ""; }

.fa-lock:before {
  content: ""; }

.fa-flag:before {
  content: ""; }

.fa-headphones:before {
  content: ""; }

.fa-volume-off:before {
  content: ""; }

.fa-volume-down:before {
  content: ""; }

.fa-volume-up:before {
  content: ""; }

.fa-qrcode:before {
  content: ""; }

.fa-barcode:before {
  content: ""; }

.fa-tag:before {
  content: ""; }

.fa-tags:before {
  content: ""; }

.fa-book:before {
  content: ""; }

.fa-bookmark:before {
  content: ""; }

.fa-print:before {
  content: ""; }

.fa-camera:before {
  content: ""; }

.fa-font:before {
  content: ""; }

.fa-bold:before {
  content: ""; }

.fa-italic:before {
  content: ""; }

.fa-text-height:before {
  content: ""; }

.fa-text-width:before {
  content: ""; }

.fa-align-left:before {
  content: ""; }

.fa-align-center:before {
  content: ""; }

.fa-align-right:before {
  content: ""; }

.fa-align-justify:before {
  content: ""; }

.fa-list:before {
  content: ""; }

.fa-dedent:before,
.fa-outdent:before {
  content: ""; }

.fa-indent:before {
  content: ""; }

.fa-video-camera:before {
  content: ""; }

.fa-photo:before,
.fa-image:before,
.fa-picture-o:before {
  content: ""; }

.fa-pencil:before {
  content: ""; }

.fa-map-marker:before {
  content: ""; }

.fa-adjust:before {
  content: ""; }

.fa-tint:before {
  content: ""; }

.fa-edit:before,
.fa-pencil-square-o:before {
  content: ""; }

.fa-share-square-o:before {
  content: ""; }

.fa-check-square-o:before {
  content: ""; }

.fa-arrows:before {
  content: ""; }

.fa-step-backward:before {
  content: ""; }

.fa-fast-backward:before {
  content: ""; }

.fa-backward:before {
  content: ""; }

.fa-play:before {
  content: ""; }

.fa-pause:before {
  content: ""; }

.fa-stop:before {
  content: ""; }

.fa-forward:before {
  content: ""; }

.fa-fast-forward:before {
  content: ""; }

.fa-step-forward:before {
  content: ""; }

.fa-eject:before {
  content: ""; }

.fa-chevron-left:before {
  content: ""; }

.fa-chevron-right:before {
  content: ""; }

.fa-plus-circle:before {
  content: ""; }

.fa-minus-circle:before {
  content: ""; }

.fa-times-circle:before {
  content: ""; }

.fa-check-circle:before {
  content: ""; }

.fa-question-circle:before {
  content: ""; }

.fa-info-circle:before {
  content: ""; }

.fa-crosshairs:before {
  content: ""; }

.fa-times-circle-o:before {
  content: ""; }

.fa-check-circle-o:before {
  content: ""; }

.fa-ban:before {
  content: ""; }

.fa-arrow-left:before {
  content: ""; }

.fa-arrow-right:before {
  content: ""; }

.fa-arrow-up:before {
  content: ""; }

.fa-arrow-down:before {
  content: ""; }

.fa-mail-forward:before,
.fa-share:before {
  content: ""; }

.fa-expand:before {
  content: ""; }

.fa-compress:before {
  content: ""; }

.fa-plus:before {
  content: ""; }

.fa-minus:before {
  content: ""; }

.fa-asterisk:before {
  content: ""; }

.fa-exclamation-circle:before {
  content: ""; }

.fa-gift:before {
  content: ""; }

.fa-leaf:before {
  content: ""; }

.fa-fire:before {
  content: ""; }

.fa-eye:before {
  content: ""; }

.fa-eye-slash:before {
  content: ""; }

.fa-warning:before,
.fa-exclamation-triangle:before {
  content: ""; }

.fa-plane:before {
  content: ""; }

.fa-calendar:before {
  content: ""; }

.fa-random:before {
  content: ""; }

.fa-comment:before {
  content: ""; }

.fa-magnet:before {
  content: ""; }

.fa-chevron-up:before {
  content: ""; }

.fa-chevron-down:before {
  content: ""; }

.fa-retweet:before {
  content: ""; }

.fa-shopping-cart:before {
  content: ""; }

.fa-folder:before {
  content: ""; }

.fa-folder-open:before {
  content: ""; }

.fa-arrows-v:before {
  content: ""; }

.fa-arrows-h:before {
  content: ""; }

.fa-bar-chart-o:before,
.fa-bar-chart:before {
  content: ""; }

.fa-twitter-square:before {
  content: ""; }

.fa-facebook-square:before {
  content: ""; }

.fa-camera-retro:before {
  content: ""; }

.fa-key:before {
  content: ""; }

.fa-gears:before,
.fa-cogs:before {
  content: ""; }

.fa-comments:before {
  content: ""; }

.fa-thumbs-o-up:before {
  content: ""; }

.fa-thumbs-o-down:before {
  content: ""; }

.fa-star-half:before {
  content: ""; }

.fa-heart-o:before {
  content: ""; }

.fa-sign-out:before {
  content: ""; }

.fa-linkedin-square:before {
  content: ""; }

.fa-thumb-tack:before {
  content: ""; }

.fa-external-link:before {
  content: ""; }

.fa-sign-in:before {
  content: ""; }

.fa-trophy:before {
  content: ""; }

.fa-github-square:before {
  content: ""; }

.fa-upload:before {
  content: ""; }

.fa-lemon-o:before {
  content: ""; }

.fa-phone:before {
  content: ""; }

.fa-square-o:before {
  content: ""; }

.fa-bookmark-o:before {
  content: ""; }

.fa-phone-square:before {
  content: ""; }

.fa-twitter:before {
  content: ""; }

.fa-facebook-f:before,
.fa-facebook:before {
  content: ""; }

.fa-github:before {
  content: ""; }

.fa-unlock:before {
  content: ""; }

.fa-credit-card:before {
  content: ""; }

.fa-feed:before,
.fa-rss:before {
  content: ""; }

.fa-hdd-o:before {
  content: ""; }

.fa-bullhorn:before {
  content: ""; }

.fa-bell:before {
  content: ""; }

.fa-certificate:before {
  content: ""; }

.fa-hand-o-right:before {
  content: ""; }

.fa-hand-o-left:before {
  content: ""; }

.fa-hand-o-up:before {
  content: ""; }

.fa-hand-o-down:before {
  content: ""; }

.fa-arrow-circle-left:before {
  content: ""; }

.fa-arrow-circle-right:before {
  content: ""; }

.fa-arrow-circle-up:before {
  content: ""; }

.fa-arrow-circle-down:before {
  content: ""; }

.fa-globe:before {
  content: ""; }

.fa-wrench:before {
  content: ""; }

.fa-tasks:before {
  content: ""; }

.fa-filter:before {
  content: ""; }

.fa-briefcase:before {
  content: ""; }

.fa-arrows-alt:before {
  content: ""; }

.fa-group:before,
.fa-users:before {
  content: ""; }

.fa-chain:before,
.fa-link:before {
  content: ""; }

.fa-cloud:before {
  content: ""; }

.fa-flask:before {
  content: ""; }

.fa-cut:before,
.fa-scissors:before {
  content: ""; }

.fa-copy:before,
.fa-files-o:before {
  content: ""; }

.fa-paperclip:before {
  content: ""; }

.fa-save:before,
.fa-floppy-o:before {
  content: ""; }

.fa-square:before {
  content: ""; }

.fa-navicon:before,
.fa-reorder:before,
.fa-bars:before {
  content: ""; }

.fa-list-ul:before {
  content: ""; }

.fa-list-ol:before {
  content: ""; }

.fa-strikethrough:before {
  content: ""; }

.fa-underline:before {
  content: ""; }

.fa-table:before {
  content: ""; }

.fa-magic:before {
  content: ""; }

.fa-truck:before {
  content: ""; }

.fa-pinterest:before {
  content: ""; }

.fa-pinterest-square:before {
  content: ""; }

.fa-google-plus-square:before {
  content: ""; }

.fa-google-plus:before {
  content: ""; }

.fa-money:before {
  content: ""; }

.fa-caret-down:before {
  content: ""; }

.fa-caret-up:before {
  content: ""; }

.fa-caret-left:before {
  content: ""; }

.fa-caret-right:before {
  content: ""; }

.fa-columns:before {
  content: ""; }

.fa-unsorted:before,
.fa-sort:before {
  content: ""; }

.fa-sort-down:before,
.fa-sort-desc:before {
  content: ""; }

.fa-sort-up:before,
.fa-sort-asc:before {
  content: ""; }

.fa-envelope:before {
  content: ""; }

.fa-linkedin:before {
  content: ""; }

.fa-rotate-left:before,
.fa-undo:before {
  content: ""; }

.fa-legal:before,
.fa-gavel:before {
  content: ""; }

.fa-dashboard:before,
.fa-tachometer:before {
  content: ""; }

.fa-comment-o:before {
  content: ""; }

.fa-comments-o:before {
  content: ""; }

.fa-flash:before,
.fa-bolt:before {
  content: ""; }

.fa-sitemap:before {
  content: ""; }

.fa-umbrella:before {
  content: ""; }

.fa-paste:before,
.fa-clipboard:before {
  content: ""; }

.fa-lightbulb-o:before {
  content: ""; }

.fa-exchange:before {
  content: ""; }

.fa-cloud-download:before {
  content: ""; }

.fa-cloud-upload:before {
  content: ""; }

.fa-user-md:before {
  content: ""; }

.fa-stethoscope:before {
  content: ""; }

.fa-suitcase:before {
  content: ""; }

.fa-bell-o:before {
  content: ""; }

.fa-coffee:before {
  content: ""; }

.fa-cutlery:before {
  content: ""; }

.fa-file-text-o:before {
  content: ""; }

.fa-building-o:before {
  content: ""; }

.fa-hospital-o:before {
  content: ""; }

.fa-ambulance:before {
  content: ""; }

.fa-medkit:before {
  content: ""; }

.fa-fighter-jet:before {
  content: ""; }

.fa-beer:before {
  content: ""; }

.fa-h-square:before {
  content: ""; }

.fa-plus-square:before {
  content: ""; }

.fa-angle-double-left:before {
  content: ""; }

.fa-angle-double-right:before {
  content: ""; }

.fa-angle-double-up:before {
  content: ""; }

.fa-angle-double-down:before {
  content: ""; }

.fa-angle-left:before {
  content: ""; }

.fa-angle-right:before {
  content: ""; }

.fa-angle-up:before {
  content: ""; }

.fa-angle-down:before {
  content: ""; }

.fa-desktop:before {
  content: ""; }

.fa-laptop:before {
  content: ""; }

.fa-tablet:before {
  content: ""; }

.fa-mobile-phone:before,
.fa-mobile:before {
  content: ""; }

.fa-circle-o:before {
  content: ""; }

.fa-quote-left:before {
  content: ""; }

.fa-quote-right:before {
  content: ""; }

.fa-spinner:before {
  content: ""; }

.fa-circle:before {
  content: ""; }

.fa-mail-reply:before,
.fa-reply:before {
  content: ""; }

.fa-github-alt:before {
  content: ""; }

.fa-folder-o:before {
  content: ""; }

.fa-folder-open-o:before {
  content: ""; }

.fa-smile-o:before {
  content: ""; }

.fa-frown-o:before {
  content: ""; }

.fa-meh-o:before {
  content: ""; }

.fa-gamepad:before {
  content: ""; }

.fa-keyboard-o:before {
  content: ""; }

.fa-flag-o:before {
  content: ""; }

.fa-flag-checkered:before {
  content: ""; }

.fa-terminal:before {
  content: ""; }

.fa-code:before {
  content: ""; }

.fa-mail-reply-all:before,
.fa-reply-all:before {
  content: ""; }

.fa-star-half-empty:before,
.fa-star-half-full:before,
.fa-star-half-o:before {
  content: ""; }

.fa-location-arrow:before {
  content: ""; }

.fa-crop:before {
  content: ""; }

.fa-code-fork:before {
  content: ""; }

.fa-unlink:before,
.fa-chain-broken:before {
  content: ""; }

.fa-question:before {
  content: ""; }

.fa-info:before {
  content: ""; }

.fa-exclamation:before {
  content: ""; }

.fa-superscript:before {
  content: ""; }

.fa-subscript:before {
  content: ""; }

.fa-eraser:before {
  content: ""; }

.fa-puzzle-piece:before {
  content: ""; }

.fa-microphone:before {
  content: ""; }

.fa-microphone-slash:before {
  content: ""; }

.fa-shield:before {
  content: ""; }

.fa-calendar-o:before {
  content: ""; }

.fa-fire-extinguisher:before {
  content: ""; }

.fa-rocket:before {
  content: ""; }

.fa-maxcdn:before {
  content: ""; }

.fa-chevron-circle-left:before {
  content: ""; }

.fa-chevron-circle-right:before {
  content: ""; }

.fa-chevron-circle-up:before {
  content: ""; }

.fa-chevron-circle-down:before {
  content: ""; }

.fa-html5:before {
  content: ""; }

.fa-css3:before {
  content: ""; }

.fa-anchor:before {
  content: ""; }

.fa-unlock-alt:before {
  content: ""; }

.fa-bullseye:before {
  content: ""; }

.fa-ellipsis-h:before {
  content: ""; }

.fa-ellipsis-v:before {
  content: ""; }

.fa-rss-square:before {
  content: ""; }

.fa-play-circle:before {
  content: ""; }

.fa-ticket:before {
  content: ""; }

.fa-minus-square:before {
  content: ""; }

.fa-minus-square-o:before {
  content: ""; }

.fa-level-up:before {
  content: ""; }

.fa-level-down:before {
  content: ""; }

.fa-check-square:before {
  content: ""; }

.fa-pencil-square:before {
  content: ""; }

.fa-external-link-square:before {
  content: ""; }

.fa-share-square:before {
  content: ""; }

.fa-compass:before {
  content: ""; }

.fa-toggle-down:before,
.fa-caret-square-o-down:before {
  content: ""; }

.fa-toggle-up:before,
.fa-caret-square-o-up:before {
  content: ""; }

.fa-toggle-right:before,
.fa-caret-square-o-right:before {
  content: ""; }

.fa-euro:before,
.fa-eur:before {
  content: ""; }

.fa-gbp:before {
  content: ""; }

.fa-dollar:before,
.fa-usd:before {
  content: ""; }

.fa-rupee:before,
.fa-inr:before {
  content: ""; }

.fa-cny:before,
.fa-rmb:before,
.fa-yen:before,
.fa-jpy:before {
  content: ""; }

.fa-ruble:before,
.fa-rouble:before,
.fa-rub:before {
  content: ""; }

.fa-won:before,
.fa-krw:before {
  content: ""; }

.fa-bitcoin:before,
.fa-btc:before {
  content: ""; }

.fa-file:before {
  content: ""; }

.fa-file-text:before {
  content: ""; }

.fa-sort-alpha-asc:before {
  content: ""; }

.fa-sort-alpha-desc:before {
  content: ""; }

.fa-sort-amount-asc:before {
  content: ""; }

.fa-sort-amount-desc:before {
  content: ""; }

.fa-sort-numeric-asc:before {
  content: ""; }

.fa-sort-numeric-desc:before {
  content: ""; }

.fa-thumbs-up:before {
  content: ""; }

.fa-thumbs-down:before {
  content: ""; }

.fa-youtube-square:before {
  content: ""; }

.fa-youtube:before {
  content: ""; }

.fa-xing:before {
  content: ""; }

.fa-xing-square:before {
  content: ""; }

.fa-youtube-play:before {
  content: ""; }

.fa-dropbox:before {
  content: ""; }

.fa-stack-overflow:before {
  content: ""; }

.fa-instagram:before {
  content: ""; }

.fa-flickr:before {
  content: ""; }

.fa-adn:before {
  content: ""; }

.fa-bitbucket:before {
  content: ""; }

.fa-bitbucket-square:before {
  content: ""; }

.fa-tumblr:before {
  content: ""; }

.fa-tumblr-square:before {
  content: ""; }

.fa-long-arrow-down:before {
  content: ""; }

.fa-long-arrow-up:before {
  content: ""; }

.fa-long-arrow-left:before {
  content: ""; }

.fa-long-arrow-right:before {
  content: ""; }

.fa-apple:before {
  content: ""; }

.fa-windows:before {
  content: ""; }

.fa-android:before {
  content: ""; }

.fa-linux:before {
  content: ""; }

.fa-dribbble:before {
  content: ""; }

.fa-skype:before {
  content: ""; }

.fa-foursquare:before {
  content: ""; }

.fa-trello:before {
  content: ""; }

.fa-female:before {
  content: ""; }

.fa-male:before {
  content: ""; }

.fa-gittip:before,
.fa-gratipay:before {
  content: ""; }

.fa-sun-o:before {
  content: ""; }

.fa-moon-o:before {
  content: ""; }

.fa-archive:before {
  content: ""; }

.fa-bug:before {
  content: ""; }

.fa-vk:before {
  content: ""; }

.fa-weibo:before {
  content: ""; }

.fa-renren:before {
  content: ""; }

.fa-pagelines:before {
  content: ""; }

.fa-stack-exchange:before {
  content: ""; }

.fa-arrow-circle-o-right:before {
  content: ""; }

.fa-arrow-circle-o-left:before {
  content: ""; }

.fa-toggle-left:before,
.fa-caret-square-o-left:before {
  content: ""; }

.fa-dot-circle-o:before {
  content: ""; }

.fa-wheelchair:before {
  content: ""; }

.fa-vimeo-square:before {
  content: ""; }

.fa-turkish-lira:before,
.fa-try:before {
  content: ""; }

.fa-plus-square-o:before {
  content: ""; }

.fa-space-shuttle:before {
  content: ""; }

.fa-slack:before {
  content: ""; }

.fa-envelope-square:before {
  content: ""; }

.fa-wordpress:before {
  content: ""; }

.fa-openid:before {
  content: ""; }

.fa-institution:before,
.fa-bank:before,
.fa-university:before {
  content: ""; }

.fa-mortar-board:before,
.fa-graduation-cap:before {
  content: ""; }

.fa-yahoo:before {
  content: ""; }

.fa-google:before {
  content: ""; }

.fa-reddit:before {
  content: ""; }

.fa-reddit-square:before {
  content: ""; }

.fa-stumbleupon-circle:before {
  content: ""; }

.fa-stumbleupon:before {
  content: ""; }

.fa-delicious:before {
  content: ""; }

.fa-digg:before {
  content: ""; }

.fa-pied-piper-pp:before {
  content: ""; }

.fa-pied-piper-alt:before {
  content: ""; }

.fa-drupal:before {
  content: ""; }

.fa-joomla:before {
  content: ""; }

.fa-language:before {
  content: ""; }

.fa-fax:before {
  content: ""; }

.fa-building:before {
  content: ""; }

.fa-child:before {
  content: ""; }

.fa-paw:before {
  content: ""; }

.fa-spoon:before {
  content: ""; }

.fa-cube:before {
  content: ""; }

.fa-cubes:before {
  content: ""; }

.fa-behance:before {
  content: ""; }

.fa-behance-square:before {
  content: ""; }

.fa-steam:before {
  content: ""; }

.fa-steam-square:before {
  content: ""; }

.fa-recycle:before {
  content: ""; }

.fa-automobile:before,
.fa-car:before {
  content: ""; }

.fa-cab:before,
.fa-taxi:before {
  content: ""; }

.fa-tree:before {
  content: ""; }

.fa-spotify:before {
  content: ""; }

.fa-deviantart:before {
  content: ""; }

.fa-soundcloud:before {
  content: ""; }

.fa-database:before {
  content: ""; }

.fa-file-pdf-o:before {
  content: ""; }

.fa-file-word-o:before {
  content: ""; }

.fa-file-excel-o:before {
  content: ""; }

.fa-file-powerpoint-o:before {
  content: ""; }

.fa-file-photo-o:before,
.fa-file-picture-o:before,
.fa-file-image-o:before {
  content: ""; }

.fa-file-zip-o:before,
.fa-file-archive-o:before {
  content: ""; }

.fa-file-sound-o:before,
.fa-file-audio-o:before {
  content: ""; }

.fa-file-movie-o:before,
.fa-file-video-o:before {
  content: ""; }

.fa-file-code-o:before {
  content: ""; }

.fa-vine:before {
  content: ""; }

.fa-codepen:before {
  content: ""; }

.fa-jsfiddle:before {
  content: ""; }

.fa-life-bouy:before,
.fa-life-buoy:before,
.fa-life-saver:before,
.fa-support:before,
.fa-life-ring:before {
  content: ""; }

.fa-circle-o-notch:before {
  content: ""; }

.fa-ra:before,
.fa-resistance:before,
.fa-rebel:before {
  content: ""; }

.fa-ge:before,
.fa-empire:before {
  content: ""; }

.fa-git-square:before {
  content: ""; }

.fa-git:before {
  content: ""; }

.fa-y-combinator-square:before,
.fa-yc-square:before,
.fa-hacker-news:before {
  content: ""; }

.fa-tencent-weibo:before {
  content: ""; }

.fa-qq:before {
  content: ""; }

.fa-wechat:before,
.fa-weixin:before {
  content: ""; }

.fa-send:before,
.fa-paper-plane:before {
  content: ""; }

.fa-send-o:before,
.fa-paper-plane-o:before {
  content: ""; }

.fa-history:before {
  content: ""; }

.fa-circle-thin:before {
  content: ""; }

.fa-header:before {
  content: ""; }

.fa-paragraph:before {
  content: ""; }

.fa-sliders:before {
  content: ""; }

.fa-share-alt:before {
  content: ""; }

.fa-share-alt-square:before {
  content: ""; }

.fa-bomb:before {
  content: ""; }

.fa-soccer-ball-o:before,
.fa-futbol-o:before {
  content: ""; }

.fa-tty:before {
  content: ""; }

.fa-binoculars:before {
  content: ""; }

.fa-plug:before {
  content: ""; }

.fa-slideshare:before {
  content: ""; }

.fa-twitch:before {
  content: ""; }

.fa-yelp:before {
  content: ""; }

.fa-newspaper-o:before {
  content: ""; }

.fa-wifi:before {
  content: ""; }

.fa-calculator:before {
  content: ""; }

.fa-paypal:before {
  content: ""; }

.fa-google-wallet:before {
  content: ""; }

.fa-cc-visa:before {
  content: ""; }

.fa-cc-mastercard:before {
  content: ""; }

.fa-cc-discover:before {
  content: ""; }

.fa-cc-amex:before {
  content: ""; }

.fa-cc-paypal:before {
  content: ""; }

.fa-cc-stripe:before {
  content: ""; }

.fa-bell-slash:before {
  content: ""; }

.fa-bell-slash-o:before {
  content: ""; }

.fa-trash:before {
  content: ""; }

.fa-copyright:before {
  content: ""; }

.fa-at:before {
  content: ""; }

.fa-eyedropper:before {
  content: ""; }

.fa-paint-brush:before {
  content: ""; }

.fa-birthday-cake:before {
  content: ""; }

.fa-area-chart:before {
  content: ""; }

.fa-pie-chart:before {
  content: ""; }

.fa-line-chart:before {
  content: ""; }

.fa-lastfm:before {
  content: ""; }

.fa-lastfm-square:before {
  content: ""; }

.fa-toggle-off:before {
  content: ""; }

.fa-toggle-on:before {
  content: ""; }

.fa-bicycle:before {
  content: ""; }

.fa-bus:before {
  content: ""; }

.fa-ioxhost:before {
  content: ""; }

.fa-angellist:before {
  content: ""; }

.fa-cc:before {
  content: ""; }

.fa-shekel:before,
.fa-sheqel:before,
.fa-ils:before {
  content: ""; }

.fa-meanpath:before {
  content: ""; }

.fa-buysellads:before {
  content: ""; }

.fa-connectdevelop:before {
  content: ""; }

.fa-dashcube:before {
  content: ""; }

.fa-forumbee:before {
  content: ""; }

.fa-leanpub:before {
  content: ""; }

.fa-sellsy:before {
  content: ""; }

.fa-shirtsinbulk:before {
  content: ""; }

.fa-simplybuilt:before {
  content: ""; }

.fa-skyatlas:before {
  content: ""; }

.fa-cart-plus:before {
  content: ""; }

.fa-cart-arrow-down:before {
  content: ""; }

.fa-diamond:before {
  content: ""; }

.fa-ship:before {
  content: ""; }

.fa-user-secret:before {
  content: ""; }

.fa-motorcycle:before {
  content: ""; }

.fa-street-view:before {
  content: ""; }

.fa-heartbeat:before {
  content: ""; }

.fa-venus:before {
  content: ""; }

.fa-mars:before {
  content: ""; }

.fa-mercury:before {
  content: ""; }

.fa-intersex:before,
.fa-transgender:before {
  content: ""; }

.fa-transgender-alt:before {
  content: ""; }

.fa-venus-double:before {
  content: ""; }

.fa-mars-double:before {
  content: ""; }

.fa-venus-mars:before {
  content: ""; }

.fa-mars-stroke:before {
  content: ""; }

.fa-mars-stroke-v:before {
  content: ""; }

.fa-mars-stroke-h:before {
  content: ""; }

.fa-neuter:before {
  content: ""; }

.fa-genderless:before {
  content: ""; }

.fa-facebook-official:before {
  content: ""; }

.fa-pinterest-p:before {
  content: ""; }

.fa-whatsapp:before {
  content: ""; }

.fa-server:before {
  content: ""; }

.fa-user-plus:before {
  content: ""; }

.fa-user-times:before {
  content: ""; }

.fa-hotel:before,
.fa-bed:before {
  content: ""; }

.fa-viacoin:before {
  content: ""; }

.fa-train:before {
  content: ""; }

.fa-subway:before {
  content: ""; }

.fa-medium:before {
  content: ""; }

.fa-yc:before,
.fa-y-combinator:before {
  content: ""; }

.fa-optin-monster:before {
  content: ""; }

.fa-opencart:before {
  content: ""; }

.fa-expeditedssl:before {
  content: ""; }

.fa-battery-4:before,
.fa-battery:before,
.fa-battery-full:before {
  content: ""; }

.fa-battery-3:before,
.fa-battery-three-quarters:before {
  content: ""; }

.fa-battery-2:before,
.fa-battery-half:before {
  content: ""; }

.fa-battery-1:before,
.fa-battery-quarter:before {
  content: ""; }

.fa-battery-0:before,
.fa-battery-empty:before {
  content: ""; }

.fa-mouse-pointer:before {
  content: ""; }

.fa-i-cursor:before {
  content: ""; }

.fa-object-group:before {
  content: ""; }

.fa-object-ungroup:before {
  content: ""; }

.fa-sticky-note:before {
  content: ""; }

.fa-sticky-note-o:before {
  content: ""; }

.fa-cc-jcb:before {
  content: ""; }

.fa-cc-diners-club:before {
  content: ""; }

.fa-clone:before {
  content: ""; }

.fa-balance-scale:before {
  content: ""; }

.fa-hourglass-o:before {
  content: ""; }

.fa-hourglass-1:before,
.fa-hourglass-start:before {
  content: ""; }

.fa-hourglass-2:before,
.fa-hourglass-half:before {
  content: ""; }

.fa-hourglass-3:before,
.fa-hourglass-end:before {
  content: ""; }

.fa-hourglass:before {
  content: ""; }

.fa-hand-grab-o:before,
.fa-hand-rock-o:before {
  content: ""; }

.fa-hand-stop-o:before,
.fa-hand-paper-o:before {
  content: ""; }

.fa-hand-scissors-o:before {
  content: ""; }

.fa-hand-lizard-o:before {
  content: ""; }

.fa-hand-spock-o:before {
  content: ""; }

.fa-hand-pointer-o:before {
  content: ""; }

.fa-hand-peace-o:before {
  content: ""; }

.fa-trademark:before {
  content: ""; }

.fa-registered:before {
  content: ""; }

.fa-creative-commons:before {
  content: ""; }

.fa-gg:before {
  content: ""; }

.fa-gg-circle:before {
  content: ""; }

.fa-tripadvisor:before {
  content: ""; }

.fa-odnoklassniki:before {
  content: ""; }

.fa-odnoklassniki-square:before {
  content: ""; }

.fa-get-pocket:before {
  content: ""; }

.fa-wikipedia-w:before {
  content: ""; }

.fa-safari:before {
  content: ""; }

.fa-chrome:before {
  content: ""; }

.fa-firefox:before {
  content: ""; }

.fa-opera:before {
  content: ""; }

.fa-internet-explorer:before {
  content: ""; }

.fa-tv:before,
.fa-television:before {
  content: ""; }

.fa-contao:before {
  content: ""; }

.fa-500px:before {
  content: ""; }

.fa-amazon:before {
  content: ""; }

.fa-calendar-plus-o:before {
  content: ""; }

.fa-calendar-minus-o:before {
  content: ""; }

.fa-calendar-times-o:before {
  content: ""; }

.fa-calendar-check-o:before {
  content: ""; }

.fa-industry:before {
  content: ""; }

.fa-map-pin:before {
  content: ""; }

.fa-map-signs:before {
  content: ""; }

.fa-map-o:before {
  content: ""; }

.fa-map:before {
  content: ""; }

.fa-commenting:before {
  content: ""; }

.fa-commenting-o:before {
  content: ""; }

.fa-houzz:before {
  content: ""; }

.fa-vimeo:before {
  content: ""; }

.fa-black-tie:before {
  content: ""; }

.fa-fonticons:before {
  content: ""; }

.fa-reddit-alien:before {
  content: ""; }

.fa-edge:before {
  content: ""; }

.fa-credit-card-alt:before {
  content: ""; }

.fa-codiepie:before {
  content: ""; }

.fa-modx:before {
  content: ""; }

.fa-fort-awesome:before {
  content: ""; }

.fa-usb:before {
  content: ""; }

.fa-product-hunt:before {
  content: ""; }

.fa-mixcloud:before {
  content: ""; }

.fa-scribd:before {
  content: ""; }

.fa-pause-circle:before {
  content: ""; }

.fa-pause-circle-o:before {
  content: ""; }

.fa-stop-circle:before {
  content: ""; }

.fa-stop-circle-o:before {
  content: ""; }

.fa-shopping-bag:before {
  content: ""; }

.fa-shopping-basket:before {
  content: ""; }

.fa-hashtag:before {
  content: ""; }

.fa-bluetooth:before {
  content: ""; }

.fa-bluetooth-b:before {
  content: ""; }

.fa-percent:before {
  content: ""; }

.fa-gitlab:before {
  content: ""; }

.fa-wpbeginner:before {
  content: ""; }

.fa-wpforms:before {
  content: ""; }

.fa-envira:before {
  content: ""; }

.fa-universal-access:before {
  content: ""; }

.fa-wheelchair-alt:before {
  content: ""; }

.fa-question-circle-o:before {
  content: ""; }

.fa-blind:before {
  content: ""; }

.fa-audio-description:before {
  content: ""; }

.fa-volume-control-phone:before {
  content: ""; }

.fa-braille:before {
  content: ""; }

.fa-assistive-listening-systems:before {
  content: ""; }

.fa-asl-interpreting:before,
.fa-american-sign-language-interpreting:before {
  content: ""; }

.fa-deafness:before,
.fa-hard-of-hearing:before,
.fa-deaf:before {
  content: ""; }

.fa-glide:before {
  content: ""; }

.fa-glide-g:before {
  content: ""; }

.fa-signing:before,
.fa-sign-language:before {
  content: ""; }

.fa-low-vision:before {
  content: ""; }

.fa-viadeo:before {
  content: ""; }

.fa-viadeo-square:before {
  content: ""; }

.fa-snapchat:before {
  content: ""; }

.fa-snapchat-ghost:before {
  content: ""; }

.fa-snapchat-square:before {
  content: ""; }

.fa-pied-piper:before {
  content: ""; }

.fa-first-order:before {
  content: ""; }

.fa-yoast:before {
  content: ""; }

.fa-themeisle:before {
  content: ""; }

.fa-google-plus-circle:before,
.fa-google-plus-official:before {
  content: ""; }

.fa-fa:before,
.fa-font-awesome:before {
  content: ""; }

.fa-handshake-o:before {
  content: ""; }

.fa-envelope-open:before {
  content: ""; }

.fa-envelope-open-o:before {
  content: ""; }

.fa-linode:before {
  content: ""; }

.fa-address-book:before {
  content: ""; }

.fa-address-book-o:before {
  content: ""; }

.fa-vcard:before,
.fa-address-card:before {
  content: ""; }

.fa-vcard-o:before,
.fa-address-card-o:before {
  content: ""; }

.fa-user-circle:before {
  content: ""; }

.fa-user-circle-o:before {
  content: ""; }

.fa-user-o:before {
  content: ""; }

.fa-id-badge:before {
  content: ""; }

.fa-drivers-license:before,
.fa-id-card:before {
  content: ""; }

.fa-drivers-license-o:before,
.fa-id-card-o:before {
  content: ""; }

.fa-quora:before {
  content: ""; }

.fa-free-code-camp:before {
  content: ""; }

.fa-telegram:before {
  content: ""; }

.fa-thermometer-4:before,
.fa-thermometer:before,
.fa-thermometer-full:before {
  content: ""; }

.fa-thermometer-3:before,
.fa-thermometer-three-quarters:before {
  content: ""; }

.fa-thermometer-2:before,
.fa-thermometer-half:before {
  content: ""; }

.fa-thermometer-1:before,
.fa-thermometer-quarter:before {
  content: ""; }

.fa-thermometer-0:before,
.fa-thermometer-empty:before {
  content: ""; }

.fa-shower:before {
  content: ""; }

.fa-bathtub:before,
.fa-s15:before,
.fa-bath:before {
  content: ""; }

.fa-podcast:before {
  content: ""; }

.fa-window-maximize:before {
  content: ""; }

.fa-window-minimize:before {
  content: ""; }

.fa-window-restore:before {
  content: ""; }

.fa-times-rectangle:before,
.fa-window-close:before {
  content: ""; }

.fa-times-rectangle-o:before,
.fa-window-close-o:before {
  content: ""; }

.fa-bandcamp:before {
  content: ""; }

.fa-grav:before {
  content: ""; }

.fa-etsy:before {
  content: ""; }

.fa-imdb:before {
  content: ""; }

.fa-ravelry:before {
  content: ""; }

.fa-eercast:before {
  content: ""; }

.fa-microchip:before {
  content: ""; }

.fa-snowflake-o:before {
  content: ""; }

.fa-superpowers:before {
  content: ""; }

.fa-wpexplorer:before {
  content: ""; }

.fa-meetup:before {
  content: ""; }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto; }

.breadcrumb {
  list-style-type: none;
  padding: 10px 10px 10px 0;
  width: 100%;
  display: inline-block; }
  .breadcrumb-item {
    float: left;
    color: #b9b9b9;
    font-family: "Museo Sans Cyrl 300", sans-serif;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 35%;
    white-space: nowrap; }
    .breadcrumb-item + .breadcrumb-item::before {
      content: '\\';
      display: inline-block;
      padding-right: .5rem;
      padding-left: .5rem;
      color: #b9b9b9; }
    .breadcrumb-item + .breadcrumb-item.categories::before {
      content: ',';
      padding-left: 0; }
    .breadcrumb-item + .breadcrumb-item.categories:nth-child(3)::before {
      content: '\\';
      padding-left: .5rem; }
    .breadcrumb-item:hover {
      color: #7bb249; }
      .breadcrumb-item:hover:last-child {
        color: #b9b9b9;
        cursor: default; }
  .breadcrumb a {
    color: inherit;
    font-family: inherit;
    font-size: inherit; }

.custom-form {
  background: #f1f1f1;
  border-radius: 0;
  color: #000;
  max-width: 663px;
  width: 100%;
  padding: 5vmin 4.5vmin 8vmin;
  margin: auto; }
  .custom-form.coments {
    display: none;
    max-width: 100%;
    float: left; }
    @media (max-width: 991px) {
      .custom-form.coments {
        max-width: 570px;
        float: none; } }
    .custom-form.coments .header {
      margin-bottom: 20px; }
    .custom-form.coments .cs-space {
      text-align: center; }
    .custom-form.coments .form-submit {
      max-width: 570px; }
  .custom-form .head-text {
    text-align: center; }
  .custom-form .cs-column-text {
    margin-bottom: 30px; }
  .custom-form textarea {
    display: block;
    min-height: 112px;
    border: 1px solid #000;
    width: 100%;
    resize: none;
    background: transparent;
    padding: 5px; }

.wpcf7-form-control-wrap {
  width: 100%; }

.input {
  position: relative;
  z-index: 1;
  display: inline-block;
  margin: 1em 0;
  width: 100%; }

.input__field {
  position: relative;
  display: block;
  float: right;
  padding: 0.8em;
  width: 60%;
  border: none;
  border-radius: 0;
  font-family: 'Museo Sans Cyrl 500',sans-serif;
  z-index: 100;
  font-size: 18px; }
  .input__field:focus {
    box-shadow: none !important;
    outline: none; }

.input__label-content--madoka {
  font-family: "Museo Sans Cyrl 100", sans-serif;
  font-size: 18px; }

.input__field--madoka:focus + .input__label--madoka .graphic--madoka, .input--filled .graphic--madoka {
  stroke-dashoffset: 0; }

.input__field--madoka:focus + .input__label--madoka .input__label-content--madoka, .input--filled .input__label-content--madoka {
  transform: translate3d(0, 25px, 0);
  font-size: 1.5em;
  padding: 1.6em 0; }

.input__field--madoka {
  width: 100%;
  background: transparent;
  color: #000; }

.wpcf7-form-control {
  margin-top: 5px;
  max-width: 100%; }

.wpcf7 label {
  font-weight: normal; }

.input__label {
  display: inline-block;
  float: right;
  padding: 0 1em;
  width: 40%;
  color: #555555;
  font-weight: bold;
  font-size: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none; }

.input__label--madoka {
  position: absolute;
  width: 100%;
  height: 100%;
  color: #000;
  text-align: left;
  cursor: text; }

svg:not(:root) {
  overflow: hidden; }

.graphic {
  position: absolute;
  top: 0;
  left: 0;
  fill: none; }

.graphic--madoka {
  transform: scale3d(1, -1, 1);
  transition: stroke-dashoffset 0.3s;
  pointer-events: none;
  stroke: #000;
  stroke-width: 2px;
  stroke-dasharray: 962;
  stroke-dashoffset: 558; }

.input__label-content {
  position: relative;
  display: block;
  padding: 1.2em 0;
  width: 100%; }

.input__label-content--madoka {
  transform-origin: 0% 50%;
  transition: 0.3s; }

.cs-space {
  clear: both;
  margin-top: 100px;
  border: none !important; }

.attachment-full {
  max-width: 955px;
  width: 100%; }

.wpb_row > .row {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: row; }
  @media (max-width: 1365px) {
    .wpb_row > .row {
      flex-direction: column;
      align-items: center; } }
  .wpb_row > .row .background-form {
    background-size: 90%;
    padding-bottom: 46.25%;
    background: url("/img/marketing/layer-5.jpg") no-repeat 160px 120px; }
    @media (max-width: 991px) {
      .wpb_row > .row .background-form {
        width: 100%;
        padding-bottom: 71.25%;
        background-size: cover; } }

@media (min-width: 992px) {
  .coments .input__label--madoka:before, .coments .input__label--madoka:after {
    content: "";
    display: block;
    height: 100%;
    width: 2px;
    background: #f1f1f1;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999; }
  .coments .input__label--madoka:after {
    left: auto;
    right: 0; } }

.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px; }

.container:before, .container:after {
  content: " ";
  display: table; }

.container:after {
  clear: both; }

@media (min-width: 768px) {
  .container {
    width: 750px; }
  .blog-page .container {
    width: 100%; } }

@media (min-width: 992px) {
  .container {
    width: 970px; } }

@media (min-width: 1200px) {
  .container {
    width: 1170px; } }

@media (min-width: 1400px) {
  .blog-page .container {
    width: 1360px; } }

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px; }

.container-fluid:before, .container-fluid:after {
  content: " ";
  display: table; }

.container-fluid:after {
  clear: both; }

.row {
  margin-left: -15px;
  margin-right: -15px; }

.row:before, .row:after {
  content: " ";
  display: table; }

.row:after {
  clear: both; }

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px; }

.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
  float: left; }

.col-xs-1 {
  width: 8.3333333333%; }

.col-xs-2 {
  width: 16.6666666667%; }

.col-xs-3 {
  width: 25%; }

.col-xs-4 {
  width: 33.3333333333%; }

.col-xs-5 {
  width: 41.6666666667%; }

.col-xs-6 {
  width: 50%; }

.col-xs-7 {
  width: 58.3333333333%; }

.col-xs-8 {
  width: 66.6666666667%; }

.col-xs-9 {
  width: 75%; }

.col-xs-10 {
  width: 83.3333333333%; }

.col-xs-11 {
  width: 91.6666666667%; }

.col-xs-12 {
  width: 100%; }

.col-xs-pull-0 {
  right: auto; }

.col-xs-pull-1 {
  right: 8.3333333333%; }

.col-xs-pull-2 {
  right: 16.6666666667%; }

.col-xs-pull-3 {
  right: 25%; }

.col-xs-pull-4 {
  right: 33.3333333333%; }

.col-xs-pull-5 {
  right: 41.6666666667%; }

.col-xs-pull-6 {
  right: 50%; }

.col-xs-pull-7 {
  right: 58.3333333333%; }

.col-xs-pull-8 {
  right: 66.6666666667%; }

.col-xs-pull-9 {
  right: 75%; }

.col-xs-pull-10 {
  right: 83.3333333333%; }

.col-xs-pull-11 {
  right: 91.6666666667%; }

.col-xs-pull-12 {
  right: 100%; }

.col-xs-push-0 {
  left: auto; }

.col-xs-push-1 {
  left: 8.3333333333%; }

.col-xs-push-2 {
  left: 16.6666666667%; }

.col-xs-push-3 {
  left: 25%; }

.col-xs-push-4 {
  left: 33.3333333333%; }

.col-xs-push-5 {
  left: 41.6666666667%; }

.col-xs-push-6 {
  left: 50%; }

.col-xs-push-7 {
  left: 58.3333333333%; }

.col-xs-push-8 {
  left: 66.6666666667%; }

.col-xs-push-9 {
  left: 75%; }

.col-xs-push-10 {
  left: 83.3333333333%; }

.col-xs-push-11 {
  left: 91.6666666667%; }

.col-xs-push-12 {
  left: 100%; }

.col-xs-offset-0 {
  margin-left: 0%; }

.col-xs-offset-1 {
  margin-left: 8.3333333333%; }

.col-xs-offset-2 {
  margin-left: 16.6666666667%; }

.col-xs-offset-3 {
  margin-left: 25%; }

.col-xs-offset-4 {
  margin-left: 33.3333333333%; }

.col-xs-offset-5 {
  margin-left: 41.6666666667%; }

.col-xs-offset-6 {
  margin-left: 50%; }

.col-xs-offset-7 {
  margin-left: 58.3333333333%; }

.col-xs-offset-8 {
  margin-left: 66.6666666667%; }

.col-xs-offset-9 {
  margin-left: 75%; }

.col-xs-offset-10 {
  margin-left: 83.3333333333%; }

.col-xs-offset-11 {
  margin-left: 91.6666666667%; }

.col-xs-offset-12 {
  margin-left: 100%; }

@media (min-width: 768px) {
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
    float: left; }
  .col-sm-1 {
    width: 8.3333333333%; }
  .col-sm-2 {
    width: 16.6666666667%; }
  .col-sm-3 {
    width: 25%; }
  .col-sm-4 {
    width: 33.3333333333%; }
  .col-sm-5 {
    width: 41.6666666667%; }
  .col-sm-6 {
    width: 50%; }
  .col-sm-7 {
    width: 58.3333333333%; }
  .col-sm-8 {
    width: 66.6666666667%; }
  .col-sm-9 {
    width: 75%; }
  .col-sm-10 {
    width: 83.3333333333%; }
  .col-sm-11 {
    width: 91.6666666667%; }
  .col-sm-12 {
    width: 100%; }
  .col-sm-pull-0 {
    right: auto; }
  .col-sm-pull-1 {
    right: 8.3333333333%; }
  .col-sm-pull-2 {
    right: 16.6666666667%; }
  .col-sm-pull-3 {
    right: 25%; }
  .col-sm-pull-4 {
    right: 33.3333333333%; }
  .col-sm-pull-5 {
    right: 41.6666666667%; }
  .col-sm-pull-6 {
    right: 50%; }
  .col-sm-pull-7 {
    right: 58.3333333333%; }
  .col-sm-pull-8 {
    right: 66.6666666667%; }
  .col-sm-pull-9 {
    right: 75%; }
  .col-sm-pull-10 {
    right: 83.3333333333%; }
  .col-sm-pull-11 {
    right: 91.6666666667%; }
  .col-sm-pull-12 {
    right: 100%; }
  .col-sm-push-0 {
    left: auto; }
  .col-sm-push-1 {
    left: 8.3333333333%; }
  .col-sm-push-2 {
    left: 16.6666666667%; }
  .col-sm-push-3 {
    left: 25%; }
  .col-sm-push-4 {
    left: 33.3333333333%; }
  .col-sm-push-5 {
    left: 41.6666666667%; }
  .col-sm-push-6 {
    left: 50%; }
  .col-sm-push-7 {
    left: 58.3333333333%; }
  .col-sm-push-8 {
    left: 66.6666666667%; }
  .col-sm-push-9 {
    left: 75%; }
  .col-sm-push-10 {
    left: 83.3333333333%; }
  .col-sm-push-11 {
    left: 91.6666666667%; }
  .col-sm-push-12 {
    left: 100%; }
  .col-sm-offset-0 {
    margin-left: 0%; }
  .col-sm-offset-1 {
    margin-left: 8.3333333333%; }
  .col-sm-offset-2 {
    margin-left: 16.6666666667%; }
  .col-sm-offset-3 {
    margin-left: 25%; }
  .col-sm-offset-4 {
    margin-left: 33.3333333333%; }
  .col-sm-offset-5 {
    margin-left: 41.6666666667%; }
  .col-sm-offset-6 {
    margin-left: 50%; }
  .col-sm-offset-7 {
    margin-left: 58.3333333333%; }
  .col-sm-offset-8 {
    margin-left: 66.6666666667%; }
  .col-sm-offset-9 {
    margin-left: 75%; }
  .col-sm-offset-10 {
    margin-left: 83.3333333333%; }
  .col-sm-offset-11 {
    margin-left: 91.6666666667%; }
  .col-sm-offset-12 {
    margin-left: 100%; } }

@media (min-width: 992px) {
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: left; }
  .col-md-1 {
    width: 8.3333333333%; }
  .col-md-2 {
    width: 16.6666666667%; }
  .col-md-3 {
    width: 25%; }
  .col-md-4 {
    width: 33.3333333333%; }
  .col-md-5 {
    width: 41.6666666667%; }
  .col-md-6 {
    width: 50%; }
  .col-md-7 {
    width: 58.3333333333%; }
  .col-md-8 {
    width: 66.6666666667%; }
  .col-md-9 {
    width: 75%; }
  .col-md-10 {
    width: 83.3333333333%; }
  .col-md-11 {
    width: 91.6666666667%; }
  .col-md-12 {
    width: 100%; }
  .col-md-pull-0 {
    right: auto; }
  .col-md-pull-1 {
    right: 8.3333333333%; }
  .col-md-pull-2 {
    right: 16.6666666667%; }
  .col-md-pull-3 {
    right: 25%; }
  .col-md-pull-4 {
    right: 33.3333333333%; }
  .col-md-pull-5 {
    right: 41.6666666667%; }
  .col-md-pull-6 {
    right: 50%; }
  .col-md-pull-7 {
    right: 58.3333333333%; }
  .col-md-pull-8 {
    right: 66.6666666667%; }
  .col-md-pull-9 {
    right: 75%; }
  .col-md-pull-10 {
    right: 83.3333333333%; }
  .col-md-pull-11 {
    right: 91.6666666667%; }
  .col-md-pull-12 {
    right: 100%; }
  .col-md-push-0 {
    left: auto; }
  .col-md-push-1 {
    left: 8.3333333333%; }
  .col-md-push-2 {
    left: 16.6666666667%; }
  .col-md-push-3 {
    left: 25%; }
  .col-md-push-4 {
    left: 33.3333333333%; }
  .col-md-push-5 {
    left: 41.6666666667%; }
  .col-md-push-6 {
    left: 50%; }
  .col-md-push-7 {
    left: 58.3333333333%; }
  .col-md-push-8 {
    left: 66.6666666667%; }
  .col-md-push-9 {
    left: 75%; }
  .col-md-push-10 {
    left: 83.3333333333%; }
  .col-md-push-11 {
    left: 91.6666666667%; }
  .col-md-push-12 {
    left: 100%; }
  .col-md-offset-0 {
    margin-left: 0%; }
  .col-md-offset-1 {
    margin-left: 8.3333333333%; }
  .col-md-offset-2 {
    margin-left: 16.6666666667%; }
  .col-md-offset-3 {
    margin-left: 25%; }
  .col-md-offset-4 {
    margin-left: 33.3333333333%; }
  .col-md-offset-5 {
    margin-left: 41.6666666667%; }
  .col-md-offset-6 {
    margin-left: 50%; }
  .col-md-offset-7 {
    margin-left: 58.3333333333%; }
  .col-md-offset-8 {
    margin-left: 66.6666666667%; }
  .col-md-offset-9 {
    margin-left: 75%; }
  .col-md-offset-10 {
    margin-left: 83.3333333333%; }
  .col-md-offset-11 {
    margin-left: 91.6666666667%; }
  .col-md-offset-12 {
    margin-left: 100%; } }

@media (min-width: 1200px) {
  .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
    float: left; }
  .col-lg-1 {
    width: 8.3333333333%; }
  .col-lg-2 {
    width: 16.6666666667%; }
  .col-lg-3 {
    width: 25%; }
  .col-lg-4 {
    width: 33.3333333333%; }
  .col-lg-5 {
    width: 41.6666666667%; }
  .col-lg-6 {
    width: 50%; }
  .col-lg-7 {
    width: 58.3333333333%; }
  .col-lg-8 {
    width: 66.6666666667%; }
  .col-lg-9 {
    width: 75%; }
  .col-lg-10 {
    width: 83.3333333333%; }
  .col-lg-11 {
    width: 91.6666666667%; }
  .col-lg-12 {
    width: 100%; }
  .col-lg-pull-0 {
    right: auto; }
  .col-lg-pull-1 {
    right: 8.3333333333%; }
  .col-lg-pull-2 {
    right: 16.6666666667%; }
  .col-lg-pull-3 {
    right: 25%; }
  .col-lg-pull-4 {
    right: 33.3333333333%; }
  .col-lg-pull-5 {
    right: 41.6666666667%; }
  .col-lg-pull-6 {
    right: 50%; }
  .col-lg-pull-7 {
    right: 58.3333333333%; }
  .col-lg-pull-8 {
    right: 66.6666666667%; }
  .col-lg-pull-9 {
    right: 75%; }
  .col-lg-pull-10 {
    right: 83.3333333333%; }
  .col-lg-pull-11 {
    right: 91.6666666667%; }
  .col-lg-pull-12 {
    right: 100%; }
  .col-lg-push-0 {
    left: auto; }
  .col-lg-push-1 {
    left: 8.3333333333%; }
  .col-lg-push-2 {
    left: 16.6666666667%; }
  .col-lg-push-3 {
    left: 25%; }
  .col-lg-push-4 {
    left: 33.3333333333%; }
  .col-lg-push-5 {
    left: 41.6666666667%; }
  .col-lg-push-6 {
    left: 50%; }
  .col-lg-push-7 {
    left: 58.3333333333%; }
  .col-lg-push-8 {
    left: 66.6666666667%; }
  .col-lg-push-9 {
    left: 75%; }
  .col-lg-push-10 {
    left: 83.3333333333%; }
  .col-lg-push-11 {
    left: 91.6666666667%; }
  .col-lg-push-12 {
    left: 100%; }
  .col-lg-offset-0 {
    margin-left: 0%; }
  .col-lg-offset-1 {
    margin-left: 8.3333333333%; }
  .col-lg-offset-2 {
    margin-left: 16.6666666667%; }
  .col-lg-offset-3 {
    margin-left: 25%; }
  .col-lg-offset-4 {
    margin-left: 33.3333333333%; }
  .col-lg-offset-5 {
    margin-left: 41.6666666667%; }
  .col-lg-offset-6 {
    margin-left: 50%; }
  .col-lg-offset-7 {
    margin-left: 58.3333333333%; }
  .col-lg-offset-8 {
    margin-left: 66.6666666667%; }
  .col-lg-offset-9 {
    margin-left: 75%; }
  .col-lg-offset-10 {
    margin-left: 83.3333333333%; }
  .col-lg-offset-11 {
    margin-left: 91.6666666667%; }
  .col-lg-offset-12 {
    margin-left: 100%; } }

@-ms-viewport {
  width: device-width; }

.visible-xs {
  display: none !important; }

.visible-sm {
  display: none !important; }

.visible-md {
  display: none !important; }

.visible-lg {
  display: none !important; }

.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
  display: none !important; }

@media (max-width: 767px) {
  .visible-xs {
    display: block !important; }
  table.visible-xs {
    display: table; }
  tr.visible-xs {
    display: table-row !important; }
  th.visible-xs,
  td.visible-xs {
    display: table-cell !important; } }

@media (max-width: 767px) {
  .visible-xs-block {
    display: block !important; } }

@media (max-width: 767px) {
  .visible-xs-inline {
    display: inline !important; } }

@media (max-width: 767px) {
  .visible-xs-inline-block {
    display: inline-block !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm {
    display: block !important; }
  table.visible-sm {
    display: table; }
  tr.visible-sm {
    display: table-row !important; }
  th.visible-sm,
  td.visible-sm {
    display: table-cell !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-block {
    display: block !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline {
    display: inline !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline-block {
    display: inline-block !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md {
    display: block !important; }
  table.visible-md {
    display: table; }
  tr.visible-md {
    display: table-row !important; }
  th.visible-md,
  td.visible-md {
    display: table-cell !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-block {
    display: block !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline {
    display: inline !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline-block {
    display: inline-block !important; } }

@media (min-width: 1200px) {
  .visible-lg {
    display: block !important; }
  table.visible-lg {
    display: table; }
  tr.visible-lg {
    display: table-row !important; }
  th.visible-lg,
  td.visible-lg {
    display: table-cell !important; } }

@media (min-width: 1200px) {
  .visible-lg-block {
    display: block !important; } }

@media (min-width: 1200px) {
  .visible-lg-inline {
    display: inline !important; } }

@media (min-width: 1200px) {
  .visible-lg-inline-block {
    display: inline-block !important; } }

@media (max-width: 767px) {
  .hidden-xs {
    display: none !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important; } }

@media (min-width: 1200px) {
  .hidden-lg {
    display: none !important; } }

.visible-print {
  display: none !important; }

@media print {
  .visible-print {
    display: block !important; }
  table.visible-print {
    display: table; }
  tr.visible-print {
    display: table-row !important; }
  th.visible-print,
  td.visible-print {
    display: table-cell !important; } }

.visible-print-block {
  display: none !important; }

@media print {
  .visible-print-block {
    display: block !important; } }

.visible-print-inline {
  display: none !important; }

@media print {
  .visible-print-inline {
    display: inline !important; } }

.visible-print-inline-block {
  display: none !important; }

@media print {
  .visible-print-inline-block {
    display: inline-block !important; } }

@media print {
  .hidden-print {
    display: none !important; } }

@media screen and (max-width: 767px) {
  .nav-portfolio .cs-prev {
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%; }
  .nav-portfolio .cs-next {
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%; }
  .nav-portfolio a {
    -webkit-transform: scale(0.6);
    transform: scale(0.6); } }

@media (max-width: 991px) {
  .col-md-9 + .cs-sidebar-clear:before, .col-md-9 + .cs-sidebar-clear:after,
  .cs-sidebar-clear + .col-md-9:before,
  .cs-sidebar-clear + .col-md-9:after {
    content: " ";
    display: table; }
  .col-md-9 + .cs-sidebar-clear:after,
  .cs-sidebar-clear + .col-md-9:after {
    clear: both; }
  .col-md-9 + .cs-sidebar-clear {
    margin-top: 40px; }
  .blog-masonry .isotope-item {
    width: 100%; }
  .blog-layout-medium .entry-header {
    text-align: left !important; }
  .blog-layout-medium .entry-meta > span {
    display: inline-block !important;
    margin-right: 10px !important;
    margin-bottom: 4px !important; } }

@media (max-width: 767px) {
  .cs-one-half,
  .cs-one-third,
  .cs-one-fourth,
  .cs-one-fifth,
  .cs-one-sixth,
  .cs-two-third,
  .cs-three-fourth,
  .cs-four-fifth,
  .cs-five-sixth {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px; } }

@media (min-width: 768px) and (max-width: 991px) {
  .cs-one-fourth,
  .cs-one-fifth,
  .cs-one-sixth {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px; } }

@media screen and (min-width: 0px) and (max-width: 991px) {
  .rsDefault .rsThumb {
    width: 59px;
    height: 44px; }
  .rsDefault .rsThumbsHor {
    height: 44px; }
  .rsDefault .rsThumbsVer {
    width: 59px; } }

html {
  font-family: 'Museo Sans Cyrl 500',sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body {
  margin: 0; }

.im {
  display: inline-block;
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

a {
  color: #7bb249; }

textarea {
  outline: none; }

a:active, a:hover, a:focus, button:focus {
  outline: none !important; }

img {
  border: 0;
  -ms-interpolation-mode: bicubic;
  max-width: 100%;
  height: auto;
  vertical-align: middle; }

#main {
  position: relative;
  overflow: hidden; }
  @media (max-width: 1024px) {
    #main {
      padding-top: 50px; } }

#content {
  overflow: visible;
  position: relative;
  max-width: 100%;
  min-height: 500px; }

.fancybox-close-small,
.fancybox-close-small:hover,
.fancybox-close-small:focus {
  outline: 0 !important; }

.fancybox-slide--iframe .fancybox-content {
  margin: 0;
  padding: 0 !important; }

.seo-text {
  padding-bottom: 40px;
  font-size: calc(1vmin + 10px);
  font-family: 'Museo Sans Cyrl 100', sans-serif; }

.clearfix::after {
  content: '';
  clear: both;
  display: block; }

.marquee {
  width: 100%; }

.marquee .inner {
  position: relative;
  width: 100%;
  display: flex; }
  .marquee .inner p {
    white-space: nowrap;
    text-transform: uppercase;
    font-size: 150px;
    font-family: 'Museo Sans Cyrl 900';
    color: #fff;
    -webkit-text-stroke: 1.5px #000; }
    @media (max-width: 768px) {
      .marquee .inner p {
        font-size: 75px; } }

.bg-1 {
  background: #ECEFF1;
  color: #7bb249; }

.button {
  padding: 1em 10px;
  border: none;
  background-color: #7bb249;
  color: inherit;
  vertical-align: middle;
  position: relative;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale; }

.button:focus {
  outline: none; }

.button > span {
  vertical-align: middle; }

.bg-1.button {
  color: #fff;
  border-color: #7bb249; }

.button--size-s {
  font-size: 14px; }
  @media (max-width: 768px) {
    .button--size-s {
      font-size: 12px; } }

.button--size-m {
  font-size: 16px; }
  @media (max-width: 768px) {
    .button--size-m {
      font-size: 14px; } }

.button--size-l {
  font-size: 18px; }
  @media (max-width: 768px) {
    .button--size-l {
      font-size: 16px; } }

.button--text-upper {
  letter-spacing: 2px;
  text-transform: uppercase; }

.button--text-thin {
  font-weight: 300; }

.button--text-medium {
  font-weight: 500; }

.button--text-thick {
  font-weight: 600; }

.button--round-s {
  border-radius: 5px; }

.button--round-m {
  border-radius: 15px; }

.button--round-l {
  border-radius: 40px; }

.button--border-thin {
  border: 1px solid; }

.button--antiman {
  background: none;
  border: none;
  height: 60px; }

.button--antiman.button--inverted, .button--antiman.button--inverted-alt {
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  -webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
  transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1); }

.button--antiman > span {
  padding-left: 0.35em; }

.button--antiman::before, .button--antiman::after {
  content: '';
  z-index: -1;
  border-radius: inherit;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s;
  -webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
  transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1); }

.button--antiman::before {
  border: 2px solid #7bb249;
  opacity: 0;
  -webkit-transform: scale3d(1.2, 1.2, 1);
  transform: scale3d(1.2, 1.2, 1); }

.button--antiman.button--border-thin::before {
  border-width: 1px; }

.button--antiman.button--border-medium::before {
  border-width: 2px; }

.button--antiman.button--border-thick::before {
  border-width: 3px; }

.button--antiman.button--inverted::before {
  border-color: #7bb249; }

.button--antiman.button--inverted-alt::before {
  border-color: #7bb249; }

.button--antiman::after {
  background: #fff; }

.button--antiman.button--inverted::after {
  background: #7bb249; }

.button--antiman.button--inverted-alt::after {
  background: #7bb249; }

.button--antiman.button--inverted:hover {
  color: #7bb249;
  text-decoration: none; }

.button--antiman:hover::before {
  opacity: 1;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1); }

.button--antiman:hover::after {
  opacity: 0;
  -webkit-transform: scale3d(0.8, 0.8, 1);
  transform: scale3d(0.8, 0.8, 1); }

.to-form {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase; }

.play-button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 92px;
  height: 38px;
  overflow: hidden;
  background: #fff;
  border: 1px solid #000; }
  .play-button:before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    left: -100%;
    opacity: 1;
    top: 0;
    box-sizing: border-box;
    text-align: center;
    transform-style: preserve-3d;
    transform-origin: 0 0;
    transition: all 0.6s cubic-bezier(0.05, 0.06, 0.05, 0.95);
    box-shadow: none;
    background-color: #000;
    z-index: 4; }
  .play-button:hover svg {
    position: relative;
    z-index: 5; }
    .play-button:hover svg path {
      stroke: #fff; }
      @media (max-width: 1024px) {
        .play-button:hover svg path {
          stroke: #000; } }
  .play-button:hover:before {
    left: 0; }
    @media (max-width: 1024px) {
      .play-button:hover:before {
        left: -100%; } }

.case-button {
  display: block;
  position: relative;
  overflow: hidden;
  width: 273px;
  height: 35px;
  border: 1px solid red; }
  .case-button span {
    display: block;
    position: relative;
    width: 100%;
    color: #fff;
    font-family: "Museo Sans Cyrl 100", sans-serif;
    font-size: 15px;
    font-weight: 100;
    line-height: 35px;
    text-align: center;
    text-transform: uppercase;
    transition: all 0.6s cubic-bezier(0.05, 0.06, 0.05, 0.95);
    z-index: 5;
    background: red; }
  .case-button:before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    left: -100%;
    opacity: 1;
    top: 0;
    box-sizing: border-box;
    text-align: center;
    transform-style: preserve-3d;
    transform-origin: 0 0;
    transition: all 0.6s cubic-bezier(0.05, 0.06, 0.05, 0.95);
    box-shadow: none;
    background-color: #000;
    z-index: 4; }
  .case-button:hover {
    text-decoration: none; }
    .case-button:hover span {
      color: #fff !important; }
    .case-button:hover:before {
      left: 0; }

button.button:disabled {
  opacity: .5;
  cursor: not-allowed; }

.read-more {
  color: #878787;
  text-decoration: none;
  font-family: "Museo Sans Cyrl 100",sans-serif;
  font-size: calc(1vmin + 15px);
  line-height: 30px;
  cursor: pointer;
  position: relative; }
  .read-more:hover::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    border-bottom: 1px solid #878787;
    width: 100%; }

.visible {
  display: inline; }
  .visible p:last-of-type {
    display: inline; }
  .visible.ellipsis p:last-of-type::after {
    content: '...';
    color: #000;
    font-family: "Museo Sans Cyrl 100", sans-serif;
    font-size: calc(1.2vmin + 10px);
    line-height: 30px; }

.invisible {
  display: none; }
  .invisible::before {
    content: ' ';
    white-space: pre; }

span.error {
  display: none; }

#cs-top {
  display: none;
  position: fixed;
  bottom: 30px;
  left: 25px;
  z-index: 99;
  width: 30px;
  height: 30px;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  color: #fff;
  background-color: #000;
  opacity: 0.3;
  border: 1px solid white;
  border-radius: 2px;
  transition: all 0.2s; }
  @media (max-width: 1400px) {
    #cs-top {
      left: 15px; } }

#cs-top.in {
  bottom: 15px; }

#cs-top:hover {
  opacity: 1; }

#cs-top:before {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  margin-top: -6px; }

#footer #cs-top {
  position: absolute;
  bottom: auto;
  left: auto;
  top: 48px;
  right: 15px;
  display: block;
  opacity: 1; }

.addComentWrap {
  text-align: center;
  padding-bottom: 60px; }
  .addComentWrap a {
    text-transform: uppercase; }

.my-container {
  max-width: calc(1630px + 10%);
  width: 100%;
  position: relative;
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px; }
  @media (min-width: 1201px) {
    .my-container {
      padding-left: 5%;
      padding-right: 5%; } }
  @media (min-width: 2000px) {
    .my-container {
      max-width: 2185px;
      padding: 0; } }
  .my-container::before {
    content: " ";
    display: table; }
  .my-container::after {
    clear: both;
    content: " ";
    display: table; }

#masthead {
  display: block;
  position: relative;
  width: 100%;
  background-color: #fff;
  transition: background 0.2s; }
  @media (max-width: 1024px) {
    #masthead {
      position: fixed;
      z-index: 199; } }
  #masthead .rd-inner {
    float: left;
    width: 100%;
    position: relative; }
  #masthead .megaSubmenu {
    position: absolute;
    width: 100%;
    z-index: 999;
    top: 98px;
    background: #fff;
    text-align: center;
    transition: background 0.2s;
    box-shadow: -1px 2px 5px 1px rgba(0, 0, 0, 0.39);
    border-top: 2px solid #7bb249; }
    #masthead .megaSubmenu-insert {
      padding: 25px;
      width: 25%;
      vertical-align: top;
      float: left; }
      #masthead .megaSubmenu-insert-img {
        padding-bottom: 15px;
        opacity: .5;
        transition: opacity 1s ease; }
      #masthead .megaSubmenu-insert-sub {
        text-align: left; }
        #masthead .megaSubmenu-insert-sub p {
          color: #000000;
          font-family: "Museo Sans Cyrl 700",sans-serif;
          font-size: calc(14px + .7vw);
          font-weight: 400;
          line-height: 30px; }
          #masthead .megaSubmenu-insert-sub p a {
            text-decoration: none;
            color: #000; }
        #masthead .megaSubmenu-insert-sub ul {
          list-style: none;
          padding: 0; }
          #masthead .megaSubmenu-insert-sub ul li a {
            text-decoration: none;
            color: #676666;
            font-family: "Museo Sans Cyrl 300",sans-serif;
            font-size: calc(8px + .7vw);
            font-weight: 400;
            line-height: 40px;
            transition: all 1s ease-in-out; }
            #masthead .megaSubmenu-insert-sub ul li a:hover {
              color: #000000;
              font-family: "Museo Sans Cyrl 700",sans-serif; }
              #masthead .megaSubmenu-insert-sub ul li a:hover::after {
                content: url("/img/megaMenu-arrow.png");
                width: 30px;
                height: 30px;
                margin-left: 10px; }
  #masthead.is-compact .megaSubmenu {
    top: 48px;
    background-color: rgba(255, 255, 255, 0.95); }
  #masthead #site-logo {
    float: left;
    max-width: 85%; }
    #masthead #site-logo img {
      max-height: 100%;
      padding-top: 5px;
      padding-bottom: 5px; }
    #masthead #site-logo .rd-logo2x {
      display: none; }
  #masthead .rd-sticky-item {
    height: 100px;
    line-height: 100px;
    transition: .5s; }
    @media (max-width: 1024px) {
      #masthead .rd-sticky-item {
        height: 40px;
        line-height: 40px; } }
  #masthead.is-sticky {
    position: fixed !important;
    z-index: 999;
    top: 0; }
  #masthead.is-compact {
    background-color: rgba(255, 255, 255, 0.95); }
    #masthead.is-compact .rd-sticky-item {
      height: 50px;
      line-height: 50px; }

#site-nav {
  float: right; }
  @media (max-width: 1024px) {
    #site-nav {
      display: none !important; } }
  #site-nav a {
    display: inline-block; }
  #site-nav ul {
    margin: 0;
    padding: 0;
    list-style-type: none; }
    #site-nav ul li {
      position: relative; }
      #site-nav ul li > .rd-link:hover {
        color: #7bb249; }
      #site-nav ul li ul {
        display: none;
        position: absolute;
        z-index: 300;
        top: 100%;
        width: 250px;
        background-color: #fff;
        -webkit-box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.07);
        box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.07); }
        #site-nav ul li ul li:first-child > .rd-link {
          border-top: none; }
        #site-nav ul li ul li .rd-link {
          display: block;
          position: relative;
          padding: 12px;
          color: #333;
          background-color: #fff;
          border-top: 1px solid #eeeeee;
          font-family: "Museo Sans Cyrl 300", sans-serif;
          font-size: 12px;
          line-height: 1em;
          font-style: normal;
          font-weight: 400;
          transition: all 0.3s; }
          #site-nav ul li ul li .rd-link:hover {
            color: #7bb249;
            background-color: #f8f8f8;
            text-decoration: none; }
  #site-nav .active > .rd-link {
    color: #7bb249; }
  #site-nav .rd-link {
    font-family: "Museo Sans Cyrl 300", sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400; }
  #site-nav > ul > li {
    float: left; }
    #site-nav > ul > li .rd-link {
      display: block;
      position: relative;
      padding-left: 10px;
      padding-right: 10px;
      color: #333; }
      #site-nav > ul > li .rd-link:hover {
        color: #7bb249;
        text-decoration: none; }
      #site-nav > ul > li .rd-link img {
        vertical-align: -2px; }
    #site-nav > ul > li > ul {
      display: none;
      position: absolute;
      z-index: 300;
      top: 100%;
      width: 120px;
      background-color: #fff;
      -webkit-box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.07);
      box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.07);
      border-top: 2px solid #7bb249;
      margin-top: -2px; }
  #site-nav .has-children > a:after {
    content: '\f107';
    font-family: 'FontAwesome';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 1;
    margin-left: 5px;
    opacity: 0.5; }

.link-depth-0:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 0;
  height: 2px;
  background-color: #7bb249;
  opacity: 0;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s; }

.link-depth-0:hover::before {
  opacity: 1; }

#site-header-shadow {
  position: absolute;
  bottom: -5px;
  height: 5px;
  width: 100%;
  overflow: hidden;
  z-index: 9;
  user-select: none; }
  #site-header-shadow:after {
    content: "";
    position: absolute;
    top: -5px;
    left: 0;
    width: 100%;
    height: 100%;
    -moz-border-radius: 100%/5px;
    -webkit-border-radius: 100% 5px;
    border-radius: 100%/5px;
    -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1); }

.overflowH {
  overflow: hidden; }

.btnTop {
  position: fixed;
  bottom: 25px;
  left: 25px;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 5px;
  transition: all 0s !important; }
  .btnTop a {
    color: white;
    padding: 5px;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
    display: inline-block;
    vertical-align: middle; }
    .btnTop a span {
      display: inline-block;
      width: 100%;
      text-align: center; }

.language {
  display: none; }
  @media (min-width: 1025px) {
    .language {
      display: block;
      list-style: none;
      margin: 0; }
      .language li {
        display: inline;
        position: relative;
        padding-right: 5px; }
        .language li:not(:last-of-type):after {
          position: absolute;
          width: 1px;
          height: 12px;
          top: 50%;
          margin-top: -8px;
          right: 3px;
          background: #848484;
          content: '';
          -webkit-transition: all .3s;
          -moz-transition: all .3s;
          -o-transition: all .3s;
          transition: all .3s;
          pointer-events: none; }
        .language li a {
          color: #848484;
          text-decoration: none;
          font-size: 13px;
          font-family: 'Museo Sans Cyrl 300',sans-serif; }
          .language li a:hover {
            color: #000; }
          .language li a.language-active {
            color: #000; } }
  .language.has-children {
    display: none; }
    @media (max-width: 1024px) {
      .language.has-children {
        display: block; } }

#navigation-mobile {
  display: none;
  background-color: #222;
  position: relative;
  z-index: 200; }
  @media (max-width: 1024px) {
    #navigation-mobile {
      position: fixed;
      top: 50px;
      left: 0;
      right: 0;
      overflow-y: auto;
      height: calc(100% - 50px); } }
  #navigation-mobile .container {
    padding-bottom: 20px; }
  #navigation-mobile .rd-dropdown-plus {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    background-color: #222; }
    #navigation-mobile .rd-dropdown-plus .fa {
      display: block;
      text-align: center;
      font-size: 13px;
      width: 50px;
      height: 44px;
      line-height: 51px;
      color: #555; }
  #navigation-mobile a {
    text-decoration: none; }
  #navigation-mobile ul {
    margin: 0;
    padding: 20px 0;
    list-style: none; }
    #navigation-mobile ul li {
      position: relative;
      border: 1px solid #333;
      border-bottom: none; }
      #navigation-mobile ul li:last-child {
        border-bottom: 1px solid #333; }
      #navigation-mobile ul li a {
        display: block;
        font-size: 13px;
        padding: 15px;
        color: #999; }
        #navigation-mobile ul li a img {
          vertical-align: -1px; }
      #navigation-mobile ul li.has-children > a {
        border-right: 1px solid #333;
        margin-right: 50px; }
        #navigation-mobile ul li.has-children > a img {
          vertical-align: -2px; }
      #navigation-mobile ul li.has-children > .rd-dropdown-plus {
        display: block; }
      #navigation-mobile ul li ul {
        position: relative;
        display: none;
        padding: 0;
        margin-left: 0; }
        #navigation-mobile ul li ul:before {
          content: "";
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 5px solid #333;
          position: absolute;
          top: 5px;
          left: 30px; }
        #navigation-mobile ul li ul li {
          border-left: none;
          border-right: none; }
          #navigation-mobile ul li ul li:first-child {
            border-top-width: 5px;
            border-top-color: #333; }
  #navigation-mobile .active > a {
    color: #fff; }

#rd-mobile-icon {
  display: none;
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -18px;
  padding: 10px;
  cursor: pointer;
  line-height: 0; }
  @media (max-width: 1024px) {
    #rd-mobile-icon {
      display: block; } }
  #rd-mobile-icon span {
    display: inline-block;
    width: 20px; }
  #rd-mobile-icon i {
    display: block;
    width: 100%;
    height: 3px;
    margin: 2px auto;
    background-color: #555;
    transition: all 0.1s; }
  #rd-mobile-icon strong {
    display: inline-block;
    position: relative;
    top: -4px;
    margin-right: 5px;
    font-size: 13px;
    line-height: 1em;
    text-transform: uppercase; }

#mobile-search {
  position: relative;
  margin-bottom: 20px; }
  #mobile-search input {
    font-size: 13px;
    height: 40px;
    padding: 22px 30px 22px 12px;
    color: #999;
    border: 1px solid #333;
    background-color: #151515;
    outline-color: #fff; }
  #mobile-search button {
    position: absolute;
    font-size: 11px;
    top: 14px;
    right: 5px;
    padding: 5px;
    margin: 0;
    border: none;
    outline: none;
    background: transparent; }

#top-bar {
  position: relative;
  color: #555;
  background-color: #f1f1f1;
  border-bottom: 1px solid #e8e8e8;
  line-height: 36px; }
  @media (max-width: 1024px) {
    #top-bar {
      display: none !important; } }

.rd-top-left {
  float: left;
  margin-bottom: -1px; }

.rd-top-right {
  float: right;
  margin-bottom: -1px; }

.rd-top-module {
  vertical-align: top;
  display: inline-block;
  position: relative;
  padding: 0 6px;
  margin-right: -1px;
  border-right: 1px solid #e8e8e8;
  font-size: 11px;
  line-height: 35px; }
  .rd-top-module:first-child {
    border-left: none;
    padding-left: 0; }
  .rd-top-module:last-child {
    border-right: none;
    padding-right: 0; }

.rd-top-module .rd-in {
  margin-right: 5px; }

.rd-top-module > a, .rd-top-module .rd-open-modal {
  color: #333;
  display: inline-block; }

#footer {
  position: relative;
  padding-top: 48px;
  font-size: 16px;
  line-height: 1.65em;
  color: #999;
  background-color: #222;
  z-index: 1; }
  #footer .widget-title h4 {
    color: #fff;
    font-family: "Museo Sans Cyrl 700", sans-serif;
    font-size: 21px;
    margin-bottom: 25px; }
  #footer a {
    color: #ccc;
    transition: all 0.2s; }
    #footer a:hover {
      color: #7bb249;
      text-decoration: none; }
  #footer label {
    display: block;
    width: 100%;
    margin-bottom: 10px; }
  #footer input {
    display: block;
    width: 100%;
    margin-top: 5px; }
  #footer .button {
    width: 100%;
    padding: 0;
    height: 40px;
    color: #fff;
    font-size: 16px;
    line-height: 40px;
    margin-top: 30px;
    font-weight: 600;
    border: 1px solid #7bb249; }
    @media (max-width: 767px) {
      #footer .button {
        overflow: hidden; } }
    #footer .button:before {
      border-width: 1px !important; }
  #footer ul {
    list-style-type: none;
    padding-left: 0; }
  #footer .menu_item > a {
    font-family: "Museo Sans Cyrl 700", sans-serif;
    font-size: 16px;
    color: #fff; }
    #footer .menu_item > a:hover {
      color: #7bb249;
      text-decoration: none; }
    #footer .menu_item > a.fSitemapLink {
      color: #7e7c7c;
      font-family: "Museo Sans Cyrl 100",sans-serif; }
      #footer .menu_item > a.fSitemapLink:hover {
        color: #7bb249; }
  #footer .menu_item ul {
    margin-bottom: 25px; }
  #footer .menu_item li a {
    color: #7e7c7c;
    font-family: "Museo Sans Cyrl 100", sans-serif; }
    #footer .menu_item li a:hover {
      color: #7bb249;
      text-decoration: none; }
  #footer form {
    max-width: 330px;
    font-size: 13px; }
    @media (max-width: 767px) {
      #footer form {
        min-width: 100%; } }
    #footer form input {
      padding-left: 10px;
      padding-right: 10px;
      height: 35px;
      outline: none; }
  #footer .contacts-list li {
    padding-left: 30px;
    position: relative;
    margin-bottom: 30px; }
  #footer .contacts-list i {
    position: absolute;
    left: 0;
    top: 3px; }

.telegram, .viber, .whatsapp {
  vertical-align: sub;
  opacity: 0.9; }
  .telegram:hover, .viber:hover, .whatsapp:hover {
    opacity: 1;
    background-color: transparent !important; }
  .telegram::after, .viber::after, .whatsapp::after {
    border-radius: 3px; }

.telegram {
  position: relative;
  top: 3px;
  margin-left: 5px; }
  .telegram:after {
    content: "";
    display: inline-block;
    width: 25px;
    height: 25px;
    background: url("/img/svg/telegram.svg") center no-repeat;
    background-size: contain; }

.viber {
  position: relative;
  top: 3px;
  margin-left: 5px; }
  .viber:after {
    content: "";
    display: inline-block;
    width: 25px;
    height: 25px;
    background: url("/img/svg/viber.svg") center no-repeat;
    background-size: contain; }

.whatsapp {
  position: relative;
  top: 3px;
  margin-left: 5px; }
  .whatsapp:after {
    content: "";
    display: inline-block;
    width: 25px;
    height: 25px;
    background: url("/img/svg/WhatsApp.svg") center no-repeat;
    background-size: contain; }

.footer-container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
  max-width: 1540px; }

#copyright {
  position: relative;
  font-size: 12px;
  line-height: 1.5em;
  padding: 10px 0;
  color: #555;
  background-color: #111; }

.route_widget {
  width: 100%;
  margin-bottom: 48px;
  word-wrap: break-word; }

.module-social {
  vertical-align: top;
  display: inline-block;
  position: relative;
  font-size: 11px;
  line-height: 35px;
  margin: 0;
  padding: 0;
  border: none;
  z-index: 1; }
  .module-social a {
    display: inline-block;
    font-size: 20px;
    text-align: center;
    vertical-align: top;
    line-height: 36px;
    width: 36px;
    margin: 0; }
    .module-social a:hover {
      color: #fff !important;
      background-color: #7bb249;
      -webkit-box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.1);
      box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.1); }

.widget {
  width: 100%;
  margin-bottom: 48px;
  word-wrap: break-word; }

.modal {
  display: none;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  outline: 0; }

.modal.fade .modal-dialog {
  transform: translate(0, -25%);
  transition: transform 0.3s ease-out; }

.modal.in .modal-dialog {
  transform: translate(0, 0); }

.modal-open .modal {
  overflow-x: auto;
  overflow-y: auto; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px; }

.modal-content {
  position: relative;
  background-color: #fff;
  border: 1px solid #999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  background-clip: padding-box;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #000;
  z-index: 1049; }

.modal-backdrop.fade {
  opacity: 0; }

.modal-backdrop.in {
  opacity: 0.5; }

.modal-header {
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
  min-height: 16.428571429px; }

.modal-header .fa {
  position: absolute;
  right: 15px;
  padding: 5px;
  cursor: pointer; }

.modal-title {
  margin: 0;
  line-height: 1.428571429; }

.modal-body {
  position: relative;
  padding: 15px; }

.modal-center .modal-dialog-center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%; }

.modal-center .modal-body {
  overflow-y: auto; }

.modal-center .modal-footer {
  margin-top: 0; }

@media (min-width: 768px) {
  .modal-dialog {
    width: 600px;
    margin: 50px auto; }
  .modal-content {
    -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5); }
  .modal-sm {
    width: 300px; } }

@media (min-width: 992px) {
  .modal-lg {
    width: 900px; } }

@media (max-width: 768px) {
  .modal-center .modal-dialog-center {
    width: 100%; } }

.cs-top-module {
  vertical-align: top;
  display: inline-block;
  position: relative;
  padding: 0 6px;
  margin-right: -1px;
  border-left: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
  font-size: 11px;
  line-height: 35px; }

.cs-top-module:first-child {
  border-left: none;
  padding-left: 0; }

.cs-top-module:last-child {
  border-right: none;
  padding-right: 0; }

.cs-top-module > a, .cs-top-module .cs-open-modal {
  color: #333;
  display: inline-block; }

.cs-top-module > a:hover, .cs-top-module .cs-open-modal:hover {
  color: #428bca;
  text-decoration: none; }

.cs-top-module .cs-in {
  margin-right: 5px; }

.cs-top-module .cs-down {
  margin-left: 2px; }

.cs-module-social {
  margin: 0;
  padding: 0;
  border: none;
  z-index: 1; }

.cs-module-social a {
  display: inline-block;
  font-size: 12px;
  text-align: center;
  vertical-align: top;
  line-height: 36px;
  width: 36px; }

.cs-module-social a:hover {
  color: #fff !important;
  background-color: #7bb249;
  -webkit-box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.1); }

.cs-module-social .cs-in {
  margin: 0; }

.thanks {
  position: relative; }

.thanks img {
  max-width: 100%; }
  @media (max-width: 767px) {
    .thanks img {
      max-width: 50vw;
      display: table;
      margin: 0 auto; } }

.thanks .right span {
  text-transform: uppercase;
  font-size: 48px;
  line-height: 1;
  margin-top: 240px;
  display: inline-block;
  color: #333;
  margin-bottom: 10px;
  font-weight: 700; }

.thanks .right h3 {
  text-transform: uppercase;
  font-size: 30px;
  line-height: 1; }

.thanks button.close {
  position: absolute;
  top: 15px;
  right: 15px;
  height: 22px;
  width: 22px;
  border: none;
  z-index: 1000;
  background: transparent url("/img/close.png") center center no-repeat; }

@media (max-width: 992px) {
  .thanks .left {
    padding-top: 50px; }
  .thanks .right {
    padding-bottom: 20px; }
  .thanks .right span {
    text-align: center;
    margin-top: 20px;
    font-size: 30px;
    width: 100%; } }
  @media (max-width: 992px) and (max-width: 767px) {
    .thanks .right span {
      font-size: 26px; } }

@media (max-width: 992px) {
  .thanks .right h3 {
    text-align: center;
    font-size: 25px; } }
  @media (max-width: 992px) and (max-width: 767px) {
    .thanks .right h3 {
      font-size: 22px; } }

@media (max-width: 992px) {
  .thanks .right .cs-module-social a {
    font-size: 25px; }
  .thanks button.close {
    width: 30px;
    height: 30px; } }

@media (max-width: 540px) {
  .cs-column-text h1 {
    font-size: 38px !important; } }

.wpcf7 span.error {
  position: absolute;
  font-size: 16px;
  color: red;
  top: 15px;
  left: 0;
  right: 0;
  text-align: center; }
  @media (max-width: 570px) {
    .wpcf7 span.error {
      font-size: 12px; } }

.modal .wpcf7 span.error {
  top: 2px; }

.widget label {
  position: relative; }

.widget span.error {
  position: absolute;
  top: 33px;
  color: red;
  width: 100%;
  text-align: center; }

.feedback-block .ui-helper-hidden {
  display: none; }

.feedback-block .ui-helper-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.feedback-block .ui-helper-reset {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  line-height: 1.3;
  text-decoration: none;
  font-size: 100%;
  list-style: none; }

.feedback-block .ui-helper-clearfix:before, .feedback-block .ui-helper-clearfix:after {
  content: "";
  display: table;
  border-collapse: collapse; }

.feedback-block .ui-helper-clearfix:after {
  clear: both; }

.feedback-block .ui-helper-zfix {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  opacity: 0;
  filter: Alpha(Opacity=0); }

.feedback-block .ui-front {
  z-index: 100; }

.feedback-block .ui-state-disabled {
  cursor: default !important;
  pointer-events: none; }

.feedback-block .ui-icon {
  display: inline-block;
  vertical-align: middle;
  margin-top: -.25em;
  position: relative;
  text-indent: -99999px;
  overflow: hidden;
  background-repeat: no-repeat; }

.feedback-block .ui-widget-icon-block {
  left: 50%;
  margin-left: -8px;
  display: block; }

.feedback-block .ui-widget-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.feedback-block .ui-datepicker {
  width: 17em;
  padding: .2em .2em 0;
  display: none; }

.feedback-block .ui-datepicker .ui-datepicker-header {
  position: relative;
  padding: .2em 0; }

.feedback-block .ui-datepicker .ui-datepicker-prev, .feedback-block .ui-datepicker .ui-datepicker-next {
  position: absolute;
  top: 2px;
  width: 1.8em;
  height: 1.8em; }

.feedback-block .ui-datepicker .ui-datepicker-prev-hover, .feedback-block .ui-datepicker .ui-datepicker-next-hover {
  top: 1px; }

.feedback-block .ui-datepicker .ui-datepicker-prev {
  left: 2px; }

.feedback-block .ui-datepicker .ui-datepicker-next {
  right: 2px; }

.feedback-block .ui-datepicker .ui-datepicker-prev-hover {
  left: 1px; }

.feedback-block .ui-datepicker .ui-datepicker-next-hover {
  right: 1px; }

.feedback-block .ui-datepicker .ui-datepicker-prev span, .feedback-block .ui-datepicker .ui-datepicker-next span {
  display: block;
  position: absolute;
  left: 50%;
  margin-left: -8px;
  top: 50%;
  margin-top: -8px; }

.feedback-block .ui-datepicker .ui-datepicker-title {
  margin: 0 2.3em;
  line-height: 1.8em;
  text-align: center; }

.feedback-block .ui-datepicker .ui-datepicker-title select {
  font-size: 1em;
  margin: 1px 0; }

.feedback-block .ui-datepicker select.ui-datepicker-month, .feedback-block .ui-datepicker select.ui-datepicker-year {
  width: 45%; }

.feedback-block .ui-datepicker table {
  width: 100%;
  font-size: .9em;
  border-collapse: collapse;
  margin: 0 0 .4em; }

.feedback-block .ui-datepicker th {
  padding: .7em .3em;
  text-align: center;
  font-weight: bold;
  border: 0; }

.feedback-block .ui-datepicker td {
  border: 0;
  padding: 1px; }

.feedback-block .ui-datepicker td span, .feedback-block .ui-datepicker td a {
  display: block;
  padding: .2em;
  text-align: right;
  text-decoration: none; }

.feedback-block .ui-datepicker .ui-datepicker-buttonpane {
  background-image: none;
  margin: .7em 0 0 0;
  padding: 0 .2em;
  border-left: 0;
  border-right: 0;
  border-bottom: 0; }

.feedback-block .ui-datepicker .ui-datepicker-buttonpane button {
  float: right;
  margin: .5em .2em .4em;
  cursor: pointer;
  padding: .2em .6em .3em .6em;
  width: auto;
  overflow: visible; }

.feedback-block .ui-datepicker .ui-datepicker-buttonpane button.ui-datepicker-current {
  float: left; }

.feedback-block .ui-datepicker.ui-datepicker-multi {
  width: auto; }

.feedback-block .ui-datepicker-multi .ui-datepicker-group {
  float: left; }

.feedback-block .ui-datepicker-multi .ui-datepicker-group table {
  width: 95%;
  margin: 0 auto .4em; }

.feedback-block .ui-datepicker-multi-2 .ui-datepicker-group {
  width: 50%; }

.feedback-block .ui-datepicker-multi-3 .ui-datepicker-group {
  width: 33.3%; }

.feedback-block .ui-datepicker-multi-4 .ui-datepicker-group {
  width: 25%; }

.feedback-block .ui-datepicker-multi .ui-datepicker-group-last .ui-datepicker-header, .feedback-block .ui-datepicker-multi .ui-datepicker-group-middle .ui-datepicker-header {
  border-left-width: 0; }

.feedback-block .ui-datepicker-multi .ui-datepicker-buttonpane {
  clear: left; }

.feedback-block .ui-datepicker-row-break {
  clear: both;
  width: 100%;
  font-size: 0; }

.feedback-block .ui-datepicker-rtl {
  direction: rtl; }

.feedback-block .ui-datepicker-rtl .ui-datepicker-prev {
  right: 2px;
  left: auto; }

.feedback-block .ui-datepicker-rtl .ui-datepicker-next {
  left: 2px;
  right: auto; }

.feedback-block .ui-datepicker-rtl .ui-datepicker-prev:hover {
  right: 1px;
  left: auto; }

.feedback-block .ui-datepicker-rtl .ui-datepicker-next:hover {
  left: 1px;
  right: auto; }

.feedback-block .ui-datepicker-rtl .ui-datepicker-buttonpane {
  clear: right; }

.feedback-block .ui-datepicker-rtl .ui-datepicker-buttonpane button {
  float: left; }

.feedback-block .ui-datepicker-rtl .ui-datepicker-buttonpane button.ui-datepicker-current, .feedback-block .ui-datepicker-rtl .ui-datepicker-group {
  float: right; }

.feedback-block .ui-datepicker-rtl .ui-datepicker-group-last .ui-datepicker-header, .feedback-block .ui-datepicker-rtl .ui-datepicker-group-middle .ui-datepicker-header {
  border-right-width: 0;
  border-left-width: 1px; }

.feedback-block .ui-datepicker .ui-icon {
  display: block;
  text-indent: -99999px;
  overflow: hidden;
  background-repeat: no-repeat;
  left: .5em;
  top: .3em; }

.feedback-block .ui-widget {
  font-family: Arial,Helvetica,sans-serif;
  font-size: 1em; }

.feedback-block .ui-widget .ui-widget {
  font-size: 1em; }

.feedback-block .ui-widget input, .feedback-block .ui-widget select, .feedback-block .ui-widget textarea, .feedback-block .ui-widget button {
  font-family: Arial,Helvetica,sans-serif;
  font-size: 1em; }

.feedback-block .ui-widget.ui-widget-content {
  border: 1px solid #c5c5c5; }

.feedback-block .ui-widget-content {
  border: 1px solid #ddd;
  background: #fff;
  color: #333; }

.feedback-block .ui-widget-content a {
  color: #333; }

.feedback-block .ui-widget-header {
  border: 1px solid #ddd;
  background: #e9e9e9;
  color: #333;
  font-weight: bold; }

.feedback-block .ui-widget-header a {
  color: #333; }

.feedback-block .ui-state-default, .feedback-block .ui-widget-content .ui-state-default, .feedback-block .ui-widget-header .ui-state-default, .feedback-block .ui-button, .feedback-block html .ui-button.ui-state-disabled:hover, .feedback-block html .ui-button.ui-state-disabled:active {
  border: 1px solid #c5c5c5;
  background: #f6f6f6;
  font-weight: normal;
  color: #454545; }

.feedback-block .ui-state-default a, .feedback-block .ui-state-default a:link, .feedback-block .ui-state-default a:visited, .feedback-block a.ui-button, .feedback-block a:link.ui-button, .feedback-block a:visited.ui-button, .feedback-block .ui-button {
  color: #454545;
  text-decoration: none; }

.feedback-block .ui-state-hover, .feedback-block .ui-widget-content .ui-state-hover, .feedback-block .ui-widget-header .ui-state-hover, .feedback-block .ui-state-focus, .feedback-block .ui-widget-content .ui-state-focus, .feedback-block .ui-widget-header .ui-state-focus, .feedback-block .ui-button:hover, .feedback-block .ui-button:focus {
  border: 1px solid #ccc;
  background: #ededed;
  font-weight: normal;
  color: #2b2b2b; }

.feedback-block .ui-state-hover a, .feedback-block .ui-state-hover a:hover, .feedback-block .ui-state-hover a:link, .feedback-block .ui-state-hover a:visited, .feedback-block .ui-state-focus a, .feedback-block .ui-state-focus a:hover, .feedback-block .ui-state-focus a:link, .feedback-block .ui-state-focus a:visited, .feedback-block a.ui-button:hover, .feedback-block a.ui-button:focus {
  color: #2b2b2b;
  text-decoration: none; }

.feedback-block .ui-visual-focus {
  box-shadow: 0 0 3px 1px #5e9ed6; }

.feedback-block .ui-state-active, .feedback-block .ui-widget-content .ui-state-active, .feedback-block .ui-widget-header .ui-state-active, .feedback-block a.ui-button:active, .feedback-block .ui-button:active, .feedback-block .ui-button.ui-state-active:hover {
  border: 1px solid #003eff;
  background: #007fff;
  font-weight: normal;
  color: #fff; }

.feedback-block .ui-icon-background, .feedback-block .ui-state-active .ui-icon-background {
  border: #003eff;
  background-color: #fff; }

.feedback-block .ui-state-active a, .feedback-block .ui-state-active a:link, .feedback-block .ui-state-active a:visited {
  color: #fff;
  text-decoration: none; }

.feedback-block .ui-state-highlight, .feedback-block .ui-widget-content .ui-state-highlight, .feedback-block .ui-widget-header .ui-state-highlight {
  border: 1px solid #dad55e;
  background: #fffa90;
  color: #777620; }

.feedback-block .ui-state-checked {
  border: 1px solid #dad55e;
  background: #fffa90; }

.feedback-block .ui-state-highlight a, .feedback-block .ui-widget-content .ui-state-highlight a, .feedback-block .ui-widget-header .ui-state-highlight a {
  color: #777620; }

.feedback-block .ui-state-error, .feedback-block .ui-widget-content .ui-state-error, .feedback-block .ui-widget-header .ui-state-error {
  border: 1px solid #f1a899;
  background: #fddfdf;
  color: #5f3f3f; }

.feedback-block .ui-state-error a, .feedback-block .ui-widget-content .ui-state-error a, .feedback-block .ui-widget-header .ui-state-error a {
  color: #5f3f3f; }

.feedback-block .ui-state-error-text, .feedback-block .ui-widget-content .ui-state-error-text, .feedback-block .ui-widget-header .ui-state-error-text {
  color: #5f3f3f; }

.feedback-block .ui-priority-primary, .feedback-block .ui-widget-content .ui-priority-primary, .feedback-block .ui-widget-header .ui-priority-primary {
  font-weight: bold; }

.feedback-block .ui-priority-secondary, .feedback-block .ui-widget-content .ui-priority-secondary, .feedback-block .ui-widget-header .ui-priority-secondary {
  opacity: .7;
  filter: Alpha(Opacity=70);
  font-weight: normal; }

.feedback-block .ui-state-disabled, .feedback-block .ui-widget-content .ui-state-disabled, .feedback-block .ui-widget-header .ui-state-disabled {
  opacity: .35;
  filter: Alpha(Opacity=35);
  background-image: none; }

.feedback-block .ui-state-disabled .ui-icon {
  filter: Alpha(Opacity=35); }

.feedback-block .ui-corner-all, .feedback-block .ui-corner-top, .feedback-block .ui-corner-left, .feedback-block .ui-corner-tl {
  border-top-left-radius: 3px; }

.feedback-block .ui-corner-all, .feedback-block .ui-corner-top, .feedback-block .ui-corner-right, .feedback-block .ui-corner-tr {
  border-top-right-radius: 3px; }

.feedback-block .ui-corner-all, .feedback-block .ui-corner-bottom, .feedback-block .ui-corner-left, .feedback-block .ui-corner-bl {
  border-bottom-left-radius: 3px; }

.feedback-block .ui-corner-all, .feedback-block .ui-corner-bottom, .feedback-block .ui-corner-right, .feedback-block .ui-corner-br {
  border-bottom-right-radius: 3px; }

.feedback-block .ui-widget-overlay {
  background: #aaa;
  opacity: .3;
  filter: Alpha(Opacity=30); }

.feedback-block .ui-widget-shadow {
  -webkit-box-shadow: 0 0 5px #666;
  box-shadow: 0 0 5px #666; }

@media (max-width: 767px) {
  .feedback-block img {
    width: 85%; } }

.c-fab_button {
  display: inline-block;
  overflow: visible;
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0;
  background: 0 0;
  color: inherit;
  vertical-align: middle;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  /*font: inherit;*/
  line-height: normal;
  cursor: pointer;
  user-select: none; }

.o-container {
  position: relative;
  margin: 0 auto; }

@media (max-width: 699px) {
  .o-container {
    padding-right: 25px;
    padding-left: 25px; } }

@media (min-width: 700px) and (max-width: 1199px) {
  .o-container {
    padding-right: 3.75rem;
    padding-left: 3.75rem; } }

@media (min-width: 1200px) and (max-width: 1599px) {
  .o-container {
    padding-right: 11.25rem;
    padding-left: 11.25rem; } }

@media (min-width: 1600px) {
  .o-container {
    padding-right: 15rem;
    padding-left: 15rem; } }

.o-grid {
  letter-spacing: normal;
  font-size: 0;
  margin-left: 0; }

.o-grid a {
  cursor: pointer; }

.o-grid.-small-margin {
  margin-left: -1.875rem; }

.o-half, .o-third, .o-whole {
  display: inline-block;
  width: 100%;
  vertical-align: top;
  font-size: 18px;
  padding-left: 0; }

.o-grid.-small-margin > .o-half, .o-grid.-small-margin > .o-third, .o-grid.-small-margin > .o-whole {
  padding-left: 1.875rem; }

@media (min-width: 1000px) {
  .o-half {
    width: 50%; } }

@media (min-width: 700px) {
  .o-third {
    width: 33.3333333333%; } }

.o-required-input-indicator {
  color: inherit;
  font-size: inherit;
  font-weight: 700;
  background: 0 0; }

.o-label {
  display: block;
  margin-bottom: .625em; }

@media (max-width: 699px) {
  .o-label {
    font-size: 16px;
    margin-bottom: .625rem; } }

.o-file-wrap, .o-input, .o-select, .o-textarea, .selectric {
  padding: .625rem;
  background-color: rgba(0, 0, 0, 0.2);
  color: currentColor;
  height: 65px;
  padding: 0 1.25rem;
  -webkit-transition: background-color .3s;
  transition: background-color .3s;
  outline: none;
  border: none;
  width: 100%; }

@media (max-width: 699px) {
  .o-file-wrap, .o-input, .o-select, .o-textarea, .selectric {
    font-size: 16px; } }

.o-file-wrap:focus, .o-input:focus, .o-select:focus, .o-textarea:focus, .selectric:focus {
  background-color: rgba(0, 0, 0, 0.15); }

.o-file-wrap::-webkit-input-placeholder, .o-input::-webkit-input-placeholder, .o-select::-webkit-input-placeholder, .o-textarea::-webkit-input-placeholder, .selectric::-webkit-input-placeholder {
  color: currentColor; }

.o-file-wrap:-ms-input-placeholder, .o-input:-ms-input-placeholder, .o-select:-ms-input-placeholder, .o-textarea:-ms-input-placeholder, .selectric:-ms-input-placeholder {
  color: currentColor; }

.o-file-wrap::-webkit-input-placeholder, .o-input::-webkit-input-placeholder, .o-select::-webkit-input-placeholder, .o-textarea::-webkit-input-placeholder, .selectric::-webkit-input-placeholder {
  color: currentColor; }

.o-file-wrap:-ms-input-placeholder, .o-input:-ms-input-placeholder, .o-select:-ms-input-placeholder, .o-textarea:-ms-input-placeholder, .selectric:-ms-input-placeholder {
  color: currentColor; }

@media (max-width: 699px) {
  .selectric {
    font-size: 15px; } }

.o-input_icon {
  position: absolute;
  left: .9375rem;
  top: 50%;
  transform: translateY(-50%);
  fill: currentColor;
  width: 20px;
  height: 15px; }

.o-select, .selectric {
  position: relative;
  z-index: 10;
  padding-right: 3.125rem;
  cursor: pointer;
  -webkit-appearance: none; }

.o-select option {
  background-color: rgba(0, 0, 0, 0.1); }

.o-select-wrap {
  position: relative; }

.o-select-wrap::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  width: 3.125rem;
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20version%3D%221.1%22%20x%3D%220%22%20y%3D%220%22%20width%3D%2213%22%20height%3D%2211.3%22%20viewBox%3D%220%200%2013%2011.3%22%20enable-background%3D%22new%200%200%2013%2011.3%22%20xml%3Aspace%3D%22preserve%22%3E%3Cpolygon%20fill%3D%22%230%22%20points%3D%226.5%2011.3%203.3%205.6%200%200%206.5%200%2013%200%209.8%205.6%20%22%2F%3E%3C%2Fsvg%3E);
  background-position: center;
  background-size: .625rem;
  background-repeat: no-repeat;
  content: "";
  pointer-events: none;
  opacity: .2; }

.o-select-wrap.-hidden {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1); }

.o-select-wrap.-hidden:first-child {
  position: relative; }

.o-select-wrap.-hidden.is-current {
  opacity: 1;
  transition-delay: .1s; }

.o-select-wraps {
  position: relative; }

.o-textarea {
  background-color: rgba(0, 0, 0, 0.2);
  min-height: 150px;
  z-index: 5;
  position: relative;
  color: #fff;
  padding: .9375rem 1.25rem;
  resize: none; }

.o-file-wrap {
  position: relative;
  cursor: pointer; }

.o-file {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer; }

.o-button {
  display: inline-block;
  position: relative;
  text-align: center;
  font-size: 1.25rem;
  font-weight: 500;
  /*padding: 1.25rem 1.5625rem;*/
  transition: opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1), transform 0.3s cubic-bezier(0.4, 0, 0.2, 1); }

.o-button.-large {
  height: 5.625rem;
  padding: 1.875rem 2.1875rem; }

.o-button::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  transition: opacity .3s; }

.o-button:active::before, .o-button:focus::before, .o-button:hover::before {
  opacity: .1; }

.o-button_text {
  vertical-align: middle; }

.selectric-wrapper {
  position: relative;
  cursor: pointer; }

.selectric-responsive {
  width: 100%; }

.selectric {
  position: relative;
  font-size: 0;
  color: currentColor; }

.selectric:before {
  display: inline-block;
  height: 100%;
  content: "";
  vertical-align: middle; }

.selectric > * {
  font-size: 1rem;
  display: inline-block;
  vertical-align: middle; }

.selectric .label {
  user-select: none; }

.selectric .button {
  display: none; }

.selectric-open {
  z-index: 9999; }

.selectric-open .selectric {
  background-color: rgba(0, 0, 0, 0.15); }

.selectric-open .selectric-items {
  transition-delay: 0s;
  visibility: visible;
  opacity: 1; }

.selectric-disabled {
  opacity: .5;
  cursor: default;
  user-select: none; }

.selectric-hide-select {
  position: relative;
  overflow: hidden;
  width: 0;
  height: 0; }

.selectric-hide-select select {
  position: absolute;
  left: -100%;
  display: none; }

.selectric-hide-select.selectric-is-native {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10; }

.selectric-hide-select.selectric-is-native select {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  border: none;
  z-index: 1;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  opacity: 0; }

.selectric-input {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  outline: 0 !important;
  border: none !important;
  background: 0 0 !important; }

.selectric-temp-show {
  position: absolute !important;
  visibility: hidden !important;
  display: block !important; }

.selectric-items {
  position: absolute;
  top: 100%;
  left: 0;
  visibility: hidden;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: -1;
  transition: visibility, opacity;
  transition-duration: 0s, .3s;
  transition-delay: .1s, 0s;
  transform: translateZ(0); }

.selectric-items .selectric-scroll {
  height: 100%;
  overflow: auto; }

.selectric-above .selectric-items {
  top: auto;
  bottom: 100%; }

.selectric-items li, .selectric-items ul {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 12px;
  line-height: 20px;
  min-height: 20px; }

.selectric-items li {
  padding: .9375rem 1.25rem;
  position: relative;
  display: block;
  color: #fff;
  cursor: pointer;
  background-color: transparent; }

.selectric-items .disabled {
  display: none; }

.selectric-item {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: .85;
  -webkit-transition: opacity .3s;
  transition: opacity .3s; }

.selectric-items li:hover .selectric-item {
  opacity: .9; }

.selectric-items li.selected .selectric-item {
  opacity: .8; }

.selectric-items li:hover {
  background-color: #5b4a7e; }

.selectric-items li.selected {
  background-color: #49396a; }

.selectric-text {
  position: relative; }

.has-contact-open .c-contact {
  transition-duration: 0s, .3s;
  transition-delay: .1s, 0s;
  visibility: visible;
  opacity: 1;
  overflow-y: auto; }

.has-contact-open .c-fab_ripple {
  transform: scale(1);
  opacity: 0;
  visibility: hidden;
  transition-duration: .6s, .3s, 0s;
  transition-delay: 0s, .3s, .5s; }

.has-contact-open .c-fab_icon.-close {
  opacity: 1; }

.has-contact-open .c-fab_icon.-create {
  opacity: 0; }

.has-contact-open .c-fab_button {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  background: #fff; }

.has-contact-open .c-fab_ripple {
  transform: scale(1);
  opacity: 0;
  visibility: hidden;
  transition-duration: .6s, .3s, 0s;
  transition-delay: 0s, .3s, .5s; }

.has-contact-open .c-fab {
  z-index: 1001;
  -webkit-transition-delay: 0s; }

@media (max-width: 1199px) {
  .has-contact-open .c-header-main_container {
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%); } }

.has-contact-open .c-header-main_background {
  -webkit-transition-delay: 0s, .3s, 0s;
  transition-delay: 0s, .3s, 0s; }

@media (max-width: 1199px) {
  .has-contact-open .c-header-main_background {
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%); } }

.has-contact-open .c-header-main {
  z-index: 100; }

.has-contact-open .c-nav, .has-contact-open .c-nav-page {
  -webkit-transition-delay: 0s, .6s;
  transition-delay: 0s, .6s; }

.has-contact-open .o-main-wrap {
  z-index: 50;
  position: relative; }

.u-background-accent {
  background-color: #4D84F1; }

.c-contact_wrapper {
  max-width: 105rem; }

.c-contact {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1001;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: visibility, opacity;
  transition: visibility, opacity;
  -webkit-transition-duration: 0s, .4s;
  transition-duration: 0s, .4s;
  transition-delay: .3s, .1s;
  text-align: left;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 9.375rem; }

@media (max-width: 699px) {
  .c-contact {
    padding-top: 5.625rem; } }

@media (min-width: 700px) and (max-width: 1199px) {
  .c-contact {
    padding-top: 12.5rem; } }

@media (min-width: 1200px) and (max-width: 1599px) {
  .c-contact {
    padding-top: 13.75rem; } }

@media (min-width: 1600px) {
  .c-contact {
    padding-top: 15rem; } }

.c-contact_item {
  font-weight: 400;
  opacity: 0;
  transition: opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1), transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  margin-bottom: 1.875rem;
  text-align: left;
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%); }

.c-contact_item.is-active {
  opacity: 1;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  transition-delay: .3s; }

.-menu .c-contact_item.is-active {
  transition-delay: 1.3s; }

@media (max-width: 699px) {
  .c-contact_item {
    margin-bottom: 1.25rem; } }

.c-contact_item.-send-button {
  display: inline-block;
  letter-spacing: normal;
  font-weight: 500;
  padding: 1rem 2.8125rem; }

@media (max-width: 699px) {
  .c-contact_item.-send-button {
    display: block;
    margin-top: .9375rem; } }

.c-contact_content {
  pointer-events: auto;
  z-index: 0;
  display: none;
  text-align: right;
  font-size: 18px;
  font-family: "Museo Sans Cyrl 700",sans-serif; }
  .c-contact_content span.error {
    position: absolute;
    bottom: 22px;
    right: 15px;
    font-size: 18px;
    color: #000;
    animation: blink .5s infinite; }
    @media (max-width: 699px) {
      .c-contact_content span.error {
        font-size: 16px; } }

.c-contact_content.is-active {
  z-index: 100;
  display: block; }

.c-contact_message {
  position: relative;
  background-color: transparent;
  font-weight: 500;
  line-height: 1.2; }

@media (max-width: 699px) {
  .c-contact_message {
    font-size: 30px;
    margin: 1.875rem 0; } }

@media (min-width: 700px) and (max-width: 999px) {
  .c-contact_message {
    font-size: 4rem;
    margin: 3.75rem 0; } }

@media (min-width: 1000px) {
  .c-contact_message {
    font-size: 5rem;
    margin: 3.75rem 0; } }

.c-contact_textbox {
  display: inline-block;
  width: auto;
  min-height: 3.75rem;
  outline: 0;
  line-height: 1.1; }

.c-contact_menu_item {
  width: 100%;
  letter-spacing: normal;
  font-weight: 500;
  text-align: center; }

.c-contact_back {
  position: absolute;
  left: 0;
  top: 0;
  margin-top: -60px;
  z-index: 100;
  opacity: 0;
  -webkit-transition: opacity .2s ease-out;
  transition: opacity .2s ease-out;
  cursor: pointer;
  width: 3.75rem;
  height: 3.75rem;
  margin-bottom: 3.75rem;
  z-index: 10;
  outline: none;
  border: none;
  background: none; }

.c-contact_back:hover {
  animation-name: bounceLeft;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1); }

.c-contact_back.is-active {
  opacity: 1;
  transition: opacity .2s ease-out .5s; }

@media (max-width: 999px) {
  .c-contact_back {
    top: 15%; } }

.c-contact_back_arrow {
  vertical-align: middle; }

.c-contact_back_icon {
  width: 50px;
  height: 25px;
  fill: currentColor;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -.625rem; }

.c-contact_error {
  display: none;
  text-align: right; }

.c-contact_filename {
  position: absolute;
  top: 50%;
  left: 50px;
  color: #fff;
  transform: translateY(-50%); }

.c-contact_spinner {
  width: 20px;
  height: 20px;
  text-align: center;
  opacity: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); }

.c-contact_spinner_wheel {
  width: 20px;
  height: 20px;
  margin: 0 auto;
  border: 3px solid currentColor;
  border-radius: 50%;
  border-left-color: transparent;
  border-right-color: transparent;
  animation: cssload-spin 1.4s infinite linear; }

@-webkit-keyframes cssload-spin {
  100% {
    transform: rotate(360deg); } }

@keyframes cssload-spin {
  100% {
    transform: rotate(360deg); } }

.c-contact_repatcha {
  display: inline-block;
  vertical-align: middle;
  float: left; }

@media (max-width: 999px) {
  .c-contact_repatcha {
    margin: 1.875rem 0; } }

.u-screen-reader-text {
  position: absolute !important;
  overflow: hidden;
  height: 1px;
  width: 1px;
  padding: 0;
  margin: 0;
  border: 0;
  clip: rect(0 0 0 0); }

.datepicker table tr td, .datepicker table tr th {
  color: #fff; }

.datepicker-dropdown.datepicker-orient-bottom:after {
  border-bottom: 6px solid rgba(0, 0, 0, 0.85); }

.datepicker table tr td.day:hover, .datepicker table tr td.focused {
  color: #000; }

.datepicker table tr td span:hover, .datepicker table tr td span.focused {
  color: #000; }

.datepicker .datepicker-switch:hover, .datepicker .prev:hover, .datepicker .next:hover, .datepicker tfoot tr th:hover {
  color: #000; }

.dropdown-menu {
  background-color: rgba(0, 0, 0, 0.85); }

.selectric .label {
  font-size: 18px;
  font-family: "Museo Sans Cyrl 700",sans-serif; }

.two-select {
  width: 49%;
  float: left; }

.two-select:last-of-type {
  float: right; }

@media (max-width: 699px) {
  .selectric .label {
    font-size: 16px; }
  .two-select {
    width: 100%;
    float: none; } }

p#caption {
  font-family: "Museo Sans Cyrl 900",sans-serif;
  font-size: calc(14px + 6.6vmin);
  margin-bottom: 50px; }

p#caption-two {
  font-family: "Museo Sans Cyrl 300",sans-serif;
  font-size: calc(14px + 4.3vmin);
  width: 100%;
  max-width: 620px; }

.u-background {
  background-color: #FFFFFF; }

@-webkit-keyframes bounceLeft {
  0% {
    -webkit-transform: translateX(0) scaleX(1);
    transform: translateX(0) scaleX(1); }
  10% {
    -webkit-transform: translateX(10px) scaleX(0.85);
    transform: translateX(10px) scaleX(0.85); }
  20% {
    -webkit-transform: translateX(-20px) scaleX(1);
    transform: translateX(-20px) scaleX(1); }
  30% {
    -webkit-transform: translateX(0) scaleX(1);
    transform: translateX(0) scaleX(1); }
  40% {
    -webkit-transform: translateX(-15px) scaleX(0.9);
    transform: translateX(-15px) scaleX(0.9); }
  50% {
    -webkit-transform: translateX(0) scaleX(1);
    transform: translateX(0) scaleX(1); } }

@keyframes bounceLeft {
  0% {
    -webkit-transform: translateX(0) scaleX(1);
    transform: translateX(0) scaleX(1); }
  10% {
    -webkit-transform: translateX(10px) scaleX(0.85);
    transform: translateX(10px) scaleX(0.85); }
  20% {
    -webkit-transform: translateX(-20px) scaleX(1);
    transform: translateX(-20px) scaleX(1); }
  30% {
    -webkit-transform: translateX(0) scaleX(1);
    transform: translateX(0) scaleX(1); }
  40% {
    -webkit-transform: translateX(-15px) scaleX(0.9);
    transform: translateX(-15px) scaleX(0.9); }
  50% {
    -webkit-transform: translateX(0) scaleX(1);
    transform: translateX(0) scaleX(1); } }

.c-fab {
  position: fixed;
  right: 0;
  bottom: 50px;
  z-index: 100;
  transition: z-index 0s, transform .3s;
  -webkit-tap-highlight-color: transparent; }

.is-compact .c-fab_icon {
  fill: #fff; }

.is-compact .u-foreground-accent {
  background: #8dc63f; }

.is-compact .c-fab {
  margin: 1rem; }

.is-compact .c-fab_button {
  width: 28px;
  height: 28px; }

.is-compact .c-fab_icon {
  width: 16px;
  height: 16px;
  margin-top: -8px;
  margin-left: -8px; }

button#mobile-close {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  margin: 15px;
  border: none;
  z-index: 1000;
  background: none; }

button#mobile-close::after {
  content: '×';
  font-size: 40px; }

.c-fab#mobile {
  margin: 0;
  right: 0;
  bottom: 0;
  position: relative;
  text-align: right; }

.c-fab#mobile:hover {
  transform: none; }

.c-fab#mobile .c-fab_ripple {
  top: calc(100% - 30px);
  left: calc(100% - 30px); }

@media (max-width: 1024px) {
  .c-fab#desktop {
    display: none; } }

.c-fab {
  margin: 1.875rem; }

.c-fab:hover {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1); }

.c-fab_ripple {
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  z-index: 1;
  -webkit-transform: scale(0.005);
  -ms-transform: scale(0.005);
  transform: scale(0.005);
  transition: transform, opacity, visibility;
  -webkit-transition-duration: .6s, .3s, 0s;
  transition-duration: .6s, .3s, 0s;
  -webkit-transition-delay: .2s, 0s, 0s;
  transition-delay: .2s, 0s, 0s; }

@media (max-width: 999px) {
  .c-fab_ripple {
    width: 250vh;
    height: 250vh;
    margin-top: -125vh;
    margin-left: -125vh; } }

@media (min-width: 1000px) {
  .c-fab_ripple {
    width: 250vw;
    height: 250vw;
    margin-top: -125vw;
    margin-left: -125vw; } }

.c-fab_button {
  position: relative;
  border-radius: 50%;
  font-size: 0;
  cursor: pointer;
  z-index: 2;
  box-shadow: 0 1px 1.5px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.24);
  transition: color .3s, background-color .3s, box-shadow .3s, visibility 0s, opacity 0s; }

@media (max-width: 2099px) {
  .c-fab_button {
    width: 56px;
    height: 56px; } }

@media (min-width: 2100px) {
  .c-fab_button {
    width: 3.5rem;
    height: 3.5rem; } }

.c-fab_button:hover {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2); }

.c-fab_icon {
  position: absolute;
  top: 50%;
  left: 50%;
  fill: #8dc63f;
  -webkit-transition: opacity .3s;
  transition: opacity .3s; }

@media (max-width: 2099px) {
  .c-fab_icon {
    width: 24px;
    height: 24px;
    margin-top: -12px;
    margin-left: -12px;
    display: block; } }

@media (min-width: 2100px) {
  .c-fab_icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-top: -.75rem;
    margin-left: -.75rem; } }

.c-fab_icon.-close {
  opacity: 0; }

.u-foreground {
  color: #222222; }

.u-background-reverse {
  background-color: #222222; }

.u-foreground-reverse {
  color: #FFFFFF; }

.u-background-accent {
  background: linear-gradient(223deg, #eb1a3e, #f3705b, #c5d17a, #34773a);
  background-size: 800% 800%;
  -webkit-animation: AnimationName 53s ease infinite;
  -moz-animation: AnimationName 53s ease infinite;
  animation: AnimationName 53s ease infinite; }

@-webkit-keyframes AnimationName {
  0% {
    background-position: 0% 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0% 50%; } }

@-moz-keyframes AnimationName {
  0% {
    background-position: 0% 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0% 50%; } }

@keyframes AnimationName {
  0% {
    background-position: 0% 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0% 50%; } }

.u-foreground-accent {
  color: #FFFFFF;
  background: #fff; }

.u-background-accent-reverse {
  background-color: #FFFFFF; }

.u-foreground-accent-reverse {
  color: #4D84F1; }

body {
  overflow-x: hidden;
  transition: color .3s; }

body.t-work {
  background-color: #fff; }

body.t-play {
  background-color: #000; }

body.has-contact-open, body.has-nav-open {
  overflow: hidden; }

body.has-contact-open.is-ios, body.has-nav-open.is-ios {
  position: fixed; }

body.has-next {
  -webkit-transition: none;
  transition: none; }

body.play-is-drawing {
  cursor: none; }

.ll-skin-lugo {
  font-size: 90%; }

.ll-skin-lugo .ui-widget {
  font-family: "Helvetica","Trebuchet MS",Tahoma,Verdana,Arial,sans-serif;
  background: #1c1c1c;
  border: none;
  border-radius: 0;
  -moz-box-shadow: 0 0 3px #292c32;
  -webkit-box-shadow: 0 0 3px #292c32;
  box-shadow: 0 0 3px #292c32; }

.ll-skin-lugo .ui-datepicker {
  width: 100%;
  max-width: 250px;
  padding: 0; }

.ll-skin-lugo .ui-datepicker-header {
  margin-top: 5px;
  font-size: 80%;
  border: none;
  border-top: 1px solid #000;
  /* stroke */
  border-bottom: 1px solid #000;
  /* stroke */
  background: #333;
  /* layer fill content */
  color: #fff;
  /* text color */
  font-size: 14px;
  font-weight: bold;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.7);
  /* drop shadow */
  border-radius: 0;
  box-shadow: inset 0 0 3px rgba(255, 255, 255, 0.1);
  /* inner glow */ }

.ll-skin-lugo .ui-datepicker-header .ui-state-hover {
  background: transparent;
  border-color: transparent;
  cursor: pointer; }

.ll-skin-lugo .ui-datepicker .ui-datepicker-next span {
  background-image: url(/img/ui-icons_ffffff_256x240.png);
  background-position: -32px -16px; }

.ll-skin-lugo .ui-datepicker .ui-datepicker-prev span {
  background-image: url(/img/ui-icons_ffffff_256x240.png);
  background-position: -96px -16px; }

.ll-skin-lugo .ui-datepicker table {
  margin: 0; }

.ll-skin-lugo .ui-datepicker th {
  color: #b7b7b7;
  font-size: 9px;
  text-transform: uppercase; }

.ll-skin-lugo .ui-datepicker td {
  border-top: 1px solid #2c2c2c;
  border-right: 1px solid #2c2c2c;
  padding: 0; }

.ll-skin-lugo .ui-datepicker td:last-child {
  border-right: none; }

.ll-skin-lugo td .ui-state-default {
  border: none;
  text-align: center;
  padding: .7em 0;
  margin: 0;
  font-size: 12px;
  font-weight: bold;
  color: #e7e7e7;
  /* text color */
  text-shadow: 0 1px 2px #000;
  /* drop shadow */
  background-color: #373737;
  /* layer fill content */
  -moz-box-shadow: inset 0 0 3px rgba(255, 255, 255, 0.2);
  /* inner glow */
  -webkit-box-shadow: inset 0 0 3px rgba(255, 255, 255, 0.2);
  /* inner glow */
  box-shadow: inset 0 0 3px rgba(255, 255, 255, 0.2);
  /* inner glow */
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEwMCAxMDAiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiPjxsaW5lYXJHcmFkaWVudCBpZD0iaGF0MCIgZ3JhZGllbnRVbml0cz0ib2JqZWN0Qm91bmRpbmdCb3giIHgxPSI1MCUiIHkxPSIxMDAlIiB4Mj0iNTAlIiB5Mj0iLTEuNDIxMDg1NDcxNTIwMmUtMTQlIj4KPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzM3MzczNyIgc3RvcC1vcGFjaXR5PSIxIi8+CjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzQyNDI0MiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgIDwvbGluZWFyR3JhZGllbnQ+Cgo8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMTAwIiBoZWlnaHQ9IjEwMCIgZmlsbD0idXJsKCNoYXQwKSIgLz4KPC9zdmc+);
  /* gradient overlay */
  background-image: linear-gradient(to top, #373737 0%, #424242 100%);
  /* gradient overlay */ }

.ll-skin-lugo td.ui-state-disabled .ui-state-default {
  background: #666;
  color: #ccc;
  text-shadow: none; }

.ll-skin-lugo td .ui-state-hover {
  background-color: #373737;
  /* layer fill content */
  -moz-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.7);
  /*inner shadow */
  -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.2);
  /* inner shadow */
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.2);
  /* inner shadow */
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEwMCAxMDAiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiPjxsaW5lYXJHcmFkaWVudCBpZD0iaGF0MCIgZ3JhZGllbnRVbml0cz0ib2JqZWN0Qm91bmRpbmdCb3giIHgxPSI1MCUiIHkxPSIxMDAlIiB4Mj0iNTAlIiB5Mj0iLTEuNDIxMDg1NDcxNTIwMmUtMTQlIj4KPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzQyNDI0MiIgc3RvcC1vcGFjaXR5PSIxIi8+CjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzM3MzczNyIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgIDwvbGluZWFyR3JhZGllbnQ+Cgo8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMTAwIiBoZWlnaHQ9IjEwMCIgZmlsbD0idXJsKCNoYXQwKSIgLz4KPC9zdmc+);
  /* gradient overlay */
  background-image: linear-gradient(to top, #424242 0%, #373737 100%);
  /* gradient overlay */ }

.ll-skin-lugo td .ui-state-active {
  background: #3f6b8a;
  /* layer fill content */
  -moz-box-shadow: inset 0 0 5px rgba(7, 15, 21, 0.9);
  /* inner shadow */
  -webkit-box-shadow: inset 0 0 5px rgba(7, 15, 21, 0.9);
  /* inner shadow */
  box-shadow: inset 0 0 5px rgba(7, 15, 21, 0.9);
  /* inner shadow */ }

@keyframes rotate {
  20%, 100% {
    transform: rotate(360deg); } }

.c-fab button {
  animation: rotate 5s infinite;
  transition: .5s; }

.article {
  float: left;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  background-color: #fff;
  width: calc(100% - 20px);
  vertical-align: top;
  margin: 20px 10px;
  transition-property: transform, opacity;
  transition-duration: 0.3s; }
  @media (min-width: 767px) {
    .article {
      width: calc(50% - 20px); } }
  @media (min-width: 992px) {
    .article {
      width: calc(33.33336% - 20px); } }
  @media (min-width: 1360px) {
    .article {
      width: calc(33.33336% - 20px); } }

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0; }
  .videoWrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

@media (min-width: 768px) {
  .isotope-filter {
    position: static !important; } }

#page-header {
  position: relative;
  margin: 0;
  width: 100%;
  color: #fff; }
  #page-header .container {
    position: relative; }
    #page-header .container > .wrap {
      padding-top: 25px;
      align-items: center;
      flex-wrap: wrap;
      flex-direction: column; }
      @media (min-width: 870px) {
        #page-header .container > .wrap {
          justify-content: space-between;
          flex-direction: row;
          align-items: flex-start; } }
      @media (min-width: 1100px) {
        #page-header .container > .wrap {
          flex-wrap: nowrap; } }
  #page-header .wrap {
    display: flex;
    justify-content: center; }
  #page-header .page-title {
    font-family: "Museo Sans Cyrl 100", sans-serif;
    font-weight: 100;
    color: #000;
    font-size: 35px;
    line-height: 1; }
    @media (min-width: 600px) {
      #page-header .page-title {
        font-size: 40px; } }
    @media (max-width: 1099px) {
      #page-header .page-title {
        width: 100%;
        text-align: center; } }
    @media (min-width: 1100px) {
      #page-header .page-title {
        margin: 0; } }
    @media (min-width: 1360px) {
      #page-header .page-title {
        font-size: 45px; } }
    #page-header .page-title span {
      font-family: "Museo Sans Cyrl 900", sans-serif;
      font-size: 35px;
      text-transform: uppercase; }
      @media (min-width: 600px) {
        #page-header .page-title span {
          font-size: 40px; } }
      @media (min-width: 1100px) {
        #page-header .page-title span {
          display: block; } }
      @media (min-width: 1360px) {
        #page-header .page-title span {
          font-size: 55px; } }
  #page-header .banner {
    display: none;
    width: 100%;
    background-color: #e1e1e1;
    margin-bottom: 15px; }
    @media (min-width: 600px) {
      #page-header .banner {
        width: 553px; } }
    @media (min-width: 870px) {
      #page-header .banner {
        margin-left: 10px;
        margin-bottom: 0; } }
    @media (min-width: 1360px) {
      #page-header .banner {
        margin-left: auto; } }
    #page-header .banner img {
      display: block;
      width: 100%; }

.md-padding {
  padding-top: 40px;
  padding-bottom: 40px; }

.isotope-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 40px; }
  @media (min-width: 769px) {
    .isotope-container {
      flex-wrap: nowrap;
      padding-top: 60px; } }

.isotope-filter {
  flex-basis: 100%;
  max-width: 200px; }
  @media (min-width: 769px) {
    .isotope-filter {
      flex-basis: 200px;
      padding-top: 10px; } }

.isotope-loop {
  flex-basis: 100%; }
  @media (min-width: 769px) {
    .isotope-loop {
      flex-basis: calc( 100% - 205px); } }

.filter-list {
  list-style-type: none;
  padding-left: 0; }
  .filter-list li {
    line-height: 29px; }
    .filter-list li.active a {
      color: #7bb249;
      text-decoration: none; }
  .filter-list a {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    color: #000;
    font-family: "Museo Sans Cyrl 100", sans-serif;
    font-size: 15px;
    transition: 0.2s ease-in-out; }
    .filter-list a:hover, .filter-list a.active {
      color: #7bb249;
      text-decoration: none; }
    @media (max-width: 991px) {
      .filter-list a {
        font-size: 11px; } }
  .filter-list__main {
    padding-bottom: 20px;
    margin-bottom: 0px; }
    .filter-list__main a {
      font-family: "Museo Sans Cyrl 700", sans-serif; }
    .filter-list__main h4 {
      font-size: 16px;
      color: #7bb249;
      margin-bottom: 25px; }
  .filter-list__additional {
    padding-top: 20px;
    margin-top: 18px;
    border-top: 1px solid #acacac; }

@media (min-width: 769px) {
  .filter-title {
    display: none; } }

.blolg-page .filter-list {
  margin-left: 7px; }

.widget-container .isotope-filter {
  margin-bottom: 48px; }

.accordion h3.ui-state-active svg.arrow {
  transform: rotate(0deg); }

h3 svg.arrow {
  stroke-width: 1; }

svg.arrow.right {
  text-align: right;
  transform: rotate(-90deg);
  margin-left: 10px;
  transition: 0.2s; }

.b-word {
  position: absolute;
  top: 200px;
  right: -150px;
  color: #e1e1e1;
  font-family: "Museo Sans Cyrl 900", sans-serif;
  font-size: 160px;
  text-transform: uppercase;
  transform: rotate(90deg);
  z-index: -1; }
  @media (min-width: 992px) {
    .b-word {
      font-size: 220px;
      right: -215px;
      top: 280px; } }
  @media (max-width: 1599px) {
    .b-word {
      display: none; } }

.pagination {
  text-align: center;
  margin-bottom: 30px; }
  .pagination .btn-group a {
    font-size: 16px;
    line-height: 1;
    color: black;
    padding: 0 5px;
    transition: .5s; }
    .pagination .btn-group a:hover {
      color: #7bb249;
      text-decoration: none;
      transition: .5s; }
    .pagination .btn-group a.disabled {
      color: #CCCCCC !important;
      pointer-events: none; }
    .pagination .btn-group a.active {
      color: #7bb249 !important;
      pointer-events: none; }

@media (max-width: 767px) {
  .isotope-filter {
    max-width: 100%; } }

.isotope-filter .ui-helper-hidden {
  display: none; }

.isotope-filter .ui-helper-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.isotope-filter .ui-helper-reset {
  margin: 0;
  padding: 0;
  outline: 0;
  line-height: 1.3;
  text-decoration: none;
  font-size: 100%;
  list-style: none; }

.isotope-filter .ui-helper-clearfix:before, .isotope-filter .ui-helper-clearfix:after {
  content: "";
  display: table;
  border-collapse: collapse; }

.isotope-filter .ui-helper-clearfix:after {
  clear: both; }

.isotope-filter .ui-helper-zfix {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  opacity: 0; }

.isotope-filter .ui-front {
  z-index: 100; }

.isotope-filter .ui-state-disabled {
  cursor: default !important;
  pointer-events: none; }

.isotope-filter .ui-accordion .ui-accordion-header {
  display: flex;
  cursor: pointer;
  position: relative;
  justify-content: space-between;
  align-items: center;
  background: #8dc63f;
  color: white;
  font-size: 13px;
  font-family: "Museo Sans Cyrl 700", sans-serif;
  text-transform: uppercase;
  padding: 18px 26px 16px 22px;
  border-color: #8dc63f;
  margin-bottom: 0; }
  .isotope-filter .ui-accordion .ui-accordion-header:focus {
    outline: none; }
  .isotope-filter .ui-accordion .ui-accordion-header svg line {
    stroke: white;
    stroke-width: 5px; }

.isotope-filter .ui-accordion .ui-accordion-content {
  padding: 0;
  border-top: 0;
  overflow: auto; }
  .isotope-filter .ui-accordion .ui-accordion-content ul li:hover {
    background: #ececec; }
  .isotope-filter .ui-accordion .ui-accordion-content ul li a {
    font-size: 13px;
    font-family: "Museo Sans Cyrl 700", sans-serif;
    line-height: 1;
    padding: 13px 0px 13px 20px; }
    .isotope-filter .ui-accordion .ui-accordion-content ul li a.active {
      background: #8dc63f;
      color: white; }

.event-block {
  display: block;
  position: relative;
  width: 260px; }
  @media (min-width: 870px) {
    .event-block {
      margin-left: 10px; } }
  @media (min-width: 1360px) {
    .event-block {
      margin-left: 20px; } }

.event-page__link {
  color: #a0a0a0;
  font-family: "Museo Sans Cyrl 700", sans-serif;
  font-size: 11px;
  text-transform: uppercase;
  transition: 0.2s ease-in-out; }
  .event-page__link:hover {
    color: #7bb249;
    text-decoration: none; }
  .event-page__link i {
    margin-left: 5px; }

.event-list {
  padding-left: 0;
  list-style-type: none; }

.event-item {
  padding-top: 20px;
  padding-bottom: 5px; }
  .event-item:first-of-type {
    border-bottom: 1px solid #acacac; }

.event-link {
  display: flex; }
  .event-link:hover {
    text-decoration: none; }
  .event-link .wrap {
    display: flex;
    flex-direction: column;
    padding-left: 10px; }

.event-title {
  color: #000;
  font-family: "Museo Sans Cyrl 300", sans-serif;
  font-size: 11px;
  line-height: normal;
  font-weight: 400;
  margin-bottom: 0;
  text-transform: uppercase;
  transition: 0.2s ease-in-out; }
  .event-title:hover {
    color: #7bb249;
    text-decoration: none; }

.event-date {
  color: #000;
  font-family: "Museo Sans Cyrl 900", sans-serif;
  font-size: 22px;
  text-transform: uppercase; }

.event-type {
  color: #000;
  font-family: "Museo Sans Cyrl 900", sans-serif;
  font-size: 11px;
  margin-top: 0; }

body {
  font-size: 13px;
  line-height: 1.65em;
  font-style: normal;
  font-weight: 400; }

.container .container {
  width: 100%;
  padding-left: 0;
  padding-right: 0; }

.main-content {
  padding-bottom: 70px; }

.comment-header {
  text-align: center; }
  .comment-header .header {
    display: block;
    font-family: "Museo Sans Cyrl 700", sans-serif;
    font-size: 35px;
    line-height: 1;
    text-transform: uppercase;
    margin-bottom: 30px; }
    @media (max-width: 460px) {
      .comment-header .header {
        font-size: 26px; } }
  .comment-header .subheader {
    font-family: "Museo Sans Cyrl 500", sans-serif;
    color: #000;
    font-size: 15px;
    font-weight: 400;
    line-height: 45px;
    margin-bottom: 20px; }
    .comment-header .subheader span {
      color: #f5012f; }
    @media (max-width: 765px) {
      .comment-header .subheader {
        line-height: 1.2; } }

.cs-section .wpb_row {
  margin-bottom: 0; }

.cs-section .wpb_content_element {
  margin-bottom: 35px; }

.cs-section .wpb_wrapper {
  position: relative;
  margin: 0 auto;
  width: 100%; }
  .cs-section .wpb_wrapper .wpb_video_wrapper {
    padding-top: 56.25%;
    position: relative;
    width: 100%; }
    .cs-section .wpb_wrapper .wpb_video_wrapper iframe {
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      margin: 0;
      top: 0;
      left: 0;
      box-sizing: border-box; }

.page-content .entry-img img {
  width: 100%; }

.page-content .entry-header {
  padding: 20px; }

.page-content .entry-title {
  font-family: "Museo Sans Cyrl 500", sans-serif;
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 10px; }
  .page-content .entry-title a {
    text-decoration: none;
    color: #333; }
    .page-content .entry-title a:hover {
      color: #7bb249; }

.page-content .entry-meta {
  line-height: 20px;
  margin-bottom: 35px; }
  .page-content .entry-meta span {
    display: inline-block;
    color: #999;
    font-family: "Museo Sans Cyrl 500", sans-serif;
    font-weight: 400;
    vertical-align: middle;
    padding-right: 5%;
    font-size: 18px; }
    .page-content .entry-meta span:last-child {
      padding: 0; }
    .page-content .entry-meta span::before {
      content: '';
      background-repeat: no-repeat;
      background-size: contain;
      display: inline-block;
      width: 20px;
      height: 20px;
      margin-right: 15px;
      vertical-align: top;
      background-position: center center;
      background-repeat: no-repeat; }
    .page-content .entry-meta span a {
      color: inherit;
      text-decoration: none;
      cursor: pointer;
      vertical-align: top; }
      .page-content .entry-meta span a:hover {
        color: #7bb249; }

.page-content .entry-content h2, .page-content .entry-content h3, .page-content .entry-content h4 {
  margin-bottom: 20px;
  font-weight: normal; }
  .page-content .entry-content h2.light, .page-content .entry-content h3.light, .page-content .entry-content h4.light {
    font-family: 'Museo Sans Cyrl 100', sans-serif; }
  .page-content .entry-content h2.normal, .page-content .entry-content h3.normal, .page-content .entry-content h4.normal {
    font-family: 'Museo Sans Cyrl 400', sans-serif; }
  .page-content .entry-content h2.bold, .page-content .entry-content h3.bold, .page-content .entry-content h4.bold {
    font-family: 'Museo Sans Cyrl 700', sans-serif; }

.page-content .entry-content p {
  font-size: 18px;
  line-height: 26px;
  color: #000000;
  margin-bottom: 20px;
  font-family: 'Museo Sans Cyrl 500', sans-serif; }

.page-content .entry-content iframe {
  width: 100%;
  border: none;
  outline: none;
  margin-bottom: 20px; }

.page-content .entry-content .imageBlock {
  position: relative;
  margin-bottom: 20px; }
  .page-content .entry-content .imageBlock .textBG {
    content: '';
    position: absolute;
    background: black;
    opacity: .5;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%; }
  .page-content .entry-content .imageBlock .text {
    width: 80%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 35px;
    line-height: 38px;
    font-weight: bold;
    color: white;
    text-align: center; }

.page-content .entry-content ul, .page-content .entry-content ol {
  padding-left: 40px;
  font-size: 18px;
  line-height: 26px;
  color: #000;
  margin-bottom: 20px;
  font-family: 'Museo Sans Cyrl 400', sans-serif; }

.page-content .entry-content .list-square {
  list-style: square; }

.page-content .entry-content .list-disc {
  list-style: disc; }

.page-content .entry-content .list-circle {
  list-style: circle; }

.page-content .entry-content .list-line {
  padding-left: 0;
  list-style: none; }
  .page-content .entry-content .list-line li:before {
    content: '-';
    margin-right: 5px; }

.page-content .entry-content .list-decimal {
  list-style: decimal; }

.page-content .entry-content .list-roman {
  list-style: upper-roman; }

.page-content .entry-content .list-none {
  padding-left: 0;
  list-style: none; }

.page-content .entry-content .banner {
  display: inline-block;
  width: 100%;
  margin-bottom: 20px; }

.page-content .entry-content video {
  width: 100%;
  margin-bottom: 20px; }

.page-content .entry-date::before {
  background-image: url("/img/branding/entry-date.png"); }

.page-content .entry-cat-links::before {
  background-image: url("/img/branding/entry-cat-links.png"); }

.page-content .entry-comments-link::before {
  background-image: url("/img/branding/entry-comments-link.png"); }

.page-content .entry-love::before {
  background-image: url("/img/branding/entry-love.png"); }

.page-content .entry-views-count::before {
  background-image: url("/img/branding/entry-views-count.png"); }

.form-submit .cs-btn {
  display: inline-block;
  vertical-align: middle;
  margin: 3px 3px 3px 0;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  font-weight: 700;
  text-transform: uppercase;
  background: none;
  border: 2px solid transparent;
  user-select: none;
  transition: all 0.2s ease-in-out;
  outline: none; }
  .form-submit .cs-btn-flat {
    color: #fff !important; }
    .form-submit .cs-btn-flat-accent {
      background-color: #7bb249; }
      .form-submit .cs-btn-flat-accent:hover {
        background-color: #97c370; }
  .form-submit .cs-btn-xs {
    font-size: 12px;
    padding: 6px 16px;
    line-height: 1.2244897963; }

.custom-form {
  background: #f1f1f1;
  border-radius: 0;
  color: #000;
  max-width: 570px;
  width: 100%;
  padding: 75px 35px 50px 35px; }
  @media (max-width: 1023px) {
    .custom-form {
      margin-left: auto !important;
      margin-right: auto !important; } }

.wpcf7-form-control-wrap {
  width: 100%; }

.head-text {
  text-align: center; }
  .head-text span {
    font-family: "Museo Sans Cyrl 700", sans-serif;
    font-size: calc(.8vmin + 25px);
    text-transform: uppercase; }
  .head-text small {
    font-family: "Museo Sans Cyrl 200", sans-serif;
    font-size: calc(.8vmin + 14px); }

.wpcf7-form-control {
  margin-top: 5px;
  max-width: 100%; }

.wpcf7 label {
  font-weight: normal; }

.cs-space {
  clear: both;
  margin-top: 50px;
  border: none !important; }

.attachment-full {
  max-width: 955px;
  width: 100%; }

.wpb_row > .row {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row; }
  @media (max-width: 1365px) {
    .wpb_row > .row {
      flex-direction: column; } }

.route_widget {
  width: 100%;
  margin-bottom: 48px;
  word-wrap: break-word; }
  .route_widget .widget-title h4 {
    font-size: 16px;
    color: #7bb249;
    margin-bottom: 35px; }
    @media (max-width: 992px) {
      .route_widget .widget-title h4 {
        margin-top: 35px; } }
  .route_widget ul {
    margin: 0;
    padding: 0;
    list-style-type: none; }
    .route_widget ul li {
      margin-bottom: 15px;
      padding-bottom: 15px;
      border-bottom: 1px solid #e8e8e8; }
      .route_widget ul li a {
        color: #333;
        text-decoration: none; }
        .route_widget ul li a img {
          width: 100%;
          margin-bottom: 10px; }
        .route_widget ul li a:hover {
          color: #7bb249; }

.cs_widget_custom_posts .cs-with-image li {
  position: relative;
  padding-left: 70px;
  min-height: 74px;
  margin-bottom: 10px;
  padding-bottom: 14px; }
  .cs_widget_custom_posts .cs-with-image li a {
    display: block;
    width: 100%;
    text-decoration: none; }
    .cs_widget_custom_posts .cs-with-image li a:hover {
      color: #7bb249; }
    .cs_widget_custom_posts .cs-with-image li a img {
      -moz-border-radius: 2px;
      -webkit-border-radius: 2px;
      border-radius: 2px;
      position: absolute;
      left: 0;
      top: 2px;
      width: 60px; }

.entry-footer {
  margin-top: 24px; }

iframe {
  max-width: 100%; }
  @media (max-width: 767px) {
    iframe {
      max-height: 300px; } }

.blog-form {
  background: url(/img/blog-form-bg.png) no-repeat;
  background-size: contain;
  background-position: 10% center;
  padding-top: 30px;
  padding-bottom: 30px; }
  @media (max-width: 991px) {
    .blog-form {
      background: none; } }

.coments textarea {
  margin-bottom: 15px; }

.coments .wpcf7 span.error {
  bottom: -5px;
  top: auto; }

.coments .likeWork {
  display: table;
  margin: 15px auto; }

.vote {
  padding: 0;
  display: flex;
  margin-top: 60px;
  margin-bottom: 60px;
  align-items: center;
  justify-content: space-around; }
  @media (max-width: 767px) {
    .vote {
      flex-direction: column; } }
  .vote-text {
    font-size: 27px;
    padding-right: 5px; }
    @media (max-width: 767px) {
      .vote-text {
        padding-right: 0;
        text-align: center; } }
  .vote .btnWrap a {
    padding-left: 40px;
    padding-right: 40px; }
    @media (max-width: 1199px) {
      .vote .btnWrap a {
        padding-left: 100px;
        padding-right: 100px; } }
    @media (max-width: 767px) {
      .vote .btnWrap a {
        padding-left: 20px;
        padding-right: 20px; } }
  @media (max-width: 767px) {
    .vote .btnWrap {
      margin-top: 30px; } }

.shortInfo {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px; }
  .shortInfo > span {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #999 !important; }
    .shortInfo > span img {
      width: auto !important;
      max-height: 13px;
      margin-right: 5px;
      margin-bottom: 0 !important;
      max-width: 18px; }

.article-insert .entry-img img {
  width: 100%; }

.article-insert .entry-header {
  padding: 20px; }

.article-insert .entry-title {
  font-family: "Museo Sans Cyrl 500", sans-serif;
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 10px;
  padding: 10px 20px 0; }
  .article-insert .entry-title a {
    text-decoration: none;
    color: #333; }
    .article-insert .entry-title a:hover {
      color: #7bb249; }

.article-insert .entry-meta {
  line-height: 20px;
  padding: 0 20px 10px; }
  .article-insert .entry-meta span {
    display: inline-block;
    color: #333;
    font-family: "Museo Sans Cyrl 500", sans-serif;
    font-weight: 500;
    vertical-align: top;
    padding-right: 20px;
    font-size: 11px;
    text-transform: uppercase; }
    .article-insert .entry-meta span:last-child {
      padding: 0; }
    .article-insert .entry-meta span::before {
      content: '';
      vertical-align: middle;
      background-repeat: no-repeat;
      background-size: contain;
      display: inline-block;
      width: 14px;
      height: 10px;
      margin-right: 5px; }
    .article-insert .entry-meta span a {
      color: inherit;
      text-decoration: none;
      cursor: pointer; }
      .article-insert .entry-meta span a:hover {
        color: #7bb249; }

.article-insert .entry-date::before {
  background-image: url("/img/branding/entry-date.png"); }

.article-insert .entry-cat-links::before {
  background-image: url("/img/branding/entry-cat-links.png"); }

.article-insert .entry-comments-link::before {
  background-image: url("/img/branding/entry-comments-link.png"); }

.article-insert .entry-love::before {
  background-image: url("/img/branding/entry-love.png"); }

.article-insert .entry-views-count::before {
  background-image: url("/img/branding/entry-views-count.png"); }
