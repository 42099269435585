#page-header {
  position: relative;
  margin: 0;
  width: 100%;
  color: #fff;
  //background-color: #7bb249;
  .container{
    position: relative;
    > .wrap {
      padding-top: 25px;
      align-items: center;
      flex-wrap: wrap;
      flex-direction: column;
      @media (min-width: 870px) {
        justify-content: space-between;
        flex-direction: row;
        align-items: flex-start;
      }
      @media (min-width: 1100px) {
        flex-wrap: nowrap;
      }
    }
  }
  .wrap {
    display: flex;
    justify-content: center;
  }
  .page-title {
    font-family: $museo100;
    font-weight: 100;
    color: #000;
    font-size: 35px;
    line-height: 1;
    @media (min-width: 600px) {
      font-size: 40px;
    }
    @media (max-width: 1099px) {
      width: 100%;
      text-align: center;
    }
    @media (min-width: 1100px) {
      margin: 0;
    }
    @media (min-width: 1360px) {
      font-size: 45px;
    }
    span {
      font-family: $museo900;
      font-size: 35px;
      text-transform: uppercase;
      @media (min-width: 600px) {
        font-size: 40px;
      }
      @media (min-width: 1100px) {
        display: block;
      }
      @media (min-width: 1360px) {
        font-size: 55px;
      }
    }
  }
  .banner {
    //display: block;
    display: none;
    width: 100%;
    background-color: #e1e1e1;
    margin-bottom: 15px;
    @media (min-width: 600px) {
      width: 553px;
    }
    @media (min-width: 870px) {
      margin-left: 10px;
      margin-bottom: 0;
    }
    @media (min-width: 1360px) {
      margin-left: auto;
    }
    img {
      display: block;
      width: 100%;
    }
  }
}

.md-padding {
  padding-top: 40px;
  padding-bottom: 40px;
}