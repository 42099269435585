#top-bar {
  position: relative;
  color: #555;
  background-color: #f1f1f1;
  border-bottom: 1px solid #e8e8e8;
  line-height: 36px;
  @media (max-width: 1024px) {
    display: none!important;
  }
}

.rd-top-left {
  float: left;
  margin-bottom: -1px;
}

.rd-top-right {
  float: right;
  margin-bottom: -1px;
}

.rd-top-module {
  vertical-align: top;
  display: inline-block;
  position: relative;
  padding: 0 6px;
  margin-right: -1px;
  //border-left: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
  font-size: 11px;
  line-height: 35px;
  &:first-child {
    border-left: none;
    padding-left: 0;
  }
  &:last-child {
    border-right: none;
    padding-right: 0;
  }
}

.rd-top-module .rd-in {
  margin-right: 5px;
}

.rd-top-module > a, .rd-top-module .rd-open-modal {
  color: #333;
  display: inline-block;
}