.isotope {
  &-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-bottom: 40px;
    @media (min-width: 769px) {
      flex-wrap: nowrap;
      padding-top: 60px;
    }
  }
  &-filter {
    flex-basis: 100%;
    max-width: 200px;
    @media (min-width: 769px) {
      flex-basis: 200px;
      padding-top: 10px;
    }
  }
  &-loop {
    flex-basis: 100%;
    @media (min-width: 769px) {
      flex-basis: calc( 100% - 205px);
    }
  }
}

.filter {
  &-list {
    list-style-type: none;
    padding-left: 0;
    li {
      line-height: 29px;
      &.active{
        a {
          color: #7bb249;
          text-decoration: none;
        }
      }
    }
    a {
      display: block;
      position: relative;
      width: 100%;
      height: 100%;
      color: #000;
      font-family: $museo100;
      font-size: 15px;
      transition: 0.2s ease-in-out;
      &:hover, &.active {
        color: #7bb249;
        text-decoration: none;
      }
      @media (max-width: 991px) {
        font-size: 11px;
      }
    }
    &__main {
      a {
        font-family: $museo700;
      }
      padding-bottom: 20px;
      margin-bottom: 0px;
      h4{
        font-size: 16px;
        color: #7bb249;
        margin-bottom: 25px;
      }
    }
    &__additional{
      padding-top: 20px;
      margin-top: 18px;
      border-top: 1px solid #acacac;
    }
  }
  &-title {
    @media (min-width: 769px) {
      display: none;
    }
  }
}

.blolg-page {
  .filter-list {
    margin-left: 7px;
  }
}

.widget-container {
  .isotope-filter {
    margin-bottom: 48px;
  }
}

.accordion h3.ui-state-active svg.arrow {
  transform: rotate(0deg);
}

h3 svg.arrow {
  stroke-width: 1;
}

svg.arrow.right {
  text-align: right;
  transform: rotate(-90deg);
  margin-left: 10px;
  transition: 0.2s;
}

.b-word {
  position: absolute;
  top: 200px;
  right: -150px;
  color: #e1e1e1;
  font-family: $museo900;
  font-size: 160px;
  text-transform: uppercase;
  transform: rotate(90deg);
  z-index: -1;
  @media (min-width: 992px) {
    font-size: 220px;
    right: -215px;
    top: 280px;
  }
  @media (max-width:1599px){
    display: none;
  }
}

.pagination{
  text-align: center;
  margin-bottom: 30px;
  .btn-group{
    a{
      font-size: 16px;
      line-height: 1;
      color: black;
      padding: 0 5px;
      transition: .5s;
      &:hover{
        color: #7bb249;
        text-decoration: none;
        transition: .5s;
      }
      &.disabled{
        color: #CCCCCC!important;
        pointer-events: none;
      }
      &.active{
        color: #7bb249!important;
        pointer-events: none;
      }
    }
  }
}