@import "bourbon";
//@include font-face("Museo Sans Cyrl 100", "/fonts/museosanscyrl-100",normal, $file-formats: woff2 woff);
//@include font-face("Museo Sans Cyrl 300", "/fonts/museosanscyrl-300",normal, $file-formats: woff2 woff);
//@include font-face("Museo Sans Cyrl 500", "/fonts/museosanscyrl-500",normal, $file-formats: woff2 woff);
//@include font-face("Museo Sans Cyrl 700", "/fonts/museosanscyrl-700",normal, $file-formats: woff2 woff);
//@include font-face("Museo Sans Cyrl 900", "/fonts/museosanscyrl-900",normal, $file-formats: woff2 woff);


@font-face {
  font-display: swap;
  font-family: "Museo Sans Cyrl 100";
  src: local('Museo Sans Cyrl 100'),
  url("/fonts/museosanscyrl-100.woff") format("woff"),
  url("/fonts/museosanscyrl-100.woff2")format("woff2");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-display: swap;
  font-family: "Museo Sans Cyrl 300";
  src: local('Museo Sans Cyrl 300'),
  url("/fonts/museosanscyrl-300.woff") format("woff"),
  url("/fonts/museosanscyrl-300.woff2")format("woff2");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-display: swap;
  font-family: "Museo Sans Cyrl 500";
  src: local('Museo Sans Cyrl 500'),
  url("/fonts/museosanscyrl-500.woff") format("woff"),
  url("/fonts/museosanscyrl-500.woff2")format("woff2");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-display: swap;
  font-family: "Museo Sans Cyrl 700";
  src: local('Museo Sans Cyrl 700'),
  url("/fonts/museosanscyrl-700.woff") format("woff"),
  url("/fonts/museosanscyrl-700.woff2")format("woff2");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-display: swap;
  font-family: "Museo Sans Cyrl 900";
  src: local('Museo Sans Cyrl 900'),
  url("/fonts/museosanscyrl-900.woff") format("woff"),
  url("/fonts/museosanscyrl-900.woff2")format("woff2");
  font-style: normal;
  font-weight: normal;
}


$default-font: sans-serif;

$museo100: 'Museo Sans Cyrl 100', $default-font;
$museo300: 'Museo Sans Cyrl 300', $default-font;
$museo500: 'Museo Sans Cyrl 500', $default-font;
$museo700: 'Museo Sans Cyrl 700', $default-font;
$museo900: 'Museo Sans Cyrl 900', $default-font;