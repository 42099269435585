.article {
  &-insert {
    .entry {
      &-img {
        img {
          width: 100%;
        }
      }
      &-header {
        padding: 20px;
      }
      &-title {
        font-family: "Museo Sans Cyrl 500", sans-serif;
        font-weight: 500;
        font-size: 18px;
        margin-bottom: 10px;
        padding: 10px 20px 0;
        a{
          text-decoration: none;
          color: #333;
          &:hover{
            color: #7bb249;
          }
        }
      }
      &-meta {
        line-height: 20px;
        padding: 0 20px 10px;
        span {
          display: inline-block;
          color: #333;
          font-family: "Museo Sans Cyrl 500", sans-serif;
          font-weight: 500;
          vertical-align: top;
          padding-right: 20px;
          font-size: 11px;
          text-transform: uppercase;
          &:last-child {
            padding: 0;
          }
          &::before {
            content: '';
            vertical-align: middle;
            background-repeat: no-repeat;
            background-size: contain;
            display: inline-block;
            width: 14px;
            height: 10px;
            margin-right: 5px;
          }
          a {
            color: inherit;
            text-decoration: none;
            cursor: pointer;
            &:hover{
              color: #7bb249;
            }
          }
        }
      }
      &-date::before {
        background-image: url('/img/branding/entry-date.png');
      }
      &-cat-links::before {
        background-image: url('/img/branding/entry-cat-links.png');
      }
      &-comments-link::before {
        background-image: url('/img/branding/entry-comments-link.png');
      }
      &-love::before {
        background-image: url('/img/branding/entry-love.png');
      }
      &-views-count::before {
        background-image: url('/img/branding/entry-views-count.png');
      }
    }
  }
}