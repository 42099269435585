
.isotope-filter {
  @media(max-width: 767px) {
    max-width: 100%;
  }
  .ui-helper {
    &-hidden {
      display: none;
    }
    &-hidden-accessible {
      border: 0;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }
    &-reset {
      margin: 0;
      padding: 0;
      outline: 0;
      line-height: 1.3;
      text-decoration: none;
      font-size: 100%;
      list-style: none;
    }
    &-clearfix:before,
    &-clearfix:after {
      content: "";
      display: table;
      border-collapse: collapse;
    }
    &-clearfix:after {
      clear: both;
    }
    &-zfix {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      opacity: 0;
    }
  }

  .ui-front {
    z-index: 100;
  }

  .ui-state-disabled {
    cursor: default !important;
    pointer-events: none;
  }

  .ui-accordion .ui-accordion-header {
    display: flex;
    cursor: pointer;
    position: relative;
    justify-content: space-between;
    align-items: center;
    background: #8dc63f;
    color: white;
    font-size: 13px;
    font-family: "Museo Sans Cyrl 700", sans-serif;
    text-transform: uppercase;
    padding: 18px 26px 16px 22px;
    border-color: #8dc63f;
    margin-bottom: 0;
    &:focus {
      outline: none;
    }
    svg {
      line {
        stroke: white;
        stroke-width: 5px
      }
    }
  }
  .ui-accordion .ui-accordion-content {
    padding: 0;
    border-top: 0;
    overflow: auto;
    ul {
      li {
        &:hover {
          background: #ececec;
        }
        a {
          font-size: 13px;
          font-family: "Museo Sans Cyrl 700", sans-serif;
          line-height: 1;
          padding: 13px 0px 13px 20px;
          &.active {
            background: #8dc63f;
            color: white;
          }
        }
      }
    }
  }
}
