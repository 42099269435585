.bg-1 {
  background: #ECEFF1;
  color: #7bb249
}

.button {
  padding: 1em 10px;
  border: none;
  background-color: #7bb249;
  color: inherit;
  vertical-align: middle;
  position: relative;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale
}

.button:focus {
  outline: none
}

.button > span {
  vertical-align: middle
}

.bg-1.button {
  color: #fff;
  border-color: #7bb249
}

.button--size-s {
  font-size: 14px;
  @media (max-width:768px){
    font-size: 12px;
  }
}

.button--size-m {
  font-size: 16px;
  @media (max-width:768px){
    font-size: 14px;
  }
}

.button--size-l {
  font-size: 18px;
  @media (max-width:768px){
    font-size: 16px;
  }
}

.button--text-upper {
  letter-spacing: 2px;
  text-transform: uppercase
}

.button--text-thin {
  font-weight: 300
}

.button--text-medium {
  font-weight: 500
}

.button--text-thick {
  font-weight: 600
}

.button--round-s {
  border-radius: 5px
}

.button--round-m {
  border-radius: 15px
}

.button--round-l {
  border-radius: 40px
}

.button--border-thin {
  border: 1px solid
}

.button--antiman {
  background: none;
  border: none;
  height: 60px
}

.button--antiman.button--inverted, .button--antiman.button--inverted-alt {
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  -webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
  transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1)
}

.button--antiman > span {
  padding-left: 0.35em
}

.button--antiman::before, .button--antiman::after {
  content: '';
  z-index: -1;
  border-radius: inherit;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s;
  -webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
  transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1)
}

.button--antiman::before {
  border: 2px solid #7bb249;
  opacity: 0;
  -webkit-transform: scale3d(1.2, 1.2, 1);
  transform: scale3d(1.2, 1.2, 1)
}

.button--antiman.button--border-thin::before {
  border-width: 1px
}

.button--antiman.button--border-medium::before {
  border-width: 2px
}

.button--antiman.button--border-thick::before {
  border-width: 3px
}

.button--antiman.button--inverted::before {
  border-color: #7bb249
}

.button--antiman.button--inverted-alt::before {
  border-color: #7bb249
}

.button--antiman::after {
  background: #fff
}

.button--antiman.button--inverted::after {
  background: #7bb249
}

.button--antiman.button--inverted-alt::after {
  background: #7bb249
}

.button--antiman.button--inverted:hover {
  color: #7bb249;
  text-decoration: none
}

.button--antiman:hover::before {
  opacity: 1;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1)
}

.button--antiman:hover::after {
  opacity: 0;
  -webkit-transform: scale3d(0.8, 0.8, 1);
  transform: scale3d(0.8, 0.8, 1)
}

.to-form{
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
}

.play-button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 92px;
  height: 38px;
  overflow: hidden;
  background: #fff;
  border: 1px solid #000;
  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    left: -100%;
    opacity: 1;
    top: 0;
    box-sizing: border-box;
    text-align: center;
    transform-style: preserve-3d;
    transform-origin: 0 0;
    transition: all 0.6s cubic-bezier(0.05, 0.06, 0.05, 0.95);
    box-shadow: none;
    background-color: #000;
    z-index: 4;
  }
  &:hover {
    svg {
      position: relative;
      z-index: 5;
      path {
        stroke: #fff;
        @media (max-width:1024px){
          stroke: #000;
        }
      }
    }
    &:before {
      left: 0;
      @media (max-width:1024px){
        left:-100%;
      }
    }
  }
}

.case-button {
  display: block;
  position: relative;
  overflow: hidden;
  width: 273px;
  height: 35px;
  border: 1px solid red;
  span {
    display: block;
    position: relative;
    width: 100%;
    color: #fff;
    font-family: "Museo Sans Cyrl 100", sans-serif;
    font-size: 15px;
    font-weight: 100;
    line-height: 35px;
    text-align: center;
    text-transform: uppercase;
    transition: all 0.6s cubic-bezier(0.05, 0.06, 0.05, 0.95);
    z-index: 5;
    background: red;
  }
  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    left: -100%;
    opacity: 1;
    top: 0;
    box-sizing: border-box;
    text-align: center;
    transform-style: preserve-3d;
    transform-origin: 0 0;
    transition: all 0.6s cubic-bezier(0.05, 0.06, 0.05, 0.95);
    box-shadow: none;
    background-color: #000;
    z-index: 4;
  }
  &:hover {
    text-decoration: none;
    span {
      color: #fff !important;
    }
    &:before {
      left: 0;
    }
  }
}
button.button:disabled {
  opacity: .5;
  cursor: not-allowed;
}
.read-more{
  color: #878787;
  text-decoration: none;
  font-family: "Museo Sans Cyrl 100",sans-serif;
  font-size: calc(1vmin + 15px);
  line-height: 30px;
  cursor: pointer;
  position: relative;
  &:hover::before {
    content: '';
    position: absolute;
    bottom: 0;
    left:0;
    border-bottom: 1px solid #878787;
    width: 100%;
  }
}

.visible {
  display: inline;
  p{
    &:last-of-type{
      display: inline;
    }
  }
  &.ellipsis{
    p {
      &:last-of-type {
        &::after {
          content: '...';
          color: #000;
          font-family: "Museo Sans Cyrl 100", sans-serif;
          font-size: calc(1.2vmin + 10px);
          line-height: 30px;
        }
      }
    }
  }
}
.invisible {
  p{
    //&:first-of-type{
    //  display: inline;
    //}
  }
  &::before{
    content: ' ';
    white-space: pre;
  }
  display: none;
}
span.error{
  display: none;
}
#cs-top {
  display: none;
  position: fixed;
  bottom: 30px;
  left: 25px;
  z-index: 99;
  width: 30px;
  height: 30px;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  color: #fff;
  background-color: #000;
  opacity: 0.3;
  border: 1px solid white;
  border-radius: 2px;
  transition: all 0.2s;
  @media (max-width: 1400px){
    left:15px;
  }
}

#cs-top.in {
  bottom: 15px;
}

#cs-top:hover {
  opacity: 1
}

#cs-top:before {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  margin-top: -6px
}
#footer{
  #cs-top {
    position: absolute;
    bottom:auto;
    left:auto;
    top: 48px;
    right: 15px;
    display: block;
    opacity: 1;
  }
}
.addComentWrap {
  text-align: center;
  padding-bottom: 60px;
  a{
    text-transform: uppercase;
  }
}