.breadcrumb {
  list-style-type: none;
  padding: 10px 10px 10px 0;
  width: 100%;
  display: inline-block;
  &-item {
    float: left;
    color: #b9b9b9;
    font-family: $museo300;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 35%;
    white-space: nowrap;
    &+.breadcrumb-item::before {
      content: '\\';
      display: inline-block;
      padding-right: .5rem;
      padding-left: .5rem;
      color: #b9b9b9;
    }
    &+.breadcrumb-item.categories::before {
      content: ',';
      padding-left: 0;
    }
    &+.breadcrumb-item.categories:nth-child(3)::before {
      content: '\\';
      padding-left: .5rem;
    }
    &:hover {
      color: #7bb249;
      &:last-child{
        color: #b9b9b9;
        cursor: default;
      }
    }
  }
  a {
    color: inherit;
    font-family: inherit;
    font-size: inherit;
  }
}