.article {
  float: left;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  background-color: #fff;
  width: calc(100% - 20px);
  vertical-align: top;
  margin: 20px 10px;
  transition-property: transform, opacity;
  transition-duration: 0.3s;
  @media (min-width: 767px) {
    width: calc(50% - 20px);
  }
  @media (min-width: 992px) {
    width: calc(33.33336% - 20px);
  }
  @media (min-width: 1360px) {
    width: calc(33.33336% - 20px);
  }
  //@media (max-width: 767px) {
  //  width: calc(100% - 20px);
  //}
  //@media (min-width: 768px) {
  //  margin: 20px 5px;
  //  max-width: 165px;
  //}
  //@media (min-width: 992px) {
  //  margin: 20px 5px;
  //  max-width: 220px;
  //}
  //@media (min-width: 1200px) {
  //  margin: 20px 10px;
  //  max-width: 260px;
  //}
  //&-insert {
  //  .entry {
  //    &-img {
  //      img {
  //        width: 100%;
  //      }
  //    }
  //    &-header {
  //      padding: 20px;
  //    }
  //    &-title {
  //      font-family: "Museo Sans Cyrl 500", sans-serif;
  //      font-weight: 500;
  //      font-size: 18px;
  //      margin-bottom: 10px;
  //      padding: 10px 20px 0;
  //      a{
  //        text-decoration: none;
  //        color: #333;
  //        &:hover{
  //          color: #7bb249;
  //        }
  //      }
  //    }
  //    &-meta {
  //      line-height: 20px;
  //      padding: 0 20px 10px;
  //      span {
  //        display: inline-block;
  //        color: #333;
  //        font-family: "Museo Sans Cyrl 500", sans-serif;
  //        font-weight: 500;
  //        vertical-align: top;
  //        padding-right: 20px;
  //        font-size: 11px;
  //        text-transform: uppercase;
  //        &:last-child {
  //          padding: 0;
  //        }
  //        &::before {
  //          content: '';
  //          vertical-align: middle;
  //          background-repeat: no-repeat;
  //          background-size: contain;
  //          display: inline-block;
  //          width: 14px;
  //          height: 10px;
  //          margin-right: 5px;
  //        }
  //        a {
  //          color: inherit;
  //          text-decoration: none;
  //          cursor: pointer;
  //          &:hover{
  //            color: #7bb249;
  //          }
  //        }
  //      }
  //    }
  //    &-date::before {
  //      background-image: url('/img/branding/entry-date.png');
  //    }
  //    &-cat-links::before {
  //      background-image: url('/img/branding/entry-cat-links.png');
  //    }
  //    &-comments-link::before {
  //      background-image: url('/img/branding/entry-comments-link.png');
  //    }
  //    &-love::before {
  //      background-image: url('/img/branding/entry-love.png');
  //    }
  //    &-views-count::before {
  //      background-image: url('/img/branding/entry-views-count.png');
  //    }
  //  }
  //}
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.isotope-filter {
  @media (min-width: 768px) {
    position: static !important;
  }
}