html {
  font-family: 'Museo Sans Cyrl 500',sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%
}

body {
  margin: 0;
}

.im {
  display: inline-block;
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: $main-green;
}

textarea{
  outline:none;
}

a:active, a:hover, a:focus, button:focus {
  outline: none !important;
}

img {
  border: 0;
  -ms-interpolation-mode: bicubic;
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

#main {
  position: relative;
  overflow: hidden;
  @media (max-width: 1024px) {
    padding-top: 50px;
  }
}

#content {
  overflow: visible;
  position: relative;
  max-width: 100%;
  min-height: 500px;
}

.fancybox-close-small,
.fancybox-close-small:hover,
.fancybox-close-small:focus {
  outline: 0!important;
}

.fancybox-slide--iframe {
  .fancybox-content {
    margin: 0;
    padding: 0!important;
  }
}


.seo-text {
  padding-bottom: 40px;
  font-size: calc(1vmin + 10px);
  font-family: 'Museo Sans Cyrl 100', sans-serif;
}

.clearfix::after {
  content: '';
  clear: both;
  display: block;
}

.marquee {
  width: 100%;
}
.marquee .inner {
  position: relative;
  width: 100%;
  display: flex;
  p{
    white-space: nowrap;
    text-transform: uppercase;
    font-size: 150px;
    font-family: 'Museo Sans Cyrl 900';
    color: #fff;
    -webkit-text-stroke: 1.5px #000;
    @media(max-width: 768px){
      font-size: 75px;
    }
  }
}