.language{
  display: none;
  @media (min-width: 1025px) {
    display: block;
    list-style: none;
    margin: 0;
    li{
      display: inline;
      position: relative;
      padding-right: 5px;
      &:not(:last-of-type):after {
        position: absolute;
        width: 1px;
        height: 12px;
        top: 50%;
        margin-top: -8px;
        right: 3px;
        background: #848484;
        content: '';
        -webkit-transition: all .3s;
        -moz-transition: all .3s;
        -o-transition: all .3s;
        transition: all .3s;
        pointer-events: none;
      }
      a{
        color: #848484;
        text-decoration: none;
        font-size: 13px;
        font-family: 'Museo Sans Cyrl 300',sans-serif;
        &:hover{
          color: #000;
        }
        &.language-active{
          color: #000;
        }
      }
    }
  }
  &.has-children {
    display: none;
    @media (max-width: 1024px) {
      display: block;
    }
  }

}